import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ApplicationInstance, IMinMaxSlider } from '@common/nutrients.interface';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { ProfileService } from '@services/nutrients/profile.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { AssetClass } from 'src/app/_DBContext/AssetClass';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { BaseController } from 'src/app/base.controller';
import { AlterElementWeightingDialogController } from './alterElementWeighting-dialog.controller';

export class ProfileDetailController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;
  private _nutrientsService: NutrientsService;
  private _profileService: ProfileService;

  private _defaultMinPhase = 5;
  private _defaultFirstPhaseStartWeek = 1;
  private _pendingLoad = false;

  public AssetStatus = ['Active', 'Suspended', 'Archived'];
  public profiles: Asset[] = [];
  public localprofiles: Asset[] = [];
  public searchProfile: string;

  public profile: Asset;
  public applicationInstances: ApplicationInstance[] = [];
  public nutrientSettings: NutrientSetting[] = [];
  public estimatedWeeksDuration = 52;
  public oldEstimatedWeeksDuration = 52;
  public showSliders = true;
  public showSaveBtn = true;
  public nameExists = false;
  public currentName: string;
  public showAll = false;
  public profileChart: AmCharts.AmSerialChart;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    ProfileService: ProfileService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsProfilesFull);

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._timeout = $timeout;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;
    this._nutrientsService = NutrientsService;
    this._profileService = ProfileService;

    this.entityManager = DataEntityService.manager;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    this._notifyingService.subscribe(NotifyEvents.Nutrients.Profiles.ElementWeightingChanged, this.scope, (event, data) => {
      this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
    });

    this._fetchData();
  }

  public checkNameAlreadyExists(name: string) {
    if (this.currentName === name) {
      this.nameExists = false;
    }
    else {
      const assetClassEntity = this.getNutrientProfileAssetClass();

      const pred = breeze.Predicate.create('Name', breeze.FilterQueryOp.Equals, name).and(
        'AssetClassId',
        breeze.FilterQueryOp.Equals,
        assetClassEntity.Id,
      );

      breeze.EntityQuery.from('AccountSharedAssets')
        .withParameters({ accountId: this.accountId })
        .where(pred)
        .using(this.entityManager)
        .execute()
        .then((data) => {
          this.nameExists = false;

          if (data.results.length) {
            const profList = data.results as Asset[];
            const newList = profList.filter((f) => f.Name !== this.currentName);

            this.nameExists = !!newList.length;
          }
        });
    }
  }

  public recreateChart() {
    this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
  }

  public addNew(aiName?: string): void {
    let previousWeekValue = 0;
    let newPhaseStartWeek = 0;
    let exists = false;

    const lastPhase = this.applicationInstances.at(-1);
    const newPhasePercentage = (lastPhase.nutrientSetting.Percentage + 100) / 2;

    previousWeekValue = this.getStartWeek(lastPhase.nutrientSetting.Percentage);
    newPhaseStartWeek = this.getStartWeek(newPhasePercentage);

    const previousPhase = this.applicationInstances.filter((x) => x.week == newPhaseStartWeek);

    exists = !!previousPhase.length;

    if (!exists && newPhaseStartWeek <= this.estimatedWeeksDuration) {
      this.addApplicationInstance(aiName, newPhasePercentage);
      this.updateMinMaxSlider();
    } else {
      this._languageService.warning('NUTR.PROF.UNABLE_TO_CREATE', 'COMMON.WARNING', { w: this.estimatedWeeksDuration });
    }
  }

  public createNewApplicationInstance(aiName?: string): void {
    const lastPhase = this.applicationInstances.at(-1);

    if (lastPhase.week == this.estimatedWeeksDuration) {
      this._languageService.warning('NUTR.PROF.UNABLE_TO_CREATE', 'COMMON.WARNING', { w: this.estimatedWeeksDuration });
    } else {
      let existingNumberOfPhase = this.estimatedWeeksDuration >= this._defaultMinPhase ? this._defaultMinPhase : this.estimatedWeeksDuration;
      const newPhasePercentage = this.getSelectedPhasePercentage(existingNumberOfPhase++);

      this.addApplicationInstance(aiName, newPhasePercentage);
      this.updateMinMaxSlider();
    }
  }

  public recalculateDurationWeeks(sTriggerFrom: string, inst?: ApplicationInstance): void {
    switch (sTriggerFrom) {
      case 'refresh': {
        if (this.estimatedWeeksDuration == null)
        {
          break;
        }

        // NOTE: The 'showSliders' is set to false to remove slider elements from DOM before the new max values are set
        // to ensure the sliders are re-rendered correctly.
        this.showSliders = false;

        const numberOfPhase = this.applicationInstances.length;

        if (this.estimatedWeeksDuration < numberOfPhase) {
          this.estimatedWeeksDuration = numberOfPhase;
        }

        this.applicationInstances.forEach((application) => {
          application.week = Math.max(1, Math.round((application.week * this.estimatedWeeksDuration) / this.oldEstimatedWeeksDuration));
          application.nutrientSetting.Percentage = this.getPercentage(application.week);
        });

        this.oldEstimatedWeeksDuration = this.estimatedWeeksDuration;
        this.updateMinMaxSlider();

        // as spoken to Jeff, the estimation week duration can be stored on field Orientation.
        // (FYI: the exising database design, nutrient profile is stored on Table Asset, there is no specific table to store nutrient profiles record)
        // as the previous nutrient profiles module never stored estimatedWeeksDuration, the existing data should be 0.
        // as discussed, set is as 52 if the orientation value is 0.
        this.profile.Orientation = this.estimatedWeeksDuration;

        // HACK: Set the 'showSliders' to true after a timeout to force the sliders to re-render with the new max values.
        this._timeout(() => {
          this.showSliders = true;
        }, 0);

        break;
      }

      case 'update': {
        if (inst.week >= inst.minWeek && inst.week <= inst.maxWeek) {
          this.applicationInstances.forEach((obj) => {
            if (obj.instanceNum == inst.instanceNum) {
              obj.nutrientSetting.Percentage = this.getPercentage(obj.week);
            }
          });

          this.updateMinMaxSlider();
        } else {
          // reset to the original week value
          this.applicationInstances.forEach((obj) => {
            if (obj.instanceNum == inst.instanceNum) {
              obj.week = this.getStartWeek(inst.nutrientSetting.Percentage);
            }
          });

          this._languageService.warning('NUTR.PROF.START_WEEK', 'COMMON.WARNING', {
            n: inst.nutrientSetting.Name,
            w: inst.minWeek,
            x: inst.maxWeek,
          });
        }

        break;
      }

      default:
        break;
    }

    if (!this._pendingLoad) {
      this._pendingLoad = true;
      this._timeout(() => {
        this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
        this._pendingLoad = false;
      }, 1000);
    }
  }

  public alterElementWeighting(ev, compound: string): void {
    this._mdDialog.show({
      escapeToClose: false,
      controller: AlterElementWeightingDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/profiles/alterElementWeighting-dialog.html',
      locals: {
        applicationInstances: this.applicationInstances,
        selectedCompound: compound,
        isReadOnly: this.isReadOnly,
      },
    } as angular.material.IDialogOptions);
  }

  public gotoProfilesList() {
    this._nutrientsService.setKeepFilter(true);
    this._state.go('app.nutrients.profiles');
  }

  public goToProfileDetail(profileId: number) {
    this._state.go('app.nutrients.profiles.detail', { id: profileId });
  }

  // this page should just use the actual values rather than variables that have to be reassigned back
  // not going to change it now though
  public undoChanges() {
    this.entityManager.rejectChanges();
    this.estimatedWeeksDuration = this.profile?.Orientation ?? 52;
    this.oldEstimatedWeeksDuration = this.estimatedWeeksDuration;
    this.applicationInstances = [];
    this.fetchNutrientSettings();
  }

  public saveChanges() {
    this.entityManager.saveChanges().then(() => {
      this._languageService.showSaveSuccess();
    });
  }

  public deleteProfileInstanceConfirm(ev, appInstance: ApplicationInstance) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('NUTR.COMMON.ARE_YOU_SURE_DELETE_PROFILE'))
      .htmlContent(this._languageService.instant('NUTR.PROF.WILL_BE_DELETED', { name: appInstance.nutrientSetting.Name }))
      .ariaLabel(this._languageService.instant('NUTR.COMMON.DELETE_PROFILE_INSTANCE'))
      .targetEvent(ev)
      .ok(this._languageService.instant('COMMON.OK'))
      .cancel(this._languageService.instant('COMMON.CANCEL_UC'));

    this._mdDialog.show(confirm).then(() => {
      const nutrientSettingEntity = this.entityManager.getEntities('NutrientSetting').filter((ns: NutrientSetting) => {
        return ns.Id === appInstance.nutrientSetting.Id;
      })[0] as NutrientSetting;

      const entityDeletions: breeze.Entity[] = [];

      if (nutrientSettingEntity.Id < 0) {
        nutrientSettingEntity.entityAspect.setDetached();
      } else {
        nutrientSettingEntity.entityAspect.setDeleted();
        entityDeletions.push(nutrientSettingEntity);
      }

      const successCallback = (savedChanges) => {
        this._languageService.success('NUTR.COMMON.DELETION_SAVED');
        this.removeApplicationInstance(appInstance);
        console.log(savedChanges);
      };

      const failCallback = (saveFailed) => {
        this._languageService.warning('NUTR.COMMON.DELETION_UNSUCCESSFULL', 'COMMON.WARNING', { m: saveFailed.message });

        if (saveFailed.entityErrors) {
          saveFailed.entityErrors.map((error) => {
            this._languageService.info('{{e}}', 'COMMON.INFORMATION', { e: error.errorMessage });
          });
        }
      };

      if (entityDeletions.length) {
        this.entityManager.saveChanges(entityDeletions, null, successCallback, failCallback);
      } else {
        this.removeApplicationInstance(appInstance);
      }
    });
  }

  private async _fetchData() {
    const getProfile = () => this.entityManager.getEntityByKey('Asset', this._state.params['id']) as Asset;

    this.showSaveBtn = false;

    const profile = getProfile();

    if (!profile) {
      // Load the nutrient profiles from server as the target profile could not be loaded from breeze cache.
      const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.NutrientProfile);

      await breeze.EntityQuery.from('AccountSharedAssets')
        .expand('AssetClass')
        .where(pred)
        .withParameters({ accountId: this.accountId })
        .using(this.entityManager)
        .execute();
    }

    this.profile = profile ?? getProfile();
    this.currentName = this.profile?.Name ?? '';
    this.showSaveBtn = this.profile?.OwnerAccountId === this.accountId;

    await this.fetchProfileList();
    await this.fetchNutrientSettings();
  }

  private async fetchNutrientSettings() {
    const assetId = this.profile ? this.profile.AssetId : parseInt(this._state.params['id']);

    const processNutrientSettings = (data: breeze.QueryResult) => {
      this.nutrientSettings = data.results as NutrientSetting[];

      if (!this.nutrientSettings.length) {
        this.createDefaultApplicationInstances();
        this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
      } else {
        // as spoken to Jeff, the estimation week duration can be stored on field Orientation.
        // NOTE: the exising database design, nutrient profile is stored in Asset table - there is no specific table to store nutrient profiles record.
        // As the previous nutrient profiles module never stored estimatedWeeksDuration, the existing data should be 0.
        // It was decided to set is as 52 if the 'orientation' is 'falsy' value.
        this.estimatedWeeksDuration = this.profile?.Orientation || this.estimatedWeeksDuration;
        this.oldEstimatedWeeksDuration = this.profile?.Orientation || this.estimatedWeeksDuration;

        let instanceNum = 1;

        this.nutrientSettings.forEach((obj) => {
          const appInstance = {
            instanceNum: instanceNum,
            nutrientSetting: obj,
          } as ApplicationInstance;

          const startOfWeek = this.getStartWeek(appInstance.nutrientSetting.Percentage);

          appInstance.week = startOfWeek;
          this.applicationInstances.push(appInstance);

          instanceNum++;
        });

        this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
      }

      this.updateMinMaxSlider();
    };

    const data = await breeze.EntityQuery.from('assets/' + assetId + '/NutrientSettings')
      .using(this.entityManager)
      .execute();

    processNutrientSettings(data);
  }

  private async fetchProfileList(): Promise<void> {
    const assetClasses = await this.fetchAssetClasses();
    const profileAssetClass = assetClasses.filter((assetClass) => assetClass.Name === 'NutrientProfile');

    if (profileAssetClass.length === 1) {
      this.fetchProfiles(profileAssetClass[0]);
    }
  }

  private async fetchAssetClasses(): Promise<AssetClass[]> {
    const assetClassEntities = this.entityManager.getEntities('AssetClass');

    if (!assetClassEntities.length) {
      const data = await breeze.EntityQuery.from('AssetClass')
        .using(this.entityManager)
        .execute();

      return data.results as AssetClass[];
    } else {
      return assetClassEntities as AssetClass[];
    }
  }

  private async fetchProfiles(profileAssetClass: AssetClass): Promise<void> {
    const pred = breeze.Predicate.create('AssetClassId', breeze.FilterQueryOp.Equals, profileAssetClass.Id)
      .and('TrackDeletedWhen', breeze.FilterQueryOp.Equals, null)
      .and('Status', breeze.FilterQueryOp.NotEquals, 'Archived');

    await breeze.EntityQuery.from('AccountSharedAssets')
      .where(pred)
      .select('AssetId, Name, OwnerAccountId')
      .orderBy('Name')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data: breeze.QueryResult) => {
        this.profiles = data.results as Asset[];
        this.localprofiles.length = 0;

        if (this.profiles) {
          this.localprofiles = this.profiles.filter((p) => p.OwnerAccountId === this.accountId);
        }
      });
  }

  private getNutrientProfileAssetClass(): AssetClass {
    return this.entityManager.getEntities('AssetClass').filter((assetClass: AssetClass) => {
      return assetClass.Name === 'NutrientProfile';
    })[0] as AssetClass;
  }

  private createDefaultApplicationInstances() {
    const numberofPhase = this.estimatedWeeksDuration >= this._defaultMinPhase ? this._defaultMinPhase : this.estimatedWeeksDuration;

    for (let i = 1; i <= numberofPhase; i++) {
      const defaultPercentage = this.getSelectedPhasePercentage(i);
      const defaultPhaseName = this._languageService.instant('COMMON.PHASE') + ` ${defaultPercentage != 0 ? i : this._defaultFirstPhaseStartWeek}`;

      this.addApplicationInstance(defaultPhaseName, defaultPercentage);
    }

    this.updateMinMaxSlider();
  }

  private removeApplicationInstance(appInstance: ApplicationInstance): void {
    const index = this.applicationInstances.indexOf(appInstance);

    if (index != -1) {
      this.applicationInstances.splice(index, 1);

      let instanceNum = 1;

      this.applicationInstances.forEach((x) => {
        x.instanceNum = instanceNum;
        instanceNum++;
      });

      this.updateMinMaxSlider();
    }
  }

  private getSelectedPhasePercentage(selectedPhase: number): number {
    const numberofPhase =
      this.estimatedWeeksDuration >= this._defaultMinPhase ? this._defaultMinPhase : this.estimatedWeeksDuration;
    let defaultPercentage = 0;

    if (numberofPhase > 1) {
      defaultPercentage = ((selectedPhase - 1) / (numberofPhase - 1)) * 100;
    }
    return defaultPercentage;
  }

  private addApplicationInstance(aiName?: string, percentage?: number): void {
    const assetId = this.profile ? this.profile.AssetId : parseInt(this._state.params['id']);
    const nutrientSettingEntityType = this.entityManager.metadataStore.getEntityType(
      'NutrientSetting',
    ) as breeze.EntityType;
    const newNutrientSettingEntity = nutrientSettingEntityType.createEntity() as NutrientSetting;

    newNutrientSettingEntity.AssetId = assetId;
    newNutrientSettingEntity.Name = aiName ?? '';
    newNutrientSettingEntity.Percentage = percentage ?? 0;
    newNutrientSettingEntity.Noots.B = 1;
    newNutrientSettingEntity.Noots.Ca = 1;
    newNutrientSettingEntity.Noots.Cl = 1;
    newNutrientSettingEntity.Noots.Cu = 1;
    newNutrientSettingEntity.Noots.Fe = 1;
    newNutrientSettingEntity.Noots.K = 1;
    newNutrientSettingEntity.Noots.Mg = 1;
    newNutrientSettingEntity.Noots.Mn = 1;
    newNutrientSettingEntity.Noots.Mo = 1;
    newNutrientSettingEntity.Noots.Na = 1;
    newNutrientSettingEntity.Noots.NH4_N = 1;
    newNutrientSettingEntity.Noots.NO3_N = 1;
    newNutrientSettingEntity.Noots.P = 1;
    newNutrientSettingEntity.Noots.S = 1;
    newNutrientSettingEntity.Noots.Zn = 1;

    this.entityManager.addEntity(newNutrientSettingEntity);

    const appInstance = {
      instanceNum: this.applicationInstances.length + 1,
      nutrientSetting: newNutrientSettingEntity,
      minWeek: 0,
      maxWeek: 0,
    } as ApplicationInstance;
    const startWeek = this.getStartWeek(appInstance.nutrientSetting.Percentage);

    appInstance.week = startWeek;

    this.applicationInstances.push(appInstance);

    if (this.applicationInstances.length > this._defaultMinPhase) {
      this.profileChart = this._profileService.getChart('profile-detail-chart', this.applicationInstances, 'percent', this.showAll);
    }
  }

  private updateMinMaxSlider(): void {
    // Ensure new 'applicationInstances' reference to trigger angular digest cycle.
    this.applicationInstances = this.applicationInstances.map((application) => {
      const { min, max } = this.getMinMaxSlider(application);

      return {
        ...application,
        minWeek: Math.floor(min),
        maxWeek: Math.round(max),
      };
    });
  }

  private getMinMaxSlider(obj: ApplicationInstance): IMinMaxSlider {
    const previousPhase = this.applicationInstances.filter((x) => x.instanceNum === obj.instanceNum - 1);
    const nextPhase = this.applicationInstances.filter((x) => x.instanceNum === obj.instanceNum + 1);

    return {
      min: previousPhase.length ? previousPhase[0].week + 1 : 1,
      max: nextPhase.length ? nextPhase[0].week - 1 : this.estimatedWeeksDuration,
    } as IMinMaxSlider;
  }

  private getStartWeek(percentage: number): number {
    // if percentage is 0 then it is first phase
    // note the existing design calculate start of the week based on the percentage of the nutrient profiles total duration
    if (percentage != 0) {
      const week = this.estimatedWeeksDuration * (percentage / 100);
      const result = Math.round(Number(week.toFixed(1)));
      return result;
    }

    return this._defaultFirstPhaseStartWeek;
  }

  private getPercentage(week: number): number {
    let percentage = 0;

    if (week != this._defaultFirstPhaseStartWeek) {
      percentage = (week / this.estimatedWeeksDuration) * 100;
    }

    return percentage;
  }
}

angular.module('app.nutrients').controller('ProfileDetailController', ProfileDetailController);
