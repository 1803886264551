import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { TableControl } from '@common/helpers/TableControl';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { SiteSettingService } from '@services/account/site-setting.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';
import { Budget } from 'src/app/_DBContext/Budget';
import { UpdateProgramDialogController } from './updatePrograms-dialog.controller';
import { NutrientBudgetPacket } from './program-details/program-details.controller';

export class ProgramsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _nutrientsService: NutrientsService;
  private _siteSettingService: SiteSettingService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public iActive = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;

  public nameFilter = '';
  public createForFilter = '';
  private programs: Budget[] = [];
  public filteredPrograms: Budget[];
  public tableControl = new TableControl();
  public arrLogsDataCSVhdr: string[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    SiteSettingService: SiteSettingService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsFertilisersFull);

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._nutrientsService = NutrientsService;
    this._siteSettingService = SiteSettingService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;

    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      this.showActive = NutrientsService.getActiveState();
      this.showArchived = NutrientsService.getArchivedState();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
      NutrientsService.setActiveState(this.showActive);
      NutrientsService.setArchivedState(this.showArchived);
    }
  }

  $onInit() {
    this.arrLogsDataCSVhdr = [
      this._languageService.instant('COMMON.SITE'),
      this._languageService.instant('NUTR.COMMON.FERTILISER'),
      this._languageService.instant('NUTR.PROG.PLANNED_UNITS'),
      this._languageService.instant('COMMON.UNITS'),
      this._languageService.instant('NUTR.PROG.TOTAL_PLANNED_UNITS'),
      this._languageService.instant('COMMON.TOTAL_UNITS'),
      this._languageService.instant('NUTR.PROG.APPLICATION_WEEK'),
    ];
    this.setWatches();
    this._fetchData();
  }

  private _fetchData() {
    const pred = breeze.Predicate.create('NutrientBudgetPacket', breeze.FilterQueryOp.Equals, null).not();

    breeze.EntityQuery.from('Budgets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute(this.dataSuccessCallback.bind(this));
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter', 'vm.createForFilter'], () => {
      if (this.programs.length) {
        this.filterPrograms();
      }
    });
  }

  private filterPrograms() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    this._nutrientsService.setCreateForFilter(this.createForFilter);
    this._nutrientsService.setActiveState(this.showActive);
    this._nutrientsService.setArchivedState(this.showArchived);
    if (this.programs) {
      this.filteredPrograms = this.programs.filter((a) => a.Name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);
      this.filteredPrograms = this.filteredPrograms.filter((a) =>
        a.JSONNutrientBudgetPacket.Groups.some((b) => b.Name.toLocaleLowerCase().indexOf(this.createForFilter.toLowerCase()) > -1),
      );
      if (!this.showActive) {
        this.filteredPrograms = this.filteredPrograms.filter((a) => a.Status !== 'Active');
      }
      if (!this.showArchived) {
        this.filteredPrograms = this.filteredPrograms.filter((a) => a.Status !== 'Archived');
      }
      this.filteredPrograms = ArrayUtils.sortByString(this.filteredPrograms, x => x.Name);
    }
  }

  private dataSuccessCallback(data: breeze.QueryResult) {
    this.programs = [];
    this.programs = data.results as Budget[];

    this.programs.forEach((program) => {
      program.JSONNutrientBudgetPacket = JSON.parse(program.NutrientBudgetPacket) as NutrientBudgetPacket;
      program.JSONNutrientBudgetPacket.LocaleStartDate = this._dayNumberService.convertYMDToLocaleDate(
        program.JSONNutrientBudgetPacket.StartDateYMD,
      );
      program.JSONNutrientBudgetPacket.LocaleEndDate = this._dayNumberService.convertYMDToLocaleDate(
        program.JSONNutrientBudgetPacket.EndDateYMD,
      );
      if (program.Status == 'Archived') this.iArchived++;
      else this.iActive++;
    });
    this.filterPrograms();
  }

  public getCSVData(budgetId: number): angular.IPromise<fuse.reportNutrientBudgetDto[]> {
    const defer = this._q.defer<fuse.reportNutrientBudgetDto[]>();
    this._siteSettingService
      .getReportNutrientBudget(budgetId)
      .then((ReportData) => {
        const mycsvDetails = [];
        ReportData.forEach((data0) => {
          const data = {};
          const unit = this._unitOfMeasureService.fertiliserUnits(data['units'], false) as uomUnit;
          const areaUnit = this._unitOfMeasureService.fertiliserUnits(data['units'], true) as uomUnit;

          data['Site'] = data0['siteName'];
          data['Fertiliser'] = data0['fertName'];

          data['plannedUnits'] = areaUnit.fromBaseRounded(data0['plannedUnits']);
          data['units'] = areaUnit.name;

          data['totalPlannedUnits'] = unit.fromBaseRounded(data0['totalPlannedUnits']);
          data['totalUnits'] = unit.name;

          data['applicationWeek'] = data0['applWeek'];

          mycsvDetails.push(data);
        });
        defer.resolve(mycsvDetails);
      })
      .catch((error) => {
        this._languageService.error(error.data.Message as string);
        defer.reject();
      });
    return defer.promise;
  }

  public addNewProgram() {
    this._state.go('app.nutrients.programs.create');
  }

  public reloadProgram(programId: number) {
    this._state.go('app.nutrients.programs.reload', { id: programId });
  }

  public applyProgram(programId: number) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('NUTR.PROG.APPLY_NEW_NUTRIENT_PROG'))
      .textContent('')
      .ariaLabel(this._languageService.instant('NUTR.PROG.APPLY_NUTRIENT_PROGRAM'))
      .ok(this._languageService.instant('COMMON.YES'))
      .cancel(this._languageService.instant('COMMON.NO'));

    this._mdDialog.show(confirm).then(() => {
      const budgetEntity = this.entityManager.getEntityByKey('Budget', programId) as Budget;
      const data = {
        budgetId: budgetEntity.Id,
      } as fuse.applyProgramDto;
      this._http.post(CommonHelper.getApiUrl('nutrients/ApplyNutrientProgramToSites'), data).then((response) => {
        if (response.data == 'Budget Applied') {
          this._languageService.success('NUTR.PROG.SUCCESSFULLY_APPLIED');
        } else {
          angular.forEach(response.data, (message) => {
            this._languageService.warning(message as string);
          });
        }
      });
    });
  }

  public updateProgramsClient(item: Budget) {
    if (this.isReadOnly) {
      return;
    }

    this._mdDialog.show({
      clickOutsideToClose: false,
      escapeToClose: false,
      controller: UpdateProgramDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/programs/updateProgram-dialog.html',
      locals: {
        item: item,
        programs: this.programs,
      },
    } as angular.material.IDialogOptions).then(() => {
      this.filterPrograms();
      this.iArchived = this.programs.filter((a) => a.Status === 'Archived').length;
      this.iActive = this.programs.filter((a) => a.Status !== 'Archived').length;
    });
  }

  public toggleButton(button: string): void {
    if (button == 'Active') this.showActive = !this.showActive;
    if (button == 'Archived') this.showArchived = !this.showArchived;
    this.filterPrograms();
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.createForFilter = '';
  }
}

angular.module('app.nutrients').controller('ProgramsController', ProgramsController);
