// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nutrients > .header {
  height: 100px;
  min-height: 60px;
  max-height: 80px;
}
#nutrients .content-container {
  padding: 24px;
  margin: 0;
}
#nutrients .form-data-wrapper {
  margin-left: 16px;
}
#nutrients .form-data-wrapper .json-data-wrapper {
  overflow: hidden;
}
#nutrients .form-data-wrapper .json-data-wrapper pre {
  font-size: 16px;
}
#nutrients .inputPaddingBottom {
  padding-bottom: 24px;
}
#nutrients .inputPaddingTopBottom {
  padding-bottom: 24px;
  padding-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/nutrients.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,gBAAA;EACA,gBAAA;AADR;AAKK;EACG,aAAA;EACN,SAAA;AAHF;AAUQ;EACI,iBAAA;AARZ;AAUY;EACI,gBAAA;AARhB;AAUgB;EACI,eAAA;AARpB;AAaK;EACI,oBAAA;AAXT;AAcK;EACG,oBAAA;EACA,iBAAA;AAZR","sourcesContent":["#nutrients {\r\n\r\n    > .header {\r\n        height: 100px;\r\n        min-height: 60px;\r\n        max-height: 80px;\r\n\r\n    }\r\n\t\r\n     .content-container {\r\n        padding: 24px;\r\n\t\tmargin: 0;\t\r\n\t}\r\n\t\r\n\t.form-wrapper {\r\n\r\n    }\r\n\r\n        .form-data-wrapper {\r\n            margin-left: 16px;\r\n\r\n            .json-data-wrapper {\r\n                overflow: hidden;\r\n\r\n                pre {\r\n                    font-size: 16px;\r\n                }\r\n            }\r\n        }\r\n\t\r\n     .inputPaddingBottom {\r\n         padding-bottom:24px;\r\n     }\r\n\r\n     .inputPaddingTopBottom {\r\n        padding-bottom:24px;\r\n        padding-top: 24px;\r\n     }\r\n\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
