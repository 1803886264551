import * as angular from 'angular';
import { Budget } from 'src/app/_DBContext/Budget';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class UpdateProgramDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  private programclient: Budget;
  private allPrograms;

  public AssetStatus = ['Active', 'Suspended', 'Archived'];
  //public AssetStatus =[];
  public nameExists: boolean = false;
  private theOldName: string;
  public theNewName: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    item,
    programs,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.programclient = item;
    this.theOldName = item.Name;
    this.allPrograms = programs;
    this.theNewName = this.theOldName;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  public checkNameAlreadyExists(name: string) {
    this.nameExists = false;
    for (let j = 0; j < this.allPrograms.length; j++) {
      if (this.allPrograms[j].Name == name) {
        if (name != this.theOldName) {
          this.nameExists = true;
          break;
        }
      }
    }
  }

  public submit() {
    this.checkNameAlreadyExists(this.programclient.Name);

    const saPred: breeze.Predicate = breeze.Predicate.create('Id', '==', this.programclient.Id);
    const querySA = breeze.EntityQuery.from('Budget').where(saPred);
    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(querySA); // query the cache (synchronous)
    for (let idx = 0; idx < sa.length; idx++) {
      const entity = sa[idx] as Budget;
      entity.Name = this.theNewName;
      entity.Status = this.programclient.Status;
    }

    if (this.hasDataChanges) {
      this._dataEntityService.saveChanges().then(
        () => {
          this._languageService.showSaveSuccess();
        },
        (saveFailed) => {
          this._languageService.showSaveFailure(saveFailed.message);

          if (saveFailed.entityErrors) {
            saveFailed.entityErrors.map((error) => {
              this._languageService.info(error.errorMessage);
            });
          }
        },
      );
    }

    this.afterChangesSaved();
  }

  private afterChangesSaved() {
    this._mdDialog.hide();
  }

  public closeDialog() {
    this._dataEntityService.rejectChanges();
    this._mdDialog.hide();
  }
}

angular.module('app.nutrients').controller('UpdateProgramDialogController', UpdateProgramDialogController);
