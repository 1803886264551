import * as angular from 'angular';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { ApplicationInstance } from '@common/nutrients.interface';
import { BaseController } from 'src/app/base.controller';

export class AlterElementWeightingDialogController extends BaseController {
  public applicationInstances: ApplicationInstance[];
  public selectedCompound;
  public dialogForm: angular.IFormController;

  private _mdDialog: angular.material.IDialogService;
  private _notifyingService: NotifyingService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    selectedCompound,
    applicationInstances,
    isReadOnly,
  ) {
    super(
      $scope,
      PermissionService,
    );
    super.setReadOnlyAs(isReadOnly);

    this._mdDialog = $mdDialog;
    this._notifyingService = NotifyingService;

    this.applicationInstances = applicationInstances;
    this.selectedCompound = selectedCompound;
  }

  public notifyProfileDetailCtrl(value: number, index: number) {
    const control = this.dialogForm['index' + index];
    if (value < 0 || value > 100) {
      control.$setValidity('valid', false);
    } else {
      control.$setValidity('valid', true);
      this._notifyingService.notify(NotifyEvents.Nutrients.Profiles.ElementWeightingChanged, null);
    }
  }

  public hide() {
    this._mdDialog.hide();
  }

  public cancel() {
    this._mdDialog.cancel();
  }
}

angular.module('app.nutrients').controller('AlterElementWeightingDialogController', AlterElementWeightingDialogController);
