import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { TableControl } from '@common/helpers/TableControl';
import { DataEntityService } from '@services/data-entity.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { AddProfileDialogController } from './addProfile-dialog.controller';
import { Asset } from 'src/app/_DBContext/Asset';

export class ProfilesController extends BaseController {
  public iActive = 0;
  public iSuspended = 0;
  public iArchived = 0;
  public showActive = true;
  public showShared = false;
  public showArchived = false;
  public nameFilter = '';
  public filteredProfiles: Asset[];
  public tableControl = new TableControl();

  private _mdDialog: angular.material.IDialogService;
  private _state;
  private _nutrientsService: NutrientsService;

  private profiles: Asset[] = [];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.setEditPermission(ApplicationPrivileges.NutrientsProfilesFull);

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;

    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      this.showActive = NutrientsService.getActiveState();
      this.showArchived = NutrientsService.getArchivedState();
      this.showShared = NutrientsService.getSharedState();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
      NutrientsService.setActiveState(this.showActive);
      NutrientsService.setArchivedState(this.showArchived);
      NutrientsService.setSharedState(this.showShared);
    }
  }

  $onInit() {
    this.setWatches();
    this._fetchData();
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter'], () => {
      if (this.profiles.length) {
        this.filterProfiles();
      }
    });
  }

  public toggleSharedProfiles() {
    this.iActive = 0;
    this.iArchived = 0;
    this.profiles.forEach((profile) => {
      if (this.showShared) {
        if (profile.Status == 'Archived') this.iArchived++;
        else this.iActive++;
      } else {
        if (profile.OwnerAccountId == this.accountId && profile.Status == 'Archived') this.iArchived++;
        else if (profile.OwnerAccountId == this.accountId && profile.Status == 'Active') this.iActive++;
      }
    });
    this.filterProfiles();
  }

  private filterProfiles() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    this._nutrientsService.setSharedState(this.showShared);
    this._nutrientsService.setActiveState(this.showActive);
    this._nutrientsService.setArchivedState(this.showArchived);
    if (this.profiles) {
      this.filteredProfiles = this.profiles.filter((a) => a.Name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);
      if (!this.showActive) {
        this.filteredProfiles = this.filteredProfiles.filter((a) => a.Status !== 'Active');
      }
      if (!this.showArchived) {
        this.filteredProfiles = this.filteredProfiles.filter((a) => a.Status !== 'Archived');
      }
      if (!this.showShared) {
        this.filteredProfiles = this.filteredProfiles.filter((a) => a.OwnerAccountId == this.accountId);
      }
    }
  }

  private _fetchData() {
    const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.NutrientProfile);

    breeze.EntityQuery.from('AccountSharedAssets')
      .expand('AssetClass')
      .where(pred)
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .orderBy('Name')
      .execute()
      .then(this.fetchProfilesSuccessCallback.bind(this));
  }

  private fetchProfilesSuccessCallback(data: breeze.QueryResult) {
    this.profiles = data.results as Asset[];
    this.iActive = 0;
    this.iArchived = 0;
    if (this.profiles) {
      this.profiles.forEach((profile) => {
        if (this.showShared) {
          if (profile.Status == 'Archived') this.iArchived++;
          else this.iActive++;
        } else {
          if (profile.OwnerAccountId == this.accountId && profile.Status == 'Archived') this.iArchived++;
          else if (profile.OwnerAccountId == this.accountId && profile.Status == 'Active') this.iActive++;
        }
      });
    }
    this.filterProfiles();
  }

  public gotoProfileDetail(asset: Asset) {
    this._state.go('app.nutrients.profiles.detail', { id: asset.AssetId });
  }

  public getOwner(ownerAccountId: number) {
    if (ownerAccountId == 1) {
      return 'SWAN Systems';
    } else {
      return '';
    }
  }

  public openAddProfileDialog(ev) {
    const sharedProfiles = this.profiles.filter((f) => f.Shared === true);
    this._mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      controller: AddProfileDialogController,
      controllerAs: 'ctrl',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/profiles/addProfile-dialog.html',
      targetEvent: ev,
      locals: {
        sharedAssets: sharedProfiles,
        showShared: false,
      },
    } as angular.material.IDialogOptions);
  }

  public toggleButton(button: string): void {
    if (button == 'Active') this.showActive = !this.showActive;
    if (button == 'Archived') this.showArchived = !this.showArchived;
    this.filterProfiles();
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }
}

angular.module('app.nutrients').controller('ProfilesController', ProfilesController);
