import * as angular from 'angular';
import * as moment from 'moment';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import {
  Analyte,
  ApplicationInstance,
  CalculatedResults,
  NutrientBudgetPacket,
  SampleBlob,
  SelectedSamplePointRow,
} from '@common/nutrients.interface';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes, unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { SamplePointService } from '@services/nutrients/sample-point.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { ProgramService } from '@services/nutrients/program.service';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Budget } from 'src/app/_DBContext/Budget';
import { BlendedAnalytes } from 'src/app/_DBContext/NutrientFields';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { BaseController } from 'src/app/base.controller';

class SWANProgramGeneralDetailComponent implements angular.IComponentOptions {
  bindings = {
    programId: '<',
    load: '<',
    step: '<',
    stepNumber: '<',
  };
  controller = ProgramGeneralDetailController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/programs/program-details/tabs/general-detail.component.html';
}

class ProgramGeneralDetailController extends BaseController {
  public minDate = SWANConstants.MinDate;
  public programId: number;
  public budget: Budget;
  public step: angular.IFormController;
  public stepNumber: number;
  public nutrientDecimalPlaces: Record<string, number | null> = {};
  private hasLoaded = false;

  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _dayNumberService: DayNumberService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _programService: ProgramService;
  private _samplePointService: SamplePointService;

  public programDetailSiteCount: number;
  public programDetailSiteArea: number;
  public programDetailWaterAverage: number;
  public selectedGroups = [] as Asset[];

  public samplePointNames: Asset[] = [];
  public relatedGroups: Asset[] = [];
  private profileList: Asset[] = [];
  public localprofileList: Asset[] = [];
  public totalPercentage: number = 0;

  private AnalyteBlend = [];
  private NitrogenBlend = [];
  private NutrientBlend = [];
  public pH: number = 0;
  private averages: number[] = [];
  public blendedCalculatedResults = {} as CalculatedResults;

  private fetchSamplePointPromises: angular.IPromise<breeze.QueryResult>[] = [];
  public volAreaUnit: uomUnit;
  public chlorUnit: uomUnit;
  public weightVolUnit: uomUnit;
  public condUnit: uomUnit;
  public tempUnit: uomUnit;
  public areaUnit: uomUnit;
  private programProfiles: fuse.nutrientProgramDto[];

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    ProgramService: ProgramService,
    SamplePointService: SamplePointService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._q = $q;
    this._http = $http;
    this._dayNumberService = DayNumberService;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._programService = ProgramService;
    this._samplePointService = SamplePointService;

    this.entityManager = DataEntityService.manager;
    this.areaUnit = UnitOfMeasureService.getUnits(UnitTypes.Area);
    this.volAreaUnit = UnitOfMeasureService.getUnits(UnitTypes.VolumeArea, unitSizes.large);
    this.weightVolUnit = UnitOfMeasureService.getUnits(UnitTypes.WeightVolume, unitSizes.small);
    this.chlorUnit = UnitOfMeasureService.getUnits(UnitTypes.Chlorine);
    this.condUnit = UnitOfMeasureService.getUnits(UnitTypes.Conductivity);
    this.tempUnit = UnitOfMeasureService.getUnits(UnitTypes.Temperature);
  }

  $onInit() {
    console.log('SWANProgramGenereralDetail activated.');
    this._programService.reloadedFromBudget = false;
    if (angular.isDefined(this.programId)) {
      this.budget = this.entityManager.getEntityByKey('Budget', this.programId) as Budget;
      const nutrientPacketJson = JSON.parse(this.budget.NutrientBudgetPacket) as NutrientBudgetPacket;
      this._programService.startDate = this._dayNumberService.convertYMDToLocaleDate(nutrientPacketJson.StartDateYMD);
      this._programService.saturdayEndDate = this._dayNumberService.convertYMDToLocaleDate(nutrientPacketJson.EndDateYMD);
    } else {
      const today: Date = new Date().addMinutes(720);
      const tomorrow: Date = new Date().addMinutes(1440);
      this._programService.startDate = angular.copy(today).moveToDayOfWeek(0, -1);
      this._programService.saturdayEndDate = angular.copy(tomorrow).addYears(1).moveToDayOfWeek(6, -1).addDays(-7);
    }
    this.calculateWeeks();
  }

  $onChanges(changes) {
    if (changes.stepNumber?.currentValue === 1 && !this.hasLoaded) {
      this.fetchData();
      this.hasLoaded = true;
    }
  }

  $onDestroy() {
    this._programService.selectedSamplePoints = [];
    this._programService.selectedGroups = [];
    this._programService.budgetToReload = null;
    this._programService.waterKLPerHa = null;
    this._programService.selectedSamplePointRows = [];
    this._programService.budgetNutrientProfile = null;
    this._programService.nutrientsInWater = null;
    console.log('SWANProgramGeneralDetail closed.');
  }

  public isSunday(date: Date) {
    return date.getDay() == 0;
  }

  public isSaturday(date: Date) {
    return date.getDay() == 6;
  }

  public relatedGroupUpdate() {
    this._programService.selectedGroups = angular.copy(this.selectedGroups);

    const successCallback = (data: breeze.QueryResult) => {
      const uniqueSites = data.results.filter(
        (v, i, self) =>
          self
            .map((e) => {
              return (e as any).ChildAsset_AssetId;
            })
            .indexOf((v as any).ChildAsset_AssetId) === i,
      );

      const uniqueSitesAsAssets = uniqueSites.map((site: any) => {
        return {
          AssetId: site.ChildAsset_AssetId,
          Name: site.ChildAsset_Name,
          Site: { Area: site.ChildAsset_Site_Area },
        } as Asset;
      });
      this._programService.plannedApplicationSites = uniqueSitesAsAssets as Asset[];
    };

    const failCallback = () => {};

    if (this._programService.selectedGroups?.length) {
      const pred = breeze.Predicate.create(
        'ParentAsset.AssetClass.Name',
        breeze.FilterQueryOp.Equals,
        AssetClassNameEnum.SiteCMU,
      ).and('ChildAsset.AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.SiteIMU);

      const tempPreds = [];
      for (let i = 0; i < this._programService.selectedGroups.length; i++) {
        tempPreds.push(
          breeze.Predicate.create(
            'ParentAsset.AssetId',
            breeze.FilterQueryOp.Equals,
            this._programService.selectedGroups[i].AssetId,
          ),
        );
      }

      const preds2 = breeze.Predicate.or(tempPreds);
      // Finding Sites under CMU
      breeze.EntityQuery.from('SiteAssets')
        .withParameters({ accountId: this.accountId })
        .where(pred.and(preds2))
        .select('ChildAsset.Name, ChildAsset.AssetId, ChildAsset.Site.Area')
        .using(this.entityManager)
        .execute()
        .then(successCallback, failCallback);
      // Verify Sites have CropNutrientAreas
      this.verifyGroupCropNutrientArea(this.selectedGroups.map((a) => a.AssetId));
    }

    this.getAverageKLPerHa();
  }

  public samplePointsUpdate() {
    // Find sample point rows that are no longer selected
    const existingRowUnSelectedSamplePoints = this._programService.selectedSamplePointRows.filter((row) => {
      let notFound = true;
      notFound =
        this._programService.selectedSamplePoints
          .map((sp) => {
            return sp.AssetId;
          })
          .indexOf(row.SamplePoint.AssetId) == -1;
      return notFound;
    });

    // Clear sample point rows that are no longer selected
    if (existingRowUnSelectedSamplePoints.length) {
      this._programService.selectedSamplePointRows = this._programService.selectedSamplePointRows.filter(
        (spr) => spr.SamplePoint.AssetId !== existingRowUnSelectedSamplePoints[0].SamplePoint.AssetId,
      );

      this.recalculateSamplePointPercentages();
    } else {
      // Find selected sample points not in the displayed rows
      const selectedSPNotInRows = this._programService.selectedSamplePoints.filter((selected) => {
        let notFound = true;
        notFound =
          this._programService.selectedSamplePointRows
            .map((sspr) => {
              return sspr.SamplePoint.AssetId;
            })
            .indexOf(selected.AssetId) == -1;
        return notFound;
      });

      if (!selectedSPNotInRows.length) {
        return;
      }

      const assetId = selectedSPNotInRows[0].AssetId;

      if (this.fetchSamplePointPromises[assetId]) {
        return;
      }

      // Get the Sample Points with the latest AssetAttachment
      this.fetchSamplePointPromises[assetId] =
        this.fetchLatestAssetAttachment() as angular.IPromise<breeze.QueryResult>;

      // Add sample point to selectedSamplePointRows array

      this._q.all([
        this.fetchSamplePointPromises[assetId],
        this.fetchSamplePoint(selectedSPNotInRows)
      ]).then((data: any) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].results[0] instanceof Asset) {
            for (let j = 0; j < data[i].results.length; j++) {
              const asset = data[i].results[j] as Asset;

              asset.AssetAttachments.map((aa) => {
                if (aa.Blob != null) {
                  aa.SampleBlob = JSON.parse(atob(aa.Blob)) as SampleBlob;
                }
              });

              const newRow = {
                SamplePoint: data[i].results[j],
              } as SelectedSamplePointRow;

              this._programService.selectedSamplePointRows.push(newRow);
              this.fetchSamplePointPromises[asset.AssetId] = null;
             
              if (newRow.SamplePoint.AssetAttachments.length) {
                if (newRow.SamplePoint.AssetAttachments[0].SampleBlob) {
                  this.getHistoricalBlend(asset.AssetId, newRow.SamplePoint.AssetAttachments[0].SampleBlob.sample.waterpH);
                }
              }
            }
          }
        }

        this.recalculateSamplePointPercentages();
      });
    }
  }

  private recalculateSamplePointPercentages() {
    const length = this._programService.selectedSamplePointRows.length;

    if (length) {
      if (this.programId) {
        for (let i = 0; i < length; i++) {
          if (this.budget && this.budget.JSONNutrientBudgetPacket && this.budget.JSONNutrientBudgetPacket.SamplePointRows[i]) {
            this._programService.selectedSamplePointRows[i].Percentage =
              this.budget.JSONNutrientBudgetPacket.SamplePointRows[i].Percentage;
          }
        }
      } else {
        this._programService.selectedSamplePointRows.map((sspr) => {
          sspr.Percentage = Math.floor(100 / length);
        });

        let totalPercentages = 0;
        this._programService.selectedSamplePointRows.forEach((sspr) => {
          totalPercentages += sspr.Percentage;
        });

        this._programService.selectedSamplePointRows[length - 1].Percentage += 100 - totalPercentages;
      }
    }

    this.updateTotalPercentage();
  }

  public updateTotalPercentage() {
    this.totalPercentage = ArrayUtils.sum(this._programService.selectedSamplePointRows.map(p => p?.Percentage ?? 0));

    this.updateAnalyteBlend();
  }

  public calculateWeeks() {
    if (
      angular.isDefined(this._programService.saturdayEndDate) &&
      angular.isDefined(this._programService.saturdayEndDate.valueOf())
    ) {
      this._programService.endDate = this._programService.saturdayEndDate.clone();
      const ms = moment.utc(this._programService.endDate).diff(moment.utc(this._programService.startDate));

      if (ms < 0) {
        this._languageService.warning('NUTR.PROG.END_DATE_AFTER_START');
        this._programService.endDate = Date.today().addYears(1).moveToDayOfWeek(0, +1);
        return;
      }
      this._programService.numOfWeeks = Math.round(moment.duration(ms).asDays() / 7);
      this.resetDistributionProfileLoadStatus();
    }

    this.getAverageKLPerHa();
  }

  public nutrientProfileOnChange() {
    this.setBudgetNutrientProfile();
    this.resetDistributionProfileLoadStatus();
    this._programService.nutrientSettings = [];
    this.validateProgramName();
  }

  private validateProgramName() {
    if (this._programService.programName != '') {
      if (this.programProfiles.some((a) => a.name.toLocaleLowerCase() == this._programService.programName.toLocaleLowerCase())) {
        this.step.programName.$setValidity('valid', false);
        this.step.programName.$setTouched();
      } else {
        this.step.programName.$setValidity('valid', true);
      }
    }
  }

  public setBudgetNutrientProfile() {
    const hasBudgetNutrientProfile = this._programService.budgetNutrientProfile !== null;
    const nutrientProfileName = this._programService.selectedNutrientProfile ? this._programService.selectedNutrientProfile.Name : '';
    const newName = this._programService.programName + ' - ' + nutrientProfileName;

    const newComment =
      this._programService.selectedNutrientProfile == null
        ? ''
        : 'Copied from ' + this._programService.selectedNutrientProfile.AssetId + ' - ' + nutrientProfileName;

    if (hasBudgetNutrientProfile) {
      // update budget nutrient profile asset name
      this._programService.budgetNutrientProfile.Name = newName;
      this._programService.budgetNutrientProfile.Comment = newComment;
    } else {
      // Create budget nutrient profile asset
      const budgetNutrientProfileClassId = SWANConstants.assetClasses.find(
        (a) => a.name == AssetClassNameEnum.BudgetNutrientProfile,
      ).id;
      const assetEntityType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;

      this._programService.budgetNutrientProfile = assetEntityType.createEntity() as Asset;
      this._programService.budgetNutrientProfile.Name = newName;
      this._programService.budgetNutrientProfile.Comment = newComment;
      this._programService.budgetNutrientProfile.AssetClassId = budgetNutrientProfileClassId;
      this._programService.budgetNutrientProfile.OwnerAccountId = this.accountId;

      this.entityManager.addEntity(this._programService.budgetNutrientProfile);
    }
  }

  private resetDistributionProfileLoadStatus() {
    this._programService.hasDistributionProfilePageLoaded = false;
  }

  private updateAnalyteBlend() {
    this.AnalyteBlend = [];
    this.NutrientBlend = [];
    this.NitrogenBlend = [];

    if (this.totalPercentage == 100) {
      const samples = [];
      this._programService.selectedSamplePointRows.forEach((row) => {
        if (!row.SamplePoint.AssetAttachments.length) {
          this._languageService.warning('NUTR.PROG.DOES_NOT_HAVE_ATTATCHMENTS', 'COMMON.WARNING', { n: row.SamplePoint.Name });
          this._languageService.warning('NUTR.PROG.CANCEL_ANALYTE_CALC');
          return;
        }
        if (row.SamplePoint.AssetAttachments[0].SampleBlob) {
          samples.push({
            assetId: row.SamplePoint.AssetId,
            value: row.Percentage,
            pH: row.SamplePoint.AssetAttachments[0].SampleBlob.sample.waterpH,
          });
        }
      });

      if (samples.length) {
        const data = {
          blend: JSON.stringify(samples),
        };

        /* get WaterBlend (Analyte Blend) coming in to the screen */
        this._http.get(CommonHelper.getApiUrl('user/getBlendedNutrients'), { params: data }).then(
          (response) => {
            if (!response.data) {
              return;
            }

            const blendedAnalytes: BlendedAnalytes = response.data as BlendedAnalytes;
            const constructedObsNutrient = { Noots: {} } as ObsNutrients;

            this.pH = blendedAnalytes.pH;

            if (blendedAnalytes.Noots?.Analytes) {
              this.AnalyteBlend =
                JSON.parse(blendedAnalytes.Noots.Analytes).map(a => ({
                  ...a,
                  value: a.Value > 0 ? a.Value : 0,
                })
              );

              constructedObsNutrient.Noots.JSONAnalytes = angular.copy(this.AnalyteBlend);
            }

            const nootKeys = Object.keys(blendedAnalytes.Noots);

            this.nutrientDecimalPlaces = this._fertiliserService.getNutrientDecimalPaces(nootKeys);
            let originalKey = null;

            nootKeys
              .filter((key) => !['NO3_N', 'NH4_N'].includes(key))
              .forEach((key) => {
                if (key === 'NO3_N') {
                  constructedObsNutrient.Noots[key] = blendedAnalytes.Noots[key];
                  originalKey = angular.copy(key);
                  key = 'NO3 as N';
                  const NO3N = blendedAnalytes.Noots[originalKey];
                  blendedAnalytes.Noots[key] = NO3N; //blendedAnalytes.Noots[oldKey];
                }

                if (key === 'NH4_N') {
                  constructedObsNutrient.Noots[key] = blendedAnalytes.Noots[key];
                  originalKey = angular.copy(key);
                  key = 'Other N';
                  const NH4N = blendedAnalytes.Noots[originalKey];
                  blendedAnalytes.Noots[key] = NH4N; //blendedAnalytes.Noots[oldKey];
                }

                this.NitrogenBlend.push({
                  name: key,
                  value: blendedAnalytes.Noots[key],
                  DateString: blendedAnalytes.NootDates[`Date_${originalKey}`],
                });
              });

            nootKeys
              .filter((key) => !['NO3_N', 'NH4_N', 'Analytes'].includes(key))
              .forEach((key) => {
                // if < then show as 0
                if (blendedAnalytes.Noots[key] < 0) {
                  blendedAnalytes.Noots[key] = 0;
                }

                constructedObsNutrient.Noots[key] = blendedAnalytes.Noots[key];

                this.NutrientBlend.push({
                  name: key,
                  value: blendedAnalytes.Noots[key],
                  DateString: blendedAnalytes.NootDates['Date_' + key],
                });
              });

            this.blendedCalculatedResults = this._samplePointService.getCalculatedResults(constructedObsNutrient.Noots);
          },
          (error) => {
            console.log(error);
          },
        );
        this.calculateAverages();
      }
    }
  }

  private fetchData() {
    const promises = [];

    promises.push(this.fetchGroupNames());
    promises.push(this.fetchNutrientProfileNames());
    promises.push(this.fetchSamplePointNames());
    promises.push(this.getProgramProfiles());

    // Fetch budget after all dropdowns have been populated
    this._q.all(promises).then((data: breeze.QueryResult[]) => {
      if (this.programId) {
        // Reload program
        this.fetchBudget(data[1].results as Asset[]).then(() => {
          this.getAverageKLPerHa();
        });

        this.nutrientProfileOnChange();
      } else {
        // load the nutrient profile selections - data[1]
        this.profileList = data[1].results as Asset[];
        this.localprofileList = this.profileList.filter((f) => f.OwnerAccountId === this.accountId);
      }
    });
  }

  private getAverageKLPerHa(): void {
    const svc = this._programService;

    if (
      !svc.startDate ||
      !svc.endDate ||
      !svc.selectedGroups?.length
    ) {
      return;
    }

    const data = {
      groupIds: svc.selectedGroups.map((a) => a.AssetId),
      fromDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(svc.startDate),
      endDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(svc.endDate),
    } as fuse.getAverageKLPerHaDto;

    this._http
      .put(CommonHelper.getApiUrl('nutrients/GetAverageKLPerHa'), data)
      .then((response) => {
        if (!response.data) {
          this._languageService.whoops();
        } else {
          const result = response.data as fuse.kilolitrePerHaDto;
          this.programDetailSiteCount = result.siteCount;
          this.programDetailSiteArea = result.areaHa;
          this.programDetailWaterAverage = result.kiloLitrePerHa;
        }
      });
  }

  private fetchBudget(nutrientProfiles: Asset[]): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const budgetEntity = this.entityManager.getEntityByKey('Budget', this.programId);

    this._programService.budgetToReload = budgetEntity as Budget;
    this._programService.programName = this._programService.budgetToReload.Name;

    const nutrientBudgetPacket = this._programService.budgetToReload.JSONNutrientBudgetPacket;

    this._programService.selectedGroups = this.relatedGroups.filter((rl) =>
      !!nutrientBudgetPacket.Groups.filter((group) => rl.AssetId === group.AssetId).length
    );
    this.selectedGroups = angular.copy(this._programService.selectedGroups);
    this._programService.plannedApplicationSites = nutrientBudgetPacket.Sites;

    this._programService.waterKLPerHa = this._programService.budgetToReload.Water_KL;

    this.profileList = nutrientProfiles;
    this.profileList.push({
      AssetId: nutrientBudgetPacket.NutrientProfile.AssetId,
      Name: nutrientBudgetPacket.NutrientProfile.Name,
      OwnerAccountId: this.accountId,
    } as Asset);

    this.localprofileList = this.profileList.filter((profile) => profile.OwnerAccountId === this.accountId);
    this._programService.selectedNutrientProfile = this.profileList.filter((pl) => pl.AssetId === nutrientBudgetPacket.NutrientProfile.AssetId)[0];
    this._programService.selectedSamplePoints = this.samplePointNames.filter((spn) => 
      !!nutrientBudgetPacket.SamplePointRows.filter((sp) => sp.SamplePoint.AssetId === spn.AssetId).length
    );

    this.samplePointsUpdate();

    const preds = [];
    nutrientBudgetPacket.ApplicationInstances.map((inst) => {
      preds.push(breeze.Predicate.create('Id', breeze.FilterQueryOp.Equals, inst.nutrientSetting.Id));
    });

    const nutrientSettingsSuccessCallback = (data: breeze.QueryResult) => {
      const appInstances = [];
      nutrientBudgetPacket.ApplicationInstances.map((inst) => {
        const matchingNutrientSettings = data.results.filter((ns: NutrientSetting) => {
          return ns.Id === inst.nutrientSetting.Id;
        });

        appInstances.push({
          instanceNum: inst.instanceNum,
          nutrientSetting: matchingNutrientSettings[0],
          spread: inst.spread,
          startDate: Date.parse(inst.startDate.toString()),
          week: inst.week,
        } as ApplicationInstance);
      });
      this._programService.applicationInstances = appInstances;
      defer.resolve();
    };
    const nutrientSettingsFailCallback = () => {
      defer.reject();
    };
    breeze.EntityQuery.from('Accounts/' + this.accountId + '/NutrientSettings')
      .where(preds[0].or(preds.slice(1, preds.length)))
      .using(this.entityManager)
      .execute(nutrientSettingsSuccessCallback, nutrientSettingsFailCallback);
    return defer.promise;
  }

  private fetchSamplePoint(samplePoints: Asset[]): Promise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.WaterSamplePoint);
    const preds = [];

    samplePoints.map((sp) => {
      preds.push(breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, sp.AssetId));
    });

    return breeze.EntityQuery.from('AccountAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred.and(breeze.Predicate.or(preds)))
      .using(this.entityManager)
      .execute();
  }

  private fetchSamplePointNames(): Promise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.WaterSamplePoint);
    const promise = breeze.EntityQuery.from('AccountAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .select('Name, AssetId')
      .using(this.entityManager)
      .execute();

    promise.then((data: breeze.QueryResult) => {
      this.samplePointNames = data.results as Asset[];
    });

    return promise;
  }

  private fetchLatestAssetAttachment(): Promise<breeze.QueryResult> {
    const assetIds = this._programService.selectedSamplePoints.map((sp) => sp.AssetId);

    return breeze.EntityQuery.from('AssetAttachments/latest').withParameters({ assetIds: assetIds }).using(this.entityManager).execute();
  }

  private getHistoricalBlend(ptId: number, pH: number) {
    const sample = [{ assetId: ptId, value: 100, pH: pH }];

    this._http.get(CommonHelper.getApiUrl('user/getBlendedNutrients'), { params: { blend: JSON.stringify(sample) } }).then((results) => {
      const historicalBlend: BlendedAnalytes = results.data as BlendedAnalytes;

      // find it in the list and update that record
      this._programService.selectedSamplePointRows.map((row) => {
        if (row.SamplePoint.AssetId === ptId) {
          if (historicalBlend.Noots.Analytes) {
            historicalBlend.Noots.JSONAnalytes = ArrayUtils.sortByString(
              JSON.parse(historicalBlend.Noots.Analytes).filter((analyte: Analyte) => !analyte.UnitString),
              x => x.Name
            );
          }

          row.sampleBlend = this._samplePointService.getCalculatedResults(historicalBlend.Noots);
          // row.SamplePoint.sampleBlend
        }
      });
    });
  }

  private fetchGroupNames(): Promise<breeze.QueryResult> {
    const successCallback = (data: breeze.QueryResult) => {
      this.relatedGroups = data.results as Asset[];
    };

    const failCallback = () => {};
    const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.SiteCMU);
    const promise = breeze.EntityQuery.from('AccountAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .select('Name, AssetId')
      .using(this.entityManager)
      .execute();

    promise.then(successCallback, failCallback);

    return promise;
  }

  private fetchNutrientProfileNames(): Promise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('AssetClass.Name', breeze.FilterQueryOp.Equals, AssetClassNameEnum.NutrientProfile)
      .and('Status', breeze.FilterQueryOp.Equals, 'Active');

    return breeze.EntityQuery.from('AccountSharedAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .select('Name, AssetId, OwnerAccountId')
      .orderBy('Name')
      .using(this.entityManager)
      .execute();
  }

  private getProgramProfiles(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('nutrients/getNutrientPrograms')).then(
      (res) => {
        if (res) {
          this.programProfiles = res.data as fuse.nutrientProgramDto[];
          defer.resolve();
        }
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private verifyGroupCropNutrientArea(groupIds: number[]) {
    const params = { groupIds: groupIds };
    this._http.get(CommonHelper.getApiUrl('nutrients/getUnsettedSitesInGroups'), { params: params }).then((result) => {
      const sites = result.data as fuse.siteProfileDto[];
      sites.forEach((site) => {
        this._languageService.info('NUTR.PROG.DOES_NOT_HAVE_CROP', 'COMMON.INFORMATION', { n: site.name });
      });
    });
  }

  private calculateAverages() {
    const calcAttributes = ['TDS', 'EC']; // 'TH', 'TC', 'MAR', 'PI', 'RSC', 'SAR', 'CCR', 'ASAR'
    const totalWeighted: number[] = [];

    calcAttributes.forEach((attr) => {
      totalWeighted[attr] = 0;
    });

    this._programService.selectedSamplePointRows.forEach((row) => {
      const sample = row.SamplePoint.AssetAttachments?.[0]?.SampleBlob?.sample;

      if (row?.Percentage && sample) {
        if (sample.totalDissolvedSolids) {
          totalWeighted['TDS'] += row.Percentage * sample.totalDissolvedSolids;
        }

        if (sample.conductivity) {
          totalWeighted['EC'] += row.Percentage * sample.conductivity;
        }
      }
    });

    for (const prop in totalWeighted) {
      this.averages[prop] = totalWeighted[prop] / 100;
    }
  }
}

angular.module('fuse').component('swanProgramGeneralDetail', new SWANProgramGeneralDetailComponent());
