import * as angular from 'angular';
import { DataEntityService } from '@services/data-entity.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { TableControl } from '@common/helpers/TableControl';
import { SampleBlob } from './sample.detail.controller';
import { AssetAttachment } from 'src/app/_DBContext/AssetAttachment';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

export class SamplesController extends BaseController {
  private _state: angular.ui.IStateService;
  private _nutrientsService: NutrientsService;

  public nameFilter = '';
  public typeFilter = '';
  public samplePointFilter = '';
  public sampledByFilter = '';
  public laboratoryFilter = '';
  private assetAttachments: AssetAttachment[];
  public filteredAssetAttachments: AssetAttachment[];
  public tableControl = new TableControl();

  constructor(
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    PermissionService: PermissionService,
    NutrientsService: NutrientsService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsSamplingFull);

    this._state = $state;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;
    
    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      this.typeFilter = NutrientsService.getTypeFilter();
      this.samplePointFilter = NutrientsService.getSamplePointFilter();
      this.sampledByFilter = NutrientsService.getSampledByFilter();
      this.laboratoryFilter = NutrientsService.getLaboratoryFilter();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
      NutrientsService.setTypeFilter(this.typeFilter);
      NutrientsService.setSamplePointFilter(this.samplePointFilter);
      NutrientsService.setSampledByFilter(this.sampledByFilter);
      NutrientsService.setLaboratoryFilter(this.laboratoryFilter);
    }
  }

  public createNewSample() {
    this._state.go('app.nutrients.sampling.detail');
  }

  $onInit() {
    this.setWatches();
    this.assetAttachments = [];
    this._fetchData();
  }

  private setWatches() {
    this.scope.$watchGroup(
      [
        'vm.nameFilter',
        'vm.typeFilter',
        'vm.samplePointFilter',
        'vm.sampledByFilter',
        'vm.laboratoryFilter',
        'vm.laboratoryIdFilter',
      ],
      () => {
        if (this.assetAttachments.length) {
          this.filterAssetAttachments();
        }
      },
    );
  }

  private filterAssetAttachments() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    this._nutrientsService.setTypeFilter(this.typeFilter);
    this._nutrientsService.setSamplePointFilter(this.samplePointFilter);
    this._nutrientsService.setSampledByFilter(this.sampledByFilter);
    this._nutrientsService.setLaboratoryFilter(this.laboratoryFilter);
    if (this.assetAttachments) {
      this.filteredAssetAttachments = this.assetAttachments.filter(
        (a) => a.Title.toLowerCase().includes(this.nameFilter.toLowerCase()),
      );
      this.filteredAssetAttachments = this.filteredAssetAttachments.filter(
        (a) => a.Type.toLowerCase().includes(this.typeFilter.toLowerCase()),
      );
      this.filteredAssetAttachments = this.filteredAssetAttachments.filter(
        (a) => a.Asset.Name.toLowerCase().includes(this.samplePointFilter.toLowerCase()),
      );
      if (this.sampledByFilter != '') {
        this.filteredAssetAttachments = this.filteredAssetAttachments.filter(
          (a) => a.SampleBlob?.sample?.technician?.toLowerCase().includes(this.sampledByFilter.toLowerCase()),
        );
      }
      if (this.laboratoryFilter != '') {
        this.filteredAssetAttachments = this.filteredAssetAttachments.filter(
          (a) => a.SampleBlob?.sample?.laboratory?.toLowerCase().includes(this.laboratoryFilter.toLowerCase()),
        );
      }
    }
  }

  private _fetchData() {
    this.fetchAssetAttachments();
  }

  private fetchAssetAttachments() {
    breeze.EntityQuery.from('AssetAttachments')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data: breeze.QueryResult) => {
        this.assetAttachments = data.results as AssetAttachment[];
        this.assetAttachments.map((program) => {
          program.Type = 'Water';
          if (program.Blob != null) {
            program.SampleBlob = JSON.parse(atob(program.Blob)) as SampleBlob;
          }
        });
        this.filterAssetAttachments();
      });
  }

  public gotoSampleDetail(assetAttachment: AssetAttachment) {
    this._state.go('app.nutrients.sampling.detail', { sampleId: assetAttachment.Id });
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
    this.samplePointFilter = '';
    this.sampledByFilter = '';
    this.laboratoryFilter = '';
  }
}

angular.module('app.nutrients').controller('SamplesController', SamplesController);
