import * as angular from 'angular';
import * as breeze from 'breeze';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { DataEntityService } from '@services/data-entity.service';
import { PermissionService } from '@services/permission.service';
import { ProgramService } from '@services/nutrients/program.service';
import { BaseController } from 'src/app/base.controller';

export class AddNutrientPhaseDialogController extends BaseController {
  public newName: string;
  public nutrientSettings: NutrientSetting[];
  public selectedNutrientSetting: NutrientSetting;

  private _mdDialog: angular.material.IDialogService;
  private _programService: ProgramService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    PermissionService: PermissionService,
    ProgramService: ProgramService,
    nutrientSettings,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._programService = ProgramService;

    this.entityManager = DataEntityService.manager;
    this.nutrientSettings = nutrientSettings;
  }

  public cancel() {
    this._mdDialog.hide();
  }

  public submit() {
    // Create Nutrient Setting
    const nutrientSettingType = this.entityManager.metadataStore.getEntityType('NutrientSetting') as breeze.EntityType;
    const newNutrientSettingEntity = nutrientSettingType.createEntity() as NutrientSetting;

    newNutrientSettingEntity.Name = this.newName;
    newNutrientSettingEntity.Percentage = this.nutrientSettings[this.nutrientSettings.length - 1].Percentage + (1 / this._programService.numOfWeeks) * 100;
    newNutrientSettingEntity.Noots = this.selectedNutrientSetting.Noots;
    newNutrientSettingEntity.AssetId = this._programService.budgetNutrientProfile.AssetId;

    this.entityManager.addEntity(newNutrientSettingEntity);

    this._mdDialog.hide();
  }
}

angular.module('app.nutrients').controller('AddNutrientPhaseDialogController', AddNutrientPhaseDialogController);
