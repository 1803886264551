import * as angular from 'angular';
import { ApplicationInstance } from '@common/nutrients.interface';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { ProgramService } from '@services/nutrients/program.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Budget } from 'src/app/_DBContext/Budget';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';

class SWANProgramPlannedApplicationComponent implements angular.IComponentOptions {
  bindings = {
    programId: '<',
    step: '<',
    stepNumber: '<',
  };
  controller = ProgramPlannedApplicationController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/programs/program-details/tabs/planned-application.component.html';
}

class ProgramPlannedApplicationController {
  public programId: number;
  public budget: Budget;
  public stepNumber: number;

  public arrOrder = [];
  public arrData = [];
  public arrDataCSVhd: string[];

  private _languageService: LanguageService;
  private _programService: ProgramService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    LanguageService: LanguageService,
    ProgramService: ProgramService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    this._languageService = LanguageService;
    this._programService = ProgramService;
    this._unitOfMeasureService = UnitOfMeasureService;
  }

  $onInit() {
    this.arrOrder = ['siteName', 'applDate', 'fertiliser', 'manufacturer', 'amount', 'areaUnits', 'amountPerSite', 'siteUnits'];
    this.arrDataCSVhd = [
      this._languageService.instant('COMMON.SITE'),
      this._languageService.instant('COMMON.APPLICATION_DATE'),
      this._languageService.instant('COMMON.FERTILISER'),
      this._languageService.instant('COMMON.MANUFACTURER'),
      this._languageService.instant('COMMON.QUANTITY_PERAREA'),
      this._languageService.instant('COMMON.AREA_UNITS'),
      this._languageService.instant('COMMON.QUANTITY_FORSITE'),
      this._languageService.instant('COMMON.SITE_UNITS'),
    ];
  }

  $onChanges(changes) {
    //console.log(changes);
    if (changes.stepNumber?.currentValue === 5) {
      this.loadPage();
    }
  }

  $onDestroy() {
    console.log('ProgramPlannedApplication closed.');
  }

  private loadPage() {
    this.arrData = [];
    let prevSiteArea = 0;
    let prevSiteName = '';
    const appInstancesLength = this._programService.fertDistributions[0].applicationInstances.length;
    this._programService.plannedApplicationSites.forEach((pas) => {
      for (let i = 0; i < appInstancesLength; i++) {
        this._programService.fertDistributions.forEach((fd, index) => {
          const fertPriceRows = this._programService.fertPrices?.filter((f) => f.FertiliserId == fd.fert.AssetId) ?? [];
          let lowprice: number = 0;
          let highprice: number = 0;
          let lowestPriceSupplier: string = null;
          let highestPriceSupplier: string = null;

          if (fertPriceRows?.length) {
            // Prices are current not shown to user, but may be reinstated later after some bugfixes
            lowprice = fertPriceRows[0].LowPrice;
            highprice = fertPriceRows[0].HighPrice;
            lowestPriceSupplier = fertPriceRows[0].LowestPriceSupplier;
            highestPriceSupplier = fertPriceRows[0].HighestPriceSupplier;
          }

          // Push it to another array for display and export
          if ((i === 0 && index === 0 ? pas : null) != null) {
            prevSiteName = (i === 0 && index === 0 ? pas : null).Name;
            prevSiteArea = (i === 0 && index === 0 ? pas : null).Site.Area;
          }

          const lastSiteNutrient = this._programService.getLastSiteNutrientSetting(
            pas.AssetId,
            fd.applicationInstances[i].startDate,
          );
          let totalQuantity;
          if (lastSiteNutrient != null && !angular.isUndefined(lastSiteNutrient)) {
            totalQuantity = lastSiteNutrient.CropNutrientArea * fd.applicationInstances[i].value;
          } else {
            totalQuantity = fd.applicationInstances[i].value * prevSiteArea;
          }

          const fertUnits = this._unitOfMeasureService.fertiliserUnits(fd.fert.Units, false);
          const fertAreaUnits = this._unitOfMeasureService.fertiliserUnits(fd.fert.Units, true);

          //push result into array for display
          this.arrData.push({
            siteName: prevSiteName,
            applDate: fd.applicationInstances[i].startDate.toString('yyyy-MM-dd'),
            fertiliser: fd.fert.Asset.Name,
            manufacturer: fd.fert.Manufacturer,
            amount: fertAreaUnits.fromBaseRounded(fd.applicationInstances[i].value),
            areaUnits: fertAreaUnits.name,
            amountPerSite: fertUnits.fromBaseRounded(totalQuantity),
            siteUnits: fertUnits.name,
            LowestPriceSupplier: lowestPriceSupplier,
            lowprice: lowprice,
            HighestPriceSupplier: highestPriceSupplier,
            highprice: highprice,
          });
        });
      }
    });
  }
}

interface SiteApplicationDistribution {
  site: Asset;
  appInstance: ApplicationInstance;
  fertiliser: Fertiliser;
}

angular.module('app.nutrients').component('swanProgramPlannedApplication', new SWANProgramPlannedApplicationComponent());
