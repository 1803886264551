import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes, unitSizes, SQLErrorCodes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { AssetAttachment } from 'src/app/_DBContext/AssetAttachment';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { BaseController } from 'src/app/base.controller';

export class SamplesDetailController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientsService: NutrientsService;

  public minDate = SWANConstants.MinDate;
  public sample: Sample = {} as Sample;
  public assetAttachment: AssetAttachment;
  public calculatedResults: CalculatedResults = {} as CalculatedResults;
  public samplePoints: Asset[];
  public selectedSamplePoint: Asset;
  public isIncludeInAnalytes: boolean = true;
  public reloadChanges: number = 0;
  public nitrogenChanges = 0;
  public obsNutrient: ObsNutrients;
  public assetAttachments: AssetAttachment[];
  public analyteEditMode: boolean = false;
  public ncAnalyteEditMode: boolean = false;

  private sampleId: number;
  public samplingDate: Date;
  public analysedDate: Date;
  public isNewSampling = false;
  public isTitleExisted = false;
  public adjustedToday: Date;
  public weightVolUnit: uomUnit;
  public condUnit: uomUnit;
  public tempUnit: uomUnit;
  public chlorUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsSamplingFull);

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;

    this.sampleId = this._state.params.sampleId;
    if (this.sampleId == null) {
      this.isNewSampling = true;
    }

    this.scope['generalForm'] = {};

    this.adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.weightVolUnit = UnitOfMeasureService.getUnits(UnitTypes.WeightVolume, unitSizes.small);
    this.condUnit = UnitOfMeasureService.getUnits(UnitTypes.Conductivity);
    this.tempUnit = UnitOfMeasureService.getUnits(UnitTypes.Temperature);
    this.chlorUnit = UnitOfMeasureService.getUnits(UnitTypes.Chlorine);
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    const promises = [] as angular.IPromise<void>[];
    promises.push(this.fetchAssetAttachments());
    promises.push(this.fetchSamplePoints());
    this._q.all(promises).then(() => {
      this._fetchData();
    });
  }

  private loadBlob() {
    if (this.assetAttachment.Blob != null) {
      const blob = JSON.parse(atob(this.assetAttachment.Blob)) as SampleBlob;
      this.sample = blob.sample as Sample;
      this.calculatedResults = blob.calculatedResults as CalculatedResults;
      if (this.sample.analysedDate) {
        this.analysedDate = this._dayNumberService.convertYMDToLocaleDate(this.sample.analysedDate);
      }
    }
    if (!angular.isDefined(this.sample)) {
      this.sample = {} as Sample;
    }
    if (!angular.isDefined(this.calculatedResults)) this.calculatedResults = {} as CalculatedResults;
  }

  private _fetchData() {
    if (this.sampleId != null) {
      this.assetAttachment = this.assetAttachments.find((a) => a.Id == this.sampleId);
      this.selectedSamplePoint = this.samplePoints.find((a) => a.AssetId == this.assetAttachment.AssetId);
      if (this.assetAttachment.DayDisplayYMD) {
        this.samplingDate = this._dayNumberService.convertYMDToLocaleDate(this.assetAttachment.DayDisplayYMD);
      }
      // #region Load Blob
      try {
        this.loadBlob();
      } catch (err) {
        //console.log((err as Error).message);
      }
      // #endregion
      this._fertiliserService.fetchObsNutrient(this.assetAttachment.AssetId, this.assetAttachment.Id).then((data) => {
        this.obsNutrient = data;
        this.reloadChanges++;
      });
    } else {
      this.samplingDate = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);

      // Create new Water Sample
      const assetAttachmentType = this.entityManager.metadataStore.getEntityType('AssetAttachment') as breeze.EntityType;

      this.assetAttachment = assetAttachmentType.createEntity() as AssetAttachment;
      this.assetAttachment.DayDisplayYMD = this.samplingDate.toString('yyyy-MM-dd');

      this.entityManager.addEntity(this.assetAttachment);

      const obsNutrientsEntityType = this.entityManager.metadataStore.getEntityType(
        'ObsNutrients',
      ) as breeze.EntityType;

      this.obsNutrient = obsNutrientsEntityType.createEntity() as ObsNutrients;
      this.obsNutrient.AssetId = this.assetAttachment.AssetId;
      this.obsNutrient.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.samplingDate);
      this.obsNutrient.AssetAttachmentId = this.assetAttachment.Id;
      this.entityManager.addEntity(this.obsNutrient);
      this.reloadChanges++; // trig analyte-form component
    }
  }

  private fetchSamplePoints(): angular.IPromise<void> {
    const waterSampleClass = SWANConstants.assetClasses.find(
      (a) => a.name.toLowerCase() == AssetClassNameEnum.WaterSamplePoint.toLowerCase(),
    );

    const defer = this._q.defer<void>();
    const pred = breeze.Predicate.create('AssetClassId', breeze.FilterQueryOp.Equals, waterSampleClass.id);

    breeze.EntityQuery.from('AccountAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .select(['Name', 'AssetId'])
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.samplePoints = data.results as Asset[];
        defer.resolve();
      });

    return defer.promise;
  }

  public updateBlob() {
    if (this.samplingDate) {
      this.assetAttachment.DayDisplayYMD = this.samplingDate.toString('yyyy-MM-dd');
      this.obsNutrient.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.samplingDate);
      this.obsNutrient.dayDisplayYMD = this.assetAttachment.DayDisplayYMD;
    }
    if (this.analysedDate) {
      this.sample.analysedDate = this.analysedDate.toString('yyyy-MM-dd');
    }
    this.assetAttachment.Blob = {}; // it is used for trigger save button
  }

  public updateAttachmentSamplePointRef() {
    this.assetAttachment.AssetId = this.selectedSamplePoint.AssetId;
    this.obsNutrient.AssetId = this.assetAttachment.AssetId;
  }

  private string2Bin(str: string): number[] {
    const result = [];
    for (let i = 0; i < str.length; i++) {
      result.push(str.charCodeAt(i));
    }
    return result;
  }

  private fetchAssetAttachments(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    breeze.EntityQuery.from('AssetAttachments')
      .select('Id, Title')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.assetAttachments = data.results as AssetAttachment[];
        defer.resolve();
      });

    return defer.promise;
  }

  private storeBlob() {
    const json = JSON.stringify({
      sample: this.sample,
      calculatedResults: this.calculatedResults,
    } as SampleBlob);
    this.assetAttachment.Blob = this.string2Bin(json);
  }

  public gotoSamplingList() {
    this._nutrientsService.setKeepFilter(true);
    this._state.go('app.nutrients.sampling');
  }

  public undoChanges() {
    this.entityManager.rejectChanges();
    this.selectedSamplePoint = this.samplePoints.filter((sp) => {
      return sp.AssetId === this.assetAttachment.AssetId;
    })[0];
    this.loadBlob();
    this.checkAssetAttachmentTitle();
    this.reloadChanges++;
  }

  public gotoSampleDetail(sampleId: number) {
    this._state.go('app.nutrients.sampling.detail', { sampleId: sampleId });
  }

  public saveChanges() {
    let isValidChanges = true;
    if (this.isTitleExisted || this.assetAttachment.Title == null || this.assetAttachment.AssetId == 0 || this.samplingDate == null) {
      isValidChanges = false;
    }
    if (isValidChanges == false) {
      angular.forEach(this.scope['generalForm'], (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          field.$setDirty();
          field.$setTouched();
        }
      });
      const alert = this._mdDialog
        .alert()
        .title(this._languageService.instant('COMMON.ALERT'))
        .htmlContent(this._languageService.instant('COMMON.PLEASE_FIX'))
        .parent(angular.element(document.body))
        .ok(this._languageService.instant('COMMON.CLOSE'))
        .targetEvent();
      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
      });
      return;
    }

    const entityChanges = this.entityManager.getChanges().filter((entity) => {
      return entity instanceof AssetAttachment;
    }) as AssetAttachment[];

    const origSamplePointId = entityChanges[0]?.entityAspect.originalValues['AssetId'];

    // Store Blob before save changes
    this.storeBlob();
    const saveOptions = {
      swanHandledErrors: [SQLErrorCodes.DuplicateKeyError],
    } as fuse.swanSaveOptions;
    this._dataEntityService.saveChanges(true, saveOptions).then(
      () => {
        // call api to update assetattachment obsnutrients to point to the new Sample Point AssetId
        if (origSamplePointId) {
          const data = { AssetAttachmentId: entityChanges[0].Id, AssetId: entityChanges[0].AssetId };
          this._http.post(CommonHelper.getApiUrl('user/ApplySamplePointRef'), data).then((result) => {});
        }
        this._languageService.showSaveSuccess();
      },
      (error) => {
        let isWhoops = true;
        if (error.httpResponse.data.Errors) {
          error.httpResponse.data.Errors.map((errorMade) => {
            if (errorMade.ErrorNumber == SQLErrorCodes.DuplicateKeyError) {
              isWhoops = false;
            }
          });
        }
        if (isWhoops) {
          this._languageService.whoops();
        } else {
          this._languageService.error('NUTR.SAMP.DATA_EXISTS');
        }
      },
    );
  }

  public dialogInfo(dialogType) {
    let theTitle: string;
    let theContent: string;

    if (dialogType == 'st') {
      theTitle = this._languageService.instant('NUTR.COMMON.CHLORIDE_CONCENTRATION_RATIO') + ' meq/L';
      theContent = "<div style='width:415px;'>" + this._languageService.instant('NUTR.SAMP.AN_INDICATION_OF_CHLORIDE');
      theContent +=
        "<table class='gridtable'><tr><td>" +
        this._languageService.instant('NUTR.SAMP.CHLORIDE_CONCENTRATION') +
        ' (meq /L)</td><td>' +
        this._languageService.instant('NUTR.SAMP.WATER_SUITABILITY') +
        '</td></tr>';
      theContent += '<tr><td>&lt;4</td><td>' + this._languageService.instant('NUTR.SAMP.EXCELLENT') + '</td></tr>';
      theContent += '<tr><td>4 - 7</td><td>' + this._languageService.instant('NUTR.SAMP.SUITABLE') + '</td></tr>';
      theContent += '<tr><td>7 - 12</td><td>' + this._languageService.instant('NUTR.SAMP.SLIGHTLY_SUITABLE') + '</td></tr>';
      theContent += '<tr><td>> 12</td><td>' + this._languageService.instant('NUTR.SAMP.NOT_SUITABLE') + '</td></tr>';
      theContent += '</table></div>';
    } else if (dialogType == 'gt') {
      theTitle =
        this._languageService.instant('NUTR.COMMON.TOTAL_HARDNESS') + ', mg/L ' + this._languageService.instant('NUTR.SAMP.AS_CACO3');
      theContent =
        "<div style='width:470px;'>" +
        this._languageService.instant('NUTR.SAMP.HARDNESS_IS') +
        this._languageService.instant('NUTR.SAMP.WATER_HARDNESS_CLASS');
      theContent +=
        "<br><br><table class='gridtable'><tr><td>" +
        this._languageService.instant('NUTR.SAMP.CLASS') +
        '</td><td>' +
        this._languageService.instant('NUTR.SAMP.CACO3_EQ') +
        ' (mg/L)</td></tr><tr><td>';
      theContent += this._languageService.instant('NUTR.SAMP.SOFT') + '</td><td>< 75</td></tr><tr><td>';
      theContent += this._languageService.instant('NUTR.SAMP.MODERATELY_HARD') + '</td><td>75 - 150</td></tr><tr><td>';
      theContent += this._languageService.instant('NUTR.SAMP.HARD') + '</td><td>150 - 300</td></tr><tr><td>';
      theContent += this._languageService.instant('NUTR.SAMP.VERY_HARD') + '</td><td>> 300</td></tr></table></div>';
    }

    this._mdDialog.show(
      this._mdDialog
        .alert()
        .clickOutsideToClose(true)
        .parent(angular.element(document.body))
        .title(theTitle)
        .htmlContent(theContent)
        .ok(this._languageService.instant('COMMON.CLOSE'))
        .targetEvent(),
    );
  }

  public checkAssetAttachmentTitle() {
    if (this.assetAttachment.Title == null) {
      this.isTitleExisted = false;
    } else {
      this.isTitleExisted = this.assetAttachments.some(
        (a) => a.Id != this.assetAttachment.Id && a.Title.toLowerCase() == this.assetAttachment.Title.toLowerCase(),
      );
    }
  }

  public onNitrogenChanged(): void {
    this.nitrogenChanges++;
  }
}

// #region Interfaces
export interface SampleBlob {
  sample: Sample;
  calculatedResults: CalculatedResults;
}

interface Sample {
  samplingDateYMD: string;
  analysedDate: string;
  technician: string;
  laboratory: string;
  laboratoryId: number;
  sampleClassification: string;
  waterpH: number;
  conductivity: number;
  totalDissolvedSolids: number;
}

export interface CalculatedResults {
  residualSodiumCarbonate: number;
  mgAdsorptionRatio: number;
  permeabilityIndex: number;
  sodiumAdsorptionRatio: number;
  totalCarbonates: number;
  chlorideConcentrationRatio: number;
  hydroxide: number;
  totalHardness: number;
  adjustedSodiumAdsorptionRatio: number;
  textCCR: string;
}
// #endregion

angular.module('app.nutrients').controller('SamplesDetailController', SamplesDetailController);
