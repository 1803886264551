import * as angular from 'angular';
import { ProfileController } from './profile.controller';

const profileConfig = ($stateProvider: angular.ui.IStateProvider) => {
  $stateProvider.state('app.profile', {
    url: '/profile',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/profile/profile.html',
        controller: ProfileController,
        controllerAs: 'vm',
      },
    },
    resolve: {},
  });
}

angular.module('app.profile').config(['$stateProvider', profileConfig]);
