import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class ProfileController extends BaseController {
  public userFullName: string;
  public appVersion: string;
  public appBuild: string;
  public currentYear: number;

  private _localStorageService: LocalStorageService;

  constructor(
    $scope: angular.IScope,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._localStorageService = LocalStorageService;

    this.userFullName = PermissionService.fullName;
    this.appVersion = AppSettings.SWANversion;
    this.appBuild = AppSettings.SWANbuild;
    this.currentYear = new Date().getFullYear();
  }

  public get hasPendingRequests(): boolean {
    return this._localStorageService.hasPendingRequests();
  }
}

angular.module('app.profile').controller('ProfileController', ProfileController);
