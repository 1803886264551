import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { Budget } from 'src/app/_DBContext/Budget';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { FertiliserPrice } from 'src/app/_DBContext/FertiliserPrice';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { SiteSettingsNutrients } from 'src/app/_DBContext/SiteSettingsNutrients';
import { UnitTypes, unitSizes } from '@common/enums';
import { K_to_K2O, P_to_P2O5, S_to_SO4 } from '@common/ratios';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { Nutrient, TargetRequirement } from '@common/nutrients.interface';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { FertiliserService, microNutrients, PriceUnit } from '@services/nutrients/fertiliser.service';
import { IFertiliserPrices, NutrientVariations, ProgramService } from '@services/nutrients/program.service';
import { BaseController } from 'src/app/base.controller';

class SWANProgramFertiliserRequirementsComponent implements angular.IComponentOptions {
  bindings = {
    programId: '<',
    step: '<',
    stepNumber: '<',
  };
  controller = ProgramFertiliserRequirementsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/programs/program-details/tabs/fertiliser-requirements.component.html';
}

export class ProgramFertiliserRequirementsController extends BaseController {
  public programId: number;
  public budget: Budget;
  public fertilisersDropDowns: Fertiliser[] = [];
  public nutrientDecimalPlaces: Record<string, number | null> = {};

  public _quantity: number = 1;
  public myTargetRequirementsAry: TargetRequirement[] = [];
  public step;
  public stepNumber;
  public totarea: number = 0;

  public selectedPhosphorus;
  public selectedPottassium;
  public selectedSulphur;
  public phosphroustemp: number;
  public potassiumtemp: number;
  public sulphurtemp: number;
  public reloaded: boolean = false;

  private _q: angular.IQService;
  private _http: angular.IHttpService = null;
  private _timeout: angular.ITimeoutService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _programService: ProgramService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private sMarket: string;
  private localFertOnly: boolean = false;

  public regexQuantity = SWANConstants.RegexDecimalOrWholePositiveNumber;

  // This variable is used for displaying the selection 'view' of either local or global fertilisers in the drop down
  // While the selected fertilisers in program service are the ones for going forward to the next phases.
  public selectedFertsForViewing: Fertiliser[] = [];
  public prevWaterinML: number = 0;
  public volUnit: uomUnit;
  public weightUnit: uomUnit;
  public weightAreaUnit: uomUnit;
  public areaUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    ProgramService: ProgramService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._q = $q;
    this._timeout = $timeout;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._programService = ProgramService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.areaUnit = UnitOfMeasureService.getUnits(UnitTypes.Area);
    this.volUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.huge);
    this.weightUnit = UnitOfMeasureService.getUnits(UnitTypes.Weight, unitSizes.small);
    this.weightAreaUnit = UnitOfMeasureService.getUnits(UnitTypes.WeightArea);
  }

  public phosphorousforms = [
    { id: 0, value: 'P' },
    { id: 1, value: 'P2O5' },
  ];
  public pottassiumforms = [
    { id: 0, value: 'K' },
    { id: 1, value: 'K2O' },
  ];
  public sulphurforms = [
    { id: 0, value: 'S' },
    { id: 1, value: 'SO4' },
  ];

  $onInit() {
    this.fillDropdown();
    this.createAllTargetRequirements();

    this.nutrientDecimalPlaces = this._fertiliserService.getNutrientDecimalPaces(microNutrients);
    this.selectedPhosphorus = this.phosphorousforms[0];
    this.selectedPottassium = this.pottassiumforms[0];
    this.selectedSulphur = this.sulphurforms[0];
  }

  $onChanges(changes) {
    if (changes.stepNumber?.currentValue === 2) {
      if (!this._programService.reloadedFromBudget) {
        this._fetchData();
      }
      if (this.prevWaterinML !== 0 && this.prevWaterinML !== this._programService.waterKLPerHa) {
        this.recalculate();
      }
      this.prevWaterinML = this._programService.waterKLPerHa;
    }
  }

  $onDestroy() {
    this._programService.selectedFerts = [];
    console.log('SWANProgramFertiliserRequirements closed.');
  }

  private loadPage() {
    this.fetchBackGroundWater(this.myTargetRequirementsAry[1]);
    this.calcTotalAreaToFertilise();
  }

  private calcTotalAreaToFertilise() {
    const mypromises = [];
    const sites: Asset[] = this._programService.plannedApplicationSites;
    this._programService.existingSiteSettingsNutrients = [];
    for (let i = 0; i < sites.length; i++) {
      const defer = this._q.defer();
      const querySite = new breeze.EntityQuery('getSiteSettingsNutrients').withParameters({ AssetId: sites[i].AssetId });
      const sitePromise = this.entityManager.executeQuery(querySite);
      sitePromise.then((s) => {
        const results = s.results as SiteSettingsNutrients[];
        this._programService.existingSiteSettingsNutrients = this._programService.existingSiteSettingsNutrients.concat(results);
        const lastSiteNutrient = this._programService.getLastSiteNutrientSetting(sites[i].AssetId, this._programService.startDate);
        if (angular.isDefined(lastSiteNutrient) && lastSiteNutrient != null) {
          const sitearea = lastSiteNutrient.CropNutrientArea != null ? lastSiteNutrient.CropNutrientArea : 0;
          defer.resolve(sitearea);
        } else defer.resolve(0);
      });
      mypromises.push(defer.promise);
    }
    this._q.all(mypromises).then((values: number[]) => {
      this.totarea = values.reduce((sum, item) => (sum += item), 0);
    });
  }

  public fillDropdown() {
    this.fertilisersDropDowns.length = 0;

    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null).and(
      'Status',
      breeze.FilterQueryOp.NotEquals,
      'Archived',
    );

    breeze.EntityQuery.from('AccountAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        const fal = data.results.map((asset: Asset) => {
          // include it if it matches market filter, or if there is a filter
          let include: boolean = true;
          if (this.sMarket) {
            include = false;
            if (asset.Fertiliser.Markets) {
              include = asset.Fertiliser.Markets.indexOf(this.sMarket) >= 0;
            }
          }

          if (include) {
            return {
              AssetId: asset.AssetId,
              Asset: { Name: asset.Name },
              Type: asset.Fertiliser.Type,
              Manufacturer: asset.Fertiliser.Manufacturer,
              Units: asset.Fertiliser.Units,
              MixingCost: asset.Fertiliser.MixingCost ? asset.Fertiliser.MixingCost : 0,
              TotVolMixInLiters: asset.Fertiliser.TotVolMixInLiters,
            } as Fertiliser;
          }
        });

        for (let idx: number = 0; idx < fal.length; idx++) {
          if (fal[idx]) {
            this.fertilisersDropDowns.push(fal[idx]);
          }
        }

        this.reloadSelectedFertilisers(ReloadSelectedFertType.FromLocalFertilisers);
      });
  }

  public toggleLocalFertOnly() {
    this.sMarket = undefined;
    if (this.localFertOnly) {
      const params = {
        assetid: this._programService.plannedApplicationSites[0].AssetId,
      };
      this._http.get(CommonHelper.getApiUrl('region/asset'), { params }).then((response) => {
        if (response.data) {
          const marketRegions = response.data as fuse.marketRegionDto[];
          this.sMarket = marketRegions[0].country;
          this.fillDropdown();
        }
      });
    } else {
      this.fillDropdown();
    }
  }

  public _fetchData() {
    if (this.programId) {
      const budgetPred = breeze.Predicate.create('Id', breeze.FilterQueryOp.Equals, parseInt(this.programId.toString()));
      const promise2 = breeze.EntityQuery.from('Budgets')
        .withParameters({ accountId: this.accountId })
        .where(budgetPred)
        .select('NutrientBudgetPacket')
        .using(this.entityManager)
        .execute()
        .then((data) => {
          this.budget = data.results[0] as Budget;
          this.budget.JSONNutrientBudgetPacket = JSON.parse(this.budget.NutrientBudgetPacket);
        }) as angular.IPromise<any>;

      // To do proper reload
      // need the list of existing fertilisers and the selected fertilisers in the nutrient budget packet
      this._q.all([promise2]).then(() => {
        this.reloadSelectedFertilisers(ReloadSelectedFertType.FromReloadProgram);
        this._programService.reloadedFromBudget = true;
      });
    }

    this.loadPage();
  }

  private reloadSelectedFertilisers(reloadType: ReloadSelectedFertType) {
    switch (reloadType) {
      case ReloadSelectedFertType.FromReloadProgram:
        this._programService.selectedFerts = [];

        this.selectedFertsForViewing = this.fertilisersDropDowns.filter((fdd) => {
          const budgetPacketFertIds = this.budget.JSONNutrientBudgetPacket.Fertilisers.map((f) => {
            return f.AssetId;
          });

          return budgetPacketFertIds.indexOf(fdd.AssetId) > -1;
        });

        this.budget.JSONNutrientBudgetPacket.Fertilisers.forEach((fert) => {
          const foundFert = this.fertilisersDropDowns.filter((existingFert) => {
            return existingFert.AssetId === fert.AssetId;
          });
          if (foundFert.length) {
            foundFert[0].MixingCost = fert.MixingCost ? fert.MixingCost : 0;
            foundFert[0].QuantityLorKG = (fert as any).Quantity;
            foundFert[0]['uomUnit'] = this._unitOfMeasureService.fertiliserUnits(foundFert[0].Units, true);
            this._programService.selectedFerts.push(foundFert[0]);
          }
        });

        this.AddNewFert();
        break;

      case ReloadSelectedFertType.FromLocalFertilisers:
        const fertIds = this._programService.selectedFerts.map((sf) => {
          return sf.AssetId;
        });

        this.selectedFertsForViewing = this.fertilisersDropDowns.filter((fdd) => {
          return fertIds.indexOf(fdd.AssetId) > -1;
        });

        break;
    }

    if (!angular.isUndefined(this.budget)) {
      const totNutTargetValues = this.budget.JSONNutrientBudgetPacket.TotalNutrientTargets;
      this.myTargetRequirementsAry[0].Noot = totNutTargetValues;
    }
  }

  /*This will create the Target Requirement Array as follows
    this.myTargetRequirementsAry[0]=> 1.Proposed requirement target(kg/ha) : Raw accept input
    this.myTargetRequirementsAry[1]=> 2.Nutrients in water(kg/ha) : Calculated value based on Sample Point selected in step1
    this.myTargetRequirementsAry[2]=> 3.Target fertiliser requirements:  (Proposed requirement target(kg/ha) - Nutrients in water(kg/ha))
    this.myTargetRequirementsAry[3]=> 4.Planned fertiliser requirements(kg) : Sum of selected fertilisers value
    this.myTargetRequirementsAry[4]=> 5.Variance (%): (Sum of selected fertilisers value - Target fertiliser requirements)
    */
  private createAllTargetRequirements() {
    let Id: number = 1;
    const myHeadings: string[] = [
      this._languageService.instant('NUTR.PROG.TOTAL_NUTRIENT_TARGETS', { name: this.weightAreaUnit.name }, false), // Disable escaping HTML characters in string interpolation.
      this._languageService.instant('NUTR.PROG.NUTRIENTS_IN_WATER') + ` (${this.weightAreaUnit.name})`,
      this._languageService.instant('NUTR.PROG.FERTILISER_TARGETS') + ` (${this.weightAreaUnit.name})`,
      this._languageService.instant('NUTR.PROG.FERTILISER_PLANNED') + ` (${this.weightAreaUnit.name})`,
      this._languageService.instant('NUTR.PROG.SUM_OF_PLANNED') + ` (${this.weightAreaUnit.name})`,
      this._languageService.instant('NUTR.PROG.VARIANCE') + ` (${this.weightAreaUnit.name})`,
    ];

    myHeadings.forEach((item) => {
      const newElement = this.createNew(Id, item);
      if (Id === 1) {
        newElement.Noot.NO3_N = 0;
        newElement.Noot.OtherN = 0;
        newElement.Noot.TotalN = 0;
        newElement.Noot.P = 0;
        newElement.Noot.K = 0;
        newElement.Noot.S = 0;
        newElement.Noot.Ca = 0;
        newElement.Noot.Mg = 0;
        newElement.Noot.Na = 0;
        newElement.Noot.Cl = 0;
        newElement.Noot.Cu = 0;
        newElement.Noot.Fe = 0;
        newElement.Noot.Mn = 0;
        newElement.Noot.Zn = 0;
        newElement.Noot.B = 0;
        newElement.Noot.Mo = 0;
      }

      // Id == 2: Nutrients in Water load into myTargetRequirementsAry[1]
      if (Id == 2) {
        // too early, this runs at load time
        //  this.fetchBackGroundWater(this.myTargetRequirementsAry[1]);
      }
      this.myTargetRequirementsAry.push(newElement);
      Id++;
    });
    this._programService.targetFertRequirements = this.myTargetRequirementsAry[2];
  }

  private createNew(id: number, reqName: string): TargetRequirement {
    const newRequirement: TargetRequirement = {} as TargetRequirement;
    let noot: Nutrient = {} as Nutrient;
    newRequirement.Id = id;
    newRequirement.Name = reqName;
    noot = this.generateDefaultValueForNoot();
    newRequirement.Noot = noot;
    return newRequirement;
  }

  private generateDefaultValueForNoot(): Nutrient {
    const noot: Nutrient = {} as Nutrient;

    noot.NO3_N = 0;
    noot.OtherN = 0;
    noot.TotalN = 0;
    noot.P = 0;
    noot.K = 0;
    noot.S = 0;
    noot.Ca = 0;
    noot.Mg = 0;
    noot.Na = 0;
    noot.Cl = 0;
    noot.Cu = 0;
    noot.Fe = 0;
    noot.Mn = 0;
    noot.Zn = 0;
    noot.B = 0;
    noot.Mo = 0;
    return noot;
  }

  public calcPhosphorus() {
    let p: number = 0;
    let p205: number = 0;
    if (this.selectedPhosphorus.id === 0) {
      //Phosphrous
      if (
        !angular.isDefined(this.phosphroustemp) ||
        this.phosphroustemp === 0 ||
        this.phosphroustemp != this.myTargetRequirementsAry[0].Noot.P
      )
        this.phosphroustemp = this.myTargetRequirementsAry[0].Noot.P;
      p205 = this.phosphroustemp;
      p = p205 / 2.2915;
      this.phosphroustemp = p;
      this.myTargetRequirementsAry[0].Noot.P = p;
    } else {
      //Phosphrous P2o
      if (!angular.isDefined(this.phosphroustemp) || this.phosphroustemp != this.myTargetRequirementsAry[0].Noot.P)
        this.phosphroustemp = this.myTargetRequirementsAry[0].Noot.P;
      p = this.phosphroustemp;
      p205 = p * 2.2915;
      this.phosphroustemp = p205;
      this.myTargetRequirementsAry[0].Noot.P = p205;
    }
    /**
     * For dealing with P/P2O5 or K /K2O or S/SO4
     */
    this.addNewNutVariation('P');
    this.recalculate();
  }

  public addNewNutVariation(nut: string) {
    const newNutrientVariation = {} as NutrientVariations;
    newNutrientVariation.NutrientName = nut;
    if (nut === 'P') {
      newNutrientVariation.IsOriginal = this.selectedPhosphorus.id === 0;
    } else if (nut === 'K') {
      newNutrientVariation.IsOriginal = this.selectedPottassium.id === 0;
    } else if (nut === 'S') {
      newNutrientVariation.IsOriginal = this.selectedSulphur.id === 0;
    }
    const exist: NutrientVariations[] = this._programService.nutVariation.filter((f) => f.NutrientName === nut);
    if (exist.length) {
      exist[0].IsOriginal = newNutrientVariation.IsOriginal;
    } else {
      this._programService.nutVariation.push(newNutrientVariation);
    }
  }

  public calcPotassium() {
    let K: number = 0;
    let K2O: number = 0;
    if (this.selectedPottassium.id === 0) {
      //Potassium
      if (
        !angular.isDefined(this.potassiumtemp) ||
        this.potassiumtemp === 0 ||
        this.potassiumtemp != this.myTargetRequirementsAry[0].Noot.K
      )
        this.potassiumtemp = this.myTargetRequirementsAry[0].Noot.K;
      K2O = this.potassiumtemp;
      K = K2O / 1.2047;
      this.potassiumtemp = K;
      this.myTargetRequirementsAry[0].Noot.K = K;
    } else {
      //Potassium K2O
      if (!angular.isDefined(this.potassiumtemp) || this.potassiumtemp != this.myTargetRequirementsAry[0].Noot.K)
        this.potassiumtemp = this.myTargetRequirementsAry[0].Noot.K;
      K = this.potassiumtemp;
      K2O = K * 1.2047;
      this.potassiumtemp = K2O;
      this.myTargetRequirementsAry[0].Noot.K = K2O;
    }
    this.addNewNutVariation('K');
    this.recalculate();
  }

  public calcSulphur() {
    let S: number = 0;
    let SO4: number = 0;
    if (this.selectedSulphur.id === 0) {
      //Sulphur
      if (!angular.isDefined(this.sulphurtemp) || this.sulphurtemp === 0 || this.sulphurtemp != this.myTargetRequirementsAry[0].Noot.S)
        this.sulphurtemp = this.myTargetRequirementsAry[0].Noot.S;
      SO4 = this.sulphurtemp;
      S = SO4 / 3;
      this.sulphurtemp = S;
      this.myTargetRequirementsAry[0].Noot.S = S;
    } else {
      //Sulphur SO4
      if (!angular.isDefined(this.sulphurtemp) || this.sulphurtemp != this.myTargetRequirementsAry[0].Noot.S)
        this.sulphurtemp = this.myTargetRequirementsAry[0].Noot.S;
      S = this.sulphurtemp;
      SO4 = S * 3;
      this.sulphurtemp = SO4;
      this.myTargetRequirementsAry[0].Noot.S = SO4;
    }
    this.addNewNutVariation('S');
    this.recalculate();
  }

  private AddNewFert() {
    const obsNutrientPromises = [];
    const fertPricesPromises = [];
    const assetLinkPromises = [];
    let allPromises = [];

    const allFertIds = this._programService.selectedFerts.map((sf) => {
      return sf.AssetId;
    });

    const fertSelectionIds = this.fertilisersDropDowns.map((fdd) => {
      return fdd.AssetId;
    });

    if (angular.isUndefined(this.selectedFertsForViewing)) {
      this.selectedFertsForViewing = [];
    }

    const viewFertIds = this.selectedFertsForViewing.map((sffv) => {
      return sffv.AssetId;
    });

    const removeIds = fertSelectionIds.filter((fsId) => {
      return viewFertIds.indexOf(fsId) === -1;
    });

    // Remove unticked fert from Program Service Selected Fertilisers
    this._programService.selectedFerts = this._programService.selectedFerts.filter((sf) => {
      return removeIds.indexOf(sf.AssetId) === -1; // return only those not finding the remove id
    });

    // Add missing fert from Program Service Selected Fertilisers
    this.selectedFertsForViewing.forEach((sffv) => {
      if (allFertIds.indexOf(sffv.AssetId) === -1) {
        this._programService.selectedFerts.push(sffv);
      }
    });

    this._programService.selectedFerts.map((selectedFert) => {
      selectedFert['uomUnit'] = this._unitOfMeasureService.fertiliserUnits(selectedFert.Units, true);
      if (
        selectedFert.Type == SWANConstants.FertiliserType.TankMix &&
        selectedFert.TotVolMixInLiters &&
        selectedFert.TotVolMixInLiters > 0 &&
        selectedFert.QuantityLorKG == null
      ) {
        selectedFert.QuantityLorKG = 0;
        //this._toastr.info("Total Volume Mix In Litres has no value, set a default value to 1.", "Info");
      }

      if (selectedFert.Type == SWANConstants.FertiliserType.Raw && selectedFert.QuantityLorKG == null) {
        //this._toastr.info('Total Volume Mix In Litres has no value, set a default value to 1.', 'Info');
        selectedFert.QuantityLorKG = 0;
      }

      // 6/6/2017 - kennethj had discussion with Tim.
      // Tank mix should have it's own ObsNutrient when it's created.
      // Here Tank mix is treated the same as Fertiliser rather than fetching the
      // child assets of a tank mix ObsNutrients
      obsNutrientPromises.push(this._fertiliserService.fetchObsNutrient(selectedFert.AssetId, null));
      assetLinkPromises.push(this._fertiliserService.fetchTankMixAssetLinks(this.accountId, selectedFert.AssetId));
      fertPricesPromises.push(this._fertiliserService.getFertiliserPrices(selectedFert.AssetId));
    });

    this._q.all(obsNutrientPromises).then((data) => {
      this._programService.selectedFerts.map((fert) => {
        data.forEach((obsNutrient: ObsNutrients) => {
          if (fert.AssetId === obsNutrient.AssetId) {
            fert.Asset.ObsNutrients = [];
            fert.Asset.ObsNutrients.push(obsNutrient);
          }
        });
      });
    });

    allPromises = fertPricesPromises.concat(assetLinkPromises);

    this._q.all(allPromises).then((data: any) => {
      this._programService.selectedFerts.map((fert) => {
        // first data set(s) are of Fertiliser Prices
        // second data set(s) are of Asset Links
        // Each type has total length / 2
        for (let i = 0; i < data.length / 2; i++) {
          // check for matching parentassetid with the selected fertiliser
          const tankMixAssetLinks = data[data.length / 2 + i].results as SiteAsset[];
          const fertPrices = data[i].results as FertiliserPrice[];
          const lowestPricePerUnit = this._fertiliserService.getMinValue(fertPrices);
          const highestPricePerUnit = this._fertiliserService.getMaxValue(fertPrices);

          const lowestPrices = fertPrices.filter((p) => p.PricePerUnit == lowestPricePerUnit);
          const lowestPriceSupplier = lowestPrices[0]?.Supplier.CompanyName ?? '';

          const highestPrices = fertPrices.filter((p) => p.PricePerUnit == highestPricePerUnit);
          const highestPriceSupplier = highestPrices[0]?.Supplier.CompanyName ?? '';

          if (tankMixAssetLinks[0] && tankMixAssetLinks[0].ParentAssetId === fert.AssetId) {
            let sumLowestPrice = 0;
            let sumHighestPrice = 0;

            angular.forEach(tankMixAssetLinks, (assetLink, value) => {
              const lowestPricePerUnit1 = this._fertiliserService.getMinValue(
                fertPrices.filter((p) => {
                  return p.AssetId == assetLink.ChildAssetId;
                }),
              );

              const price = this._fertiliserService.reCalculateMinMaxValues(
                lowestPricePerUnit1,
                assetLink.Coefficient,
                fert.QuantityLorKG,
                PriceUnit.PricePerLitre,
              );

              const highestPricePerUnit1 = this._fertiliserService.getMaxValue(
                fertPrices.filter((p) => {
                  return p.AssetId == assetLink.ChildAssetId;
                }),
              );

              const price2 = this._fertiliserService.reCalculateMinMaxValues(
                highestPricePerUnit1,
                assetLink.Coefficient,
                fert.QuantityLorKG,
                PriceUnit.PricePerLitre,
              );

              if (!Number.isNaN(price)) sumLowestPrice += price;

              if (!Number.isNaN(price2)) sumHighestPrice += price2;
            });

            /*
             *	1000L to Litre
             */
            sumLowestPrice += this._fertiliserService.getMixingCost(fert.MixingCost, fert.QuantityLorKG) / 1000;
            sumHighestPrice += this._fertiliserService.getMixingCost(fert.MixingCost, fert.QuantityLorKG) / 1000;
            fert.LowestPrice = sumLowestPrice.toFixed(2);
            fert.HighestPrice = sumHighestPrice.toFixed(2);

            this.setFertPrices_PlannedApplications2(
              fert.AssetId,
              lowestPricePerUnit,
              highestPricePerUnit,
              lowestPriceSupplier,
              highestPriceSupplier,
            );
          }

          // raw fertiliser does not have any asset links
          if (fert.Type == 'Raw' && fertPrices[0]?.AssetId === fert.AssetId) {
            fert.LowestPrice = this._fertiliserService
              .reCalculateMinMaxValues(lowestPricePerUnit, 1, fert.QuantityLorKG, PriceUnit.PricePerLitre)
              .toFixed(4);
            fert.HighestPrice = this._fertiliserService
              .reCalculateMinMaxValues(highestPricePerUnit, 1, fert.QuantityLorKG, PriceUnit.PricePerLitre)
              .toFixed(4);

            this.setFertPrices_PlannedApplications2(
              fert.AssetId,
              lowestPricePerUnit,
              highestPricePerUnit,
              lowestPriceSupplier,
              highestPriceSupplier,
            );
          }
        }
      });

      this.totalLowestHighestPrices();
      this.recalculate();
    });
  }

  private setFertPrices_PlannedApplications2(
    fertId: number,
    lowestPrice: number,
    highestPrice: number,
    lowestPriceSupplier: string,
    highestPriceSupplier: string,
  ) {
    if (this._programService.fertPrices) {
      const foundProgramFertPrices = this._programService.fertPrices.filter((f) => f.FertiliserId === fertId);

      if (!foundProgramFertPrices.length) {
        const newFertPrice = {
          FertiliserId: fertId,
          LowPrice: lowestPrice,
          HighPrice: highestPrice,
          LowestPriceSupplier: lowestPriceSupplier,
          HighestPriceSupplier: highestPriceSupplier,
        } as IFertiliserPrices;

        this._programService.fertPrices.push(newFertPrice);
      } else {
        foundProgramFertPrices[0].LowPrice = lowestPrice;
        foundProgramFertPrices[0].HighPrice = highestPrice;
      }
    }
  }

  public totalLowestPrice = 0;
  public totalHighestPrice = 0;

  private totalLowestHighestPrices() {
    let lowestTotal = 0;
    let highestTotal = 0;

    this._programService.selectedFerts.map((selectedFert) => {
      //if (selectedFert.FertiliserPrices) {
      //    let max: number = Math.max.apply(null, selectedFert.FertiliserPrices.map(item => item.PricePerUnit));
      //    total = total + (max * selectedFert.TotVolMixInLiters);
      //}
      lowestTotal += parseFloat(selectedFert.LowestPrice) * selectedFert.QuantityLorKG;
      highestTotal += parseFloat(selectedFert.HighestPrice) * selectedFert.QuantityLorKG;
    });

    const areaConv = 1.0 / this.areaUnit.fromBase(1.0); // convert price/ha to price/acre, etc

    this.totalHighestPrice = highestTotal * areaConv;
    this.totalLowestPrice = lowestTotal * areaConv;
  }

  private recalculate() {
    this.fetchBackGroundWater(this.myTargetRequirementsAry[1]);
    this.CalculateTotal();
    this.calcTargetReqmnts();
    this.calcSumOfPlannedPlusWater();
    this.calcVariance();
    this.createGraph();
    this.totalLowestHighestPrices();
  }

  private createGraph() {
    this._timeout(() => {
      this.updateChart('chartdivFertgraph1', 0, 9);
    }, 5);
    this._timeout(() => {
      this.updateChart('chartdivFertgraph2', 10, 14);
    }, 5);
    this._timeout(() => {
      this.updateChart('chartdivFertgraph3', 15, 15);
    }, 5);
    //this.updateChart('chartdivFertgraph2', 10, 14);
    //this.updateChart('chartdivFertgraph3', 15, 15);
  }

  private fetchBackGroundWater(target: TargetRequirement): void {
    const parts: NutrientFields[] = [];

    /*int AssetId, string Appln, string onDate */
    const onDate: Date = new Date();
    const sDate = onDate.toString('yyyy-MM-dd');
    const promises: angular.IPromise<void>[] = [];
    this._programService.selectedSamplePointRows.forEach((obj) => {
      // int AssetId, string Appln, string onDate
      // no verification on percentage value, we believe it is true
      const data = {
        AssetId: obj.SamplePoint.AssetId,
        Appln: (0.01 * obj.Percentage).toString(),
        dfr: '',
        dto: sDate,
      };

      // the accumulation will be in parts per litre, so we sum the results to get a 'virtual litre' from the mix
      promises.push(
        this._http.get(CommonHelper.getApiUrl('user/getNutrients'), { params: data }).then(
          (response) => {
            if (response.data) {
              // response.data will be an array with an element for each element in the Appln array (which is 1):
              // so we take the zero'th element
              parts.push(response.data[0] as NutrientFields);
            }
          },
          (error) => {
            console.log(error);
          },
        ),
      );
    });
    this._q.all(promises).then(() => {
      // initialise an empty TargetRequirement
      const result: TargetRequirement = this.createNew(-1, 'Water');

      parts.forEach((data: NutrientFields) => {
        if (data) {
          const nitraten = data.NO3_N < 0 ? 0 : data.NO3_N;
          const othern = data.NH4_N < 0 ? 0 : data.NH4_N;

          result.Noot.NO3_N += nitraten;
          result.Noot.OtherN += othern;
          result.Noot.TotalN += nitraten + othern;
          result.Noot.P += data.P < 0 ? 0 : data.P;
          result.Noot.K += data.K < 0 ? 0 : data.K;
          result.Noot.S += data.S < 0 ? 0 : data.S;
          result.Noot.Ca += data.Ca < 0 ? 0 : data.Ca;
          result.Noot.Mg += data.Mg < 0 ? 0 : data.Mg;
          result.Noot.Na += data.Na < 0 ? 0 : data.Na;
          result.Noot.Cl += data.Cl < 0 ? 0 : data.Cl;
          result.Noot.Cu += data.Cu < 0 ? 0 : data.Cu;
          result.Noot.Fe += data.Fe < 0 ? 0 : data.Fe;
          result.Noot.Mn += data.Mn < 0 ? 0 : data.Mn;
          result.Noot.Zn += data.Zn < 0 ? 0 : data.Zn;
          result.Noot.B += data.B < 0 ? 0 : data.B;
          result.Noot.Mo += data.Mo < 0 ? 0 : data.Mo;
        }
      });

      // values we've received are in mg/Liter
      // these need to be converted into kg/ha
      // but its all in /ha, so we just need to convert the mg/l into kg/ML
      // mg => g => kg == 1*10e6, l => kl => ML == 1*10e6, so ratio is already set
      // and the factor is just the co-efficient of the water quantity (mg/l)*(ML/ha) == (kg/ha)
      // but as of UoM release, we're now working in kL base, so needs /1000

      const factor: number = this._programService.waterKLPerHa / 1000;
      // factor will convert to kg/ha

      // Nitrogens
      target.Noot.NO3_N = result.Noot.NO3_N * factor;
      target.Noot.OtherN = result.Noot.OtherN * factor; // OtherN is NH4-N
      target.Noot.TotalN = result.Noot.TotalN * factor;

      // Other nutrients
      target.Noot.P = result.Noot.P * factor;
      target.Noot.K = result.Noot.K * factor;
      target.Noot.S = result.Noot.S * factor;
      target.Noot.Ca = result.Noot.Ca * factor;
      target.Noot.Mg = result.Noot.Mg * factor;
      target.Noot.Na = result.Noot.Na * factor;
      target.Noot.Cl = result.Noot.Cl * factor;
      target.Noot.Cu = result.Noot.Cu * factor;
      target.Noot.Fe = result.Noot.Fe * factor;
      target.Noot.Mn = result.Noot.Mn * factor;
      target.Noot.Zn = result.Noot.Zn * factor;
      target.Noot.B = result.Noot.B * factor;
      target.Noot.Mo = result.Noot.Mo * factor;

      this._programService.nutrientsInWater = target;
      this.calcSumOfPlannedPlusWater();
    });
  }

  //Fert.TotVolMixInLiters column temperorly used for getting the quantity
  private CalculateTotal() {
    const plannedfertListUpdate = this.myTargetRequirementsAry[3];
    let totNo3N: number = 0; //NO3N= NitrateN
    let totNH4: number = 0;

    let totP: number = 0;
    let totK: number = 0;
    let totCa: number = 0;
    let totCl: number = 0;
    let totCu: number = 0;
    let totFe: number = 0;
    let totMn: number = 0;
    let totMg: number = 0;
    let totS: number = 0;
    let totNa: number = 0;
    let totZn: number = 0;
    let totB: number = 0;
    let totMo: number = 0;
    let totOtherN: number = 0;
    let totTotN: number = 0;

    let value = 0;
    let NitrateN = 0,
      OtherN = 0;

    angular.forEach(this._programService.selectedFerts, (obj, index) => {
      (NitrateN = 0), (OtherN = 0);
      if (obj.Asset.ObsNutrients[0].Noots.NO3_N > 0) NitrateN = (obj.Asset.ObsNutrients[0].Noots.NO3_N / 100) * obj.QuantityLorKG;

      value = NitrateN;
      totNo3N = totNo3N + value;

      value = (this._fertiliserService.NH4N_to_NH4(obj.Asset.ObsNutrients[0].Noots.NH4_N) / 100) * obj.QuantityLorKG;
      totNH4 = totNH4 + value;

      value = (obj.Asset.ObsNutrients[0].Noots.NH4_N / 100) * obj.QuantityLorKG;
      totOtherN = totOtherN + value;

      if (obj.Asset.ObsNutrients[0].Noots.NO3_N > 0) NitrateN = (obj.Asset.ObsNutrients[0].Noots.NO3_N / 100) * obj.QuantityLorKG;
      if (obj.Asset.ObsNutrients[0].Noots.NH4_N > 0) OtherN = (obj.Asset.ObsNutrients[0].Noots.NH4_N / 100) * obj.QuantityLorKG;

      value = NitrateN + OtherN;
      totTotN = totTotN + value;
      value = (obj.Asset.ObsNutrients[0].Noots.P / 100) * obj.QuantityLorKG;
      totP = totP + value;

      value = (obj.Asset.ObsNutrients[0].Noots.K / 100) * obj.QuantityLorKG;
      totK = totK + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Ca / 100) * obj.QuantityLorKG;
      totCa = totCa + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Na / 100) * obj.QuantityLorKG;
      totNa = totNa + value;

      value = (obj.Asset.ObsNutrients[0].Noots.S / 100) * obj.QuantityLorKG;
      totS = totS + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Mg / 100) * obj.QuantityLorKG;
      totMg = totMg + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Cl / 100) * obj.QuantityLorKG;
      totCl = totCl + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Cu / 100) * obj.QuantityLorKG;
      totCu = totCu + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Fe / 100) * obj.QuantityLorKG;
      totFe = totFe + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Mn / 100) * obj.QuantityLorKG;
      totMn = totMn + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Zn / 100) * obj.QuantityLorKG;
      totZn = totZn + value;

      value = (obj.Asset.ObsNutrients[0].Noots.B / 100) * obj.QuantityLorKG;
      totB = totB + value;

      value = (obj.Asset.ObsNutrients[0].Noots.Mo / 100) * obj.QuantityLorKG;
      totMo = totMo + value;
    });

    //plannedfertListUpdate is the 4th row in Target Requirements array
    plannedfertListUpdate.Noot.NO3_N = totNo3N;
    plannedfertListUpdate.Noot.OtherN = totOtherN;
    plannedfertListUpdate.Noot.TotalN = totTotN;
    plannedfertListUpdate.Noot.P = totP;
    plannedfertListUpdate.Noot.K = totK;
    plannedfertListUpdate.Noot.Ca = totCa;
    plannedfertListUpdate.Noot.Mg = totMg;
    plannedfertListUpdate.Noot.S = totS;
    plannedfertListUpdate.Noot.Na = totNa;
    plannedfertListUpdate.Noot.Cl = totCl;
    plannedfertListUpdate.Noot.Cu = totCu;
    plannedfertListUpdate.Noot.Fe = totFe;
    plannedfertListUpdate.Noot.Mn = totMn;
    plannedfertListUpdate.Noot.Zn = totZn;
    plannedfertListUpdate.Noot.B = totB;
    plannedfertListUpdate.Noot.Mo = totMo;
  }

  private calcTargetReqmnts() {
    const proposedRequirements = this.myTargetRequirementsAry[0];
    const nutrientInWater = this.myTargetRequirementsAry[1];
    const targetfertReq = this.myTargetRequirementsAry[2];
    proposedRequirements.Noot.TotalN = proposedRequirements.Noot.NO3_N + proposedRequirements.Noot.OtherN;
    this.doSomeCalc(proposedRequirements, nutrientInWater, targetfertReq);
    this.calcVariance();
    this.calcSumOfPlannedPlusWater();
    this.createGraph();
    this.UpdateTargetRequirements();
  }

  private calcVariance() {
    const plannedFertRemnts = this.myTargetRequirementsAry[3];
    const targetfertReq = this.myTargetRequirementsAry[2];
    const variance = this.myTargetRequirementsAry[5];
    this.doSomeCalc(plannedFertRemnts, targetfertReq, variance);
    this.UpdateTargetRequirements();
  }

  private calcSumOfPlannedPlusWater() {
    const plannedFertRemnts = this.myTargetRequirementsAry[3];
    const nutrientInWater = this.myTargetRequirementsAry[1];
    const sumofPlannedpluswater = this.myTargetRequirementsAry[4];

    sumofPlannedpluswater.Noot.NO3_N = plannedFertRemnts.Noot.NO3_N + nutrientInWater.Noot.NO3_N;
    sumofPlannedpluswater.Noot.OtherN = plannedFertRemnts.Noot.OtherN + nutrientInWater.Noot.OtherN;
    sumofPlannedpluswater.Noot.TotalN = plannedFertRemnts.Noot.TotalN + nutrientInWater.Noot.TotalN;

    sumofPlannedpluswater.Noot.P = plannedFertRemnts.Noot.P + nutrientInWater.Noot.P;
    sumofPlannedpluswater.Noot.K = plannedFertRemnts.Noot.K + nutrientInWater.Noot.K;
    sumofPlannedpluswater.Noot.S = plannedFertRemnts.Noot.S + nutrientInWater.Noot.S;
    sumofPlannedpluswater.Noot.Ca = plannedFertRemnts.Noot.Ca + nutrientInWater.Noot.Ca;
    sumofPlannedpluswater.Noot.Mg = plannedFertRemnts.Noot.Mg + nutrientInWater.Noot.Mg;

    sumofPlannedpluswater.Noot.Na = plannedFertRemnts.Noot.Na + nutrientInWater.Noot.Na;
    sumofPlannedpluswater.Noot.Cl = plannedFertRemnts.Noot.Cl + nutrientInWater.Noot.Cl;
    sumofPlannedpluswater.Noot.Cu = plannedFertRemnts.Noot.Cu + nutrientInWater.Noot.Cu;
    sumofPlannedpluswater.Noot.Fe = plannedFertRemnts.Noot.Fe + nutrientInWater.Noot.Fe;
    sumofPlannedpluswater.Noot.Mn = plannedFertRemnts.Noot.Mn + nutrientInWater.Noot.Mn;
    sumofPlannedpluswater.Noot.Zn = plannedFertRemnts.Noot.Zn + nutrientInWater.Noot.Zn;
    sumofPlannedpluswater.Noot.B = plannedFertRemnts.Noot.B + nutrientInWater.Noot.B;
    sumofPlannedpluswater.Noot.Mo = plannedFertRemnts.Noot.Mo + nutrientInWater.Noot.Mo;
  }

  private UpdateTargetRequirements() {
    this._programService.targetFertRequirements = this.myTargetRequirementsAry[2];
    this._programService.totalNutrientTarget = this.myTargetRequirementsAry[0];
  }

  private doSomeCalc(dest: TargetRequirement, source: TargetRequirement, result: TargetRequirement) {
    let p = 0;
    let k = 0;
    let s = 0;

    result.Noot.NO3_N = dest.Noot.NO3_N - source.Noot.NO3_N;
    result.Noot.OtherN = dest.Noot.OtherN - source.Noot.OtherN;
    result.Noot.TotalN = dest.Noot.TotalN - source.Noot.TotalN;
    if (this.selectedPhosphorus.id !== 0) {
      //Phosphrous P2O5
      p = dest.Noot.P / P_to_P2O5;
    } else {
      p = dest.Noot.P;
    }
    if (this.selectedPottassium.id !== 0) {
      //K2O
      k = dest.Noot.K / K_to_K2O;
    } else {
      k = dest.Noot.K;
    }
    if (this.selectedSulphur.id !== 0) {
      //SO4
      s = dest.Noot.S / S_to_SO4;
    } else {
      s = dest.Noot.S;
    }
    result.Noot.P = p - source.Noot.P;
    result.Noot.K = k - source.Noot.K;
    result.Noot.S = s - source.Noot.S;
    result.Noot.Ca = dest.Noot.Ca - source.Noot.Ca;
    result.Noot.Mg = dest.Noot.Mg - source.Noot.Mg;

    result.Noot.Na = dest.Noot.Na - source.Noot.Na;
    result.Noot.Cl = dest.Noot.Cl - source.Noot.Cl;
    result.Noot.Cu = dest.Noot.Cu - source.Noot.Cu;
    result.Noot.Fe = dest.Noot.Fe - source.Noot.Fe;
    result.Noot.Mn = dest.Noot.Mn - source.Noot.Mn;
    result.Noot.Zn = dest.Noot.Zn - source.Noot.Zn;
    result.Noot.B = dest.Noot.B - source.Noot.B;
    result.Noot.Mo = dest.Noot.Mo - source.Noot.Mo;
  }

  public calculateTotalN(NO3_N: number, NH4_N: number, quantity: number): number {
    let NitrateN: number = 0;
    let OtherN: number = 0;
    let TotalN: number = 0;
    if (NO3_N > 0) NitrateN = (NO3_N / 100) * quantity;
    if (NH4_N > 0) OtherN = (NH4_N / 100) * quantity;

    TotalN = NitrateN + OtherN;
    return TotalN;
  }

  public calculateOtherN(NH4_N: number, quantity: number): number {
    let OtherN: number = 0;
    if (NH4_N > 0) OtherN = (NH4_N / 100) * quantity;
    return OtherN;
  }

  public calculateNitrateN(NO3_N: number, quantity: number): number {
    let NitrateN: number = 0;
    if (NO3_N > 0) NitrateN = (NO3_N / 100) * quantity;
    return NitrateN;
  }

  private updateChart(chartname: string, start: number, end: number) {
    let myPoint: MyClusteredChart;
    const myPoints: MyClusteredChart[] = [];
    const nutrient: string[] = [
      'NO3_N',
      'OtherN',
      'TotalN',
      'P',
      'K',
      'S',
      'Ca',
      'Mg',
      'Na',
      'Cl',
      'Cu',
      'Fe',
      'Mn',
      'Zn',
      'B',
      'Mo',
    ];
    const names: string[] = [
      'NO3_N',
      this._languageService.instant('NUTR.CHEM.OTHER_N'),
      this._languageService.instant('NUTR.CHEM.TOTAL_N'),
      'P',
      'K',
      'S',
      'Ca',
      'Mg',
      'Na',
      'Cl',
      'Cu',
      'Fe',
      'Mn',
      'Zn',
      'B',
      'Mo',
    ];
    let p: number;
    let t: number;
    for (let i = start; i <= end; i++) {
      p = 0;
      t = 0;
      myPoint = {} as MyClusteredChart;
      myPoint.Name = names[i];
      p = this.myTargetRequirementsAry[4].Noot[nutrient[i]];
      t = this.myTargetRequirementsAry[0].Noot[nutrient[i]];

      if (!p) p = 0;
      if (!t) t = 0;

      myPoint.PlannedValue = this.weightAreaUnit.fromBaseRounded(p, this._fertiliserService.nootDP(nutrient[i]));
      myPoint.TargetdValue = this.weightAreaUnit.fromBaseRounded(t, this._fertiliserService.nootDP(nutrient[i]));

      myPoints.push(myPoint);
    }
    const chart = AmCharts.makeChart(chartname, {
      type: 'serial',
      theme: 'light',
      categoryField: 'Name',
      rotate: true,
      startDuration: 0,
      categoryAxis: {
        gridPosition: 'start',
        position: 'left',
      },
      trendLines: [],
      graphs: [
        {
          balloonText: this._languageService.instant('NUTR.PROG.PLANNED_VALUE') + ': [[value]]',
          fillAlphas: 0.8,
          id: 'AmGraph-1',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.PLANNED'),
          type: 'column',
          fillColors: '#00b050',
          valueField: 'PlannedValue',
        },
        {
          balloonText: this._languageService.instant('NUTR.PROG.TARGETED_VALUE') + ': [[value]]',
          fillAlphas: 0.8,
          id: 'AmGraph-2',
          lineAlpha: 0.2,
          title: this._languageService.instant('NUTR.PROG.TARGETED'),
          type: 'column',
          fillColors: '#e46c0a',
          valueField: 'TargetdValue',
        },
      ],
      guides: [],
      valueAxes: [
        {
          id: 'ValueAxis-1',
          position: 'top',
          axisAlpha: 0,
        },
      ],
      allLabels: [],
      balloon: {},
      titles: [],
      dataProvider: myPoints,
      legend: {
        useGraphSettings: true,
      },
    });
  }
}

interface MyClusteredChart {
  Name: string;
  PlannedValue: number;
  TargetdValue: number;
}

export const enum ReloadSelectedFertType {
  FromReloadProgram,
  FromLocalFertilisers,
}

angular.module('fuse').component('swanProgramFertiliserRequirements', new SWANProgramFertiliserRequirementsComponent());
