// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profile .header {
  height: 120px;
  min-height: 120px;
  max-height: 320px;
  background-size: 100% auto;
}
#profile .header .profile-image {
  margin-right: 24px;
}
#profile .header .name {
  font-size: 34px;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/profile/profile.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,0BAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AACQ;EACI,eAAA;EACA,cAAA;AACZ","sourcesContent":["#profile {\r\n    .header {\r\n        height: 120px;\r\n        min-height: 120px;\r\n        max-height: 320px;\r\n        background-size: 100% auto;\r\n        .profile-image {\r\n            margin-right: 24px;\r\n        }\r\n        .name {\r\n            font-size: 34px;\r\n            color: #FFFFFF;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
