import * as angular from 'angular';
import { AnalytesController } from './analytes/analytes.controller';
import { FertiliserDetailController } from './fertiliser/fertiliser.detail.controller';
import { FertiliserController } from './fertiliser/fertilisers.controller';
import { ProfileDetailController } from './profiles/profile-detail.controller';
import { ProfilesController } from './profiles/profiles.controller';
import { ProgramDetailsController } from './programs/program-details/program-details.controller';
import { ProgramsController } from './programs/programs.controller';
import { SamplesDetailController } from './sampling/sample.detail.controller';
import { SamplesController } from './sampling/samples.controller';
import { TankMixDetailController } from './tankmix/tankmix.detail.controller';
import { TankMixController } from './tankmix/tankmixes.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

const nutrientConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.nutrients', {
    abstract: true,
    url: '/nutrients',
  });

  $stateProvider.state('app.nutrients.programs', {
    url: '/programs',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/programs/programs.html',
        controller: ProgramsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsProgramsView },
  });
  $stateProvider.state('app.nutrients.programs.create', {
    url: '/create',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/programs/program-details/program-details.html',
        controller: ProgramDetailsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsProgramsView },
  });

  $stateProvider.state('app.nutrients.programs.reload', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/programs/program-details/program-details.html',
        controller: ProgramDetailsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsProgramsView },
  });

  $stateProvider.state('app.nutrients.sampling', {
    url: '/sampling',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/sampling/samples.html',
        controller: SamplesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsSamplingView },
  });

  $stateProvider.state('app.nutrients.sampling.detail', {
    params: {
      sampleId: null,
    },
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/sampling/sample.detail.html',
        controller: SamplesDetailController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsSamplingView },
  });

  $stateProvider.state('app.nutrients.fertiliser', {
    url: '/fertiliser',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/fertiliser/fertilisers.html',
        controller: FertiliserController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsFertilisersView },
  });

  $stateProvider.state('app.nutrients.fertiliser.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/fertiliser/fertiliser.detail.html',
        controller: FertiliserDetailController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsFertilisersView },
  });

  $stateProvider.state('app.nutrients.tankmix', {
    url: '/tankmix',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/tankmix/tankmixes.html',
        controller: TankMixController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsFertilisersView },
  });

  $stateProvider.state('app.nutrients.tankmix.detail', {
    url: '/tankmix/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/tankmix/tankmix.detail.html',
        controller: TankMixDetailController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsFertilisersView },
  });

  $stateProvider.state('app.nutrients.analytes', {
    url: '/analytes',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/analytes/analytes.html',
        controller: AnalytesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsAnalytesView },
  });

  $stateProvider.state('app.nutrients.profiles', {
    url: '/profiles',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/profiles/profiles.html',
        controller: ProfilesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsProfilesView },
  });

  $stateProvider.state('app.nutrients.profiles.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/nutrients/profiles/profile-detail.html',
        controller: ProfileDetailController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.NutrientsProfilesView },
  });
}

angular.module('app.nutrients').config(['$stateProvider', nutrientConfig]);
