import * as angular from 'angular';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { BaseController } from 'src/app/base.controller';
import { TableControl } from '@common/helpers/TableControl';
import { Asset } from 'src/app/_DBContext/Asset';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { uomUnit } from '@services/unit-of-measure.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { TankMixesReportController } from './tankmixes-report-dialog-controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { UnitTypes } from '@common/enums';

export class TankMixController extends BaseController {
  public nameFilter = '';
  public fertilisers: Fertiliser[] = [];
  public filteredFertilisers: Fertiliser[] = [];
  public selectedTankMixes: Asset[] = [];
  public tableControl = new TableControl();
  public weightUnit: uomUnit;
  public fertVolUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientsService: NutrientsService;

  private mycsvDetails: any[] = [];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsFertilisersFull);

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;

    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
    }

    this.weightUnit = UnitOfMeasureService.getUnits(UnitTypes.Weight);
    this.fertVolUnit = UnitOfMeasureService.getUnits(UnitTypes.FertVolume);
  }

  $onInit() {
    this._fetchData();
    this.setWatches();
  }

  public get arrLogsDataCSVhdr() {
    const hdrs = [
      this._languageService.instant('NUTR.COMMON.TANK_MIX'),
      `${this._languageService.instant('NUTR.TANK.TOTAL_BATCH_VOLUME')} (${this.fertVolUnit}):`,
      this._languageService.instant('NUTR.COMMON.FERTILISER_NAME'),
      this._languageService.instant('COMMON.UNITS'),
      `${this._languageService.instant('NUTR.TANK.UNITS_PER_K')}${this.fertVolUnit}`,
      this._languageService.instant('COMMON.UNITS_BATCH'),
    ];
    return hdrs;
  }

  private _fetchData() {
    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null).and(
      'Fertiliser.Type',
      breeze.FilterQueryOp.Equals,
      'Tank Mix',
    );

    breeze.EntityQuery.from('AccountSharedAssets')
      .expand('Fertiliser')
      //.select("Fertiliser")
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.fertilisers = [];
        // this.fertiliserscope.fertilisers = data.results as Fertiliser[];
        data.results.map((asset: Asset) => {
          this.fertilisers.push(asset.Fertiliser);
        });
        this.filterFertilisers();
      });
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter'], () => {
      if (this.fertilisers.length) {
        this.filterFertilisers();
      }
    });
  }

  public gotoFertiliserDetail(fertiliser: Fertiliser) {
    if (fertiliser.Type === 'Tank Mix') this._state.go('app.nutrients.tankmix.detail', { id: fertiliser.AssetId });
    else this._state.go('app.nutrients.detail', { id: fertiliser.AssetId });
  }

  public showTankMixDetail(tankmixId: number) {
    const tankMix = this.selectedTankMixes.find((tm) => tm.AssetId === tankmixId);
    const promisesArray = [];

    if (tankMix) {
      const index = this.selectedTankMixes.indexOf(tankMix);
      this.selectedTankMixes.splice(index, 1);
    } else {
      var tankmixEntity = this.entityManager.getEntityByKey('Asset', tankmixId) as Asset;
      const obsNutPromise = this._fertiliserService.fetchObsNutrient(tankmixId, null);
      promisesArray.push(obsNutPromise);
      const siteAssetPromise = this.fetchAllSiteAsset(tankmixId);
      promisesArray.push(siteAssetPromise);
    }

    if (promisesArray?.length) {
      this._q.all(promisesArray).then((data) => {
        this.selectedTankMixes.push(tankmixEntity);
        this.mycsvDetails.length = 0;
        this.generateCSVArray();
      });
    } else {
      this.mycsvDetails.length = 0;
      this.generateCSVArray();
    }
  }

  // This really should be merged with the code for the Site/Group Nutrient reports' Tank Mix report, but probably beyond scope for the moment
  private generateCSVArray() {
    let first;
    //Generating csv download array here
    this.selectedTankMixes.forEach((tankMix) => {
      first = true;
      const mixvolume = this.fertVolUnit.fromBase(tankMix.Fertiliser.TotVolMixInLiters);
      const emptyRow = {
        name: '',
        volume: '',
        fertname: '',
        units: '',
        unit1000: '',
        unitsbatch: '',
      };
      this.mycsvDetails.push(emptyRow);
      tankMix.ChildAssets.forEach((child) => {
        this._fertiliserService.setUnits(child, this.weightUnit, this.fertVolUnit);
        const quantity = child.unit.fromBase(child.Coefficient);
        const units1000 = (quantity / mixvolume) * 1000;

        const row = {
          name: '',
          volume: '',
          fertname: child.ChildAsset.Name,
          units: child.unit.name,
          unit1000: units1000,
          unitsbatch: quantity,
        };
        // If this is the first row for this tank mix, add mix name and total volume
        if (first) {
          row.name = tankMix.Name;
          row.volume = this.fertVolUnit.fromBase(tankMix.Fertiliser.TotVolMixInLiters);

          first = false;
        }
        this.mycsvDetails.push(row);
      });
    });
  }

  private fetchAllSiteAsset(assetId: number): angular.IPromise<SiteAsset[]> {
    const defer = this._q.defer<SiteAsset[]>();
    const pred = new breeze.Predicate('ParentAssetId', breeze.FilterQueryOp.Equals, assetId);

    breeze.EntityQuery.from('SiteAssets')
      .expand('ChildAsset.Fertiliser')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results.length >= 1) {
          const siteAssets = data.results as SiteAsset[];
          defer.resolve(siteAssets);
        }
      });

    return defer.promise;
  }

  private filterFertilisers() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    if (this.fertilisers) {
      this.filteredFertilisers = this.fertilisers.filter((a) => a.Asset.Name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);
    }
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }

  public showTankMixesReport(): void {
    this._mdDialog.show({
      controller: TankMixesReportController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/nutrients/tankmix/tankmixes-report-dialog.html',
      locals: {
        arrLogsDataCSVhdr: this.arrLogsDataCSVhdr,
        fertVolUnit: this.fertVolUnit,
        mycsvDetails: this.mycsvDetails,
        selectedTankMixes: this.selectedTankMixes,
        weightUnit: this.weightUnit,
      },
    });
  }

  public gotoFertiliserList() {
    this._state.go('app.nutrients.fertiliser');
  }
}

angular.module('app.nutrients').controller('TankMixController', TankMixController);
