import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { SupportedLanguage } from '@indicina/swan-shared/interfaces/config/Settings';
import { DateUtils, IsoWeekday } from '@indicina/swan-shared/utils/DateUtils';
import { EnumUtils } from '@indicina/swan-shared/utils/EnumUtils';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { InitialisationService } from '@services/initialisation.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class ProfilePreferencesComponent implements angular.IComponentOptions {
  bindings = {
    hasPendingRequests: '<',
  };
  controller = ProfileUomController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/profile/tabs/profile-preferences.html';
}

class ProfileUomController extends BaseController {
  public hasPendingRequests: boolean;
  public supportedLanguages: SupportedLanguage[];
  public supportedLocales: string[];
  public longDayNames: string[];
  public isoWeekdayValues: IsoWeekday[];
  public selectedLanguageCode: string;
  public selectedLocale: string;
  public selectedStartOfWeek: IsoWeekday;

  private _http: angular.IHttpService;
  private _initialisationService: InitialisationService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    InitialisationService: InitialisationService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._initialisationService = InitialisationService;
    this._languageService = LanguageService;
  }

  private get dayNames(): string[] {
    return DateUtils.Locale.getWeekdayNamesLong(IsoWeekday.Monday);
  }

  $onInit() {
    super.$onInit();

    const selectedLanguageCode = InitialisationService.selectedLanguageCode;

    this.supportedLanguages = AppSettings.i18nexus.supportedLanguages;
    this.supportedLocales = DateUtils.Locale.getAvailableLocales(selectedLanguageCode);
    this.longDayNames = this.dayNames;
    this.isoWeekdayValues = EnumUtils.getNumberValues(IsoWeekday);
    this.selectedLanguageCode = selectedLanguageCode;
    this.selectedLocale = this.getDefaultLocale(InitialisationService.selectedLocale);
    this.selectedStartOfWeek = InitialisationService.selectedStartOfWeek;
  }

  public async handleChangeLanguage(): Promise<void> {
    try {
      const response = await this._http.put(CommonHelper.getApiUrl('users/changeLanguage'), { languageCode: this.selectedLanguageCode });

      if (response) {
        this._initialisationService.setLanguage(this.selectedLanguageCode);

        // Handle locale update based on selected language.
        this.supportedLocales = DateUtils.Locale.getAvailableLocales(this.selectedLanguageCode); // Refresh the available locales for selected language.
        this.selectedLocale = this.getDefaultLocale(InitialisationService.selectedLocale);

        this.setLocale(this.selectedLocale);

        this._languageService.success('TOOLBAR.CHANGE_LANGUAGE_SUCCESS');
      } else {
        this._languageService.error('TOOLBAR.CHANGE_LANGUAGE_FAILED');
      }
    }
    catch {
      this._languageService.error('TOOLBAR.CHANGE_LANGUAGE_FAILED');
    }
  }

  public handleChangeLocale(): void {
    this.setLocale(this.selectedLocale);
  }

  public handleChangeStartOfWeek(): void {
    this._initialisationService.setStartOfWeek(this.selectedStartOfWeek);
  }

  private getDefaultLocale(locale: string): string {
    const browserLocale = navigator.language;

    return this.supportedLocales.some(x => x.startsWith(locale))
      ? locale
      : this.supportedLocales.includes(browserLocale)
          ? browserLocale
          : this.supportedLocales[0];
  }

  private setLocale(locale: string): void {
    this._initialisationService.setLocale(locale);

    // Refresh the day names for selected language and locale.
    this.longDayNames = this.dayNames;
  }
}

angular.module('app.profile').component('profilePreferences', new ProfilePreferencesComponent());
