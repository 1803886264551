import * as angular from 'angular';
import * as breeze from 'breeze';
import { SysAnalyte } from 'src/app/_DBContext/SysAnalyte';
import { Unit } from 'src/app/_DBContext/Unit';
import { UnitBaseClass } from 'src/app/_DBContext/UnitBaseClass';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddAnalyteDialogController extends BaseController {
  public newName: string;
  public newAnalyte: SysAnalyte;
  public unitbaseClasses: UnitBaseClass[];
  public units: Unit[];

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    unitBaseClasses: UnitBaseClass[],
    units: Unit[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.unitbaseClasses = unitBaseClasses;
    this.units = units;
  }

  public cancel() {
    this._mdDialog.hide();
  }

  public submit() {
    // Create Analyte
    const analyteType = this.entityManager.metadataStore.getEntityType('SysAnalyte') as breeze.EntityType;
    const newAnalyteEntity = analyteType.createEntity() as SysAnalyte;

    newAnalyteEntity.Name = this.newAnalyte.Name;
    newAnalyteEntity.Symbol = this.newAnalyte.Symbol;
    newAnalyteEntity.Class = 'Analyte';
    newAnalyteEntity.DataInputId = 14;
    newAnalyteEntity.UnitBaseClassId = this.newAnalyte.UnitBaseClassId;
    newAnalyteEntity.UnitClassId = this.newAnalyte.UnitClassId;

    this.entityManager.addEntity(newAnalyteEntity);

    // Navigate to the new Analyte
    this._dataEntityService.saveChanges().then((value: breeze.SaveResult) => {
      this._languageService.showSaveSuccess();
      this._mdDialog.hide();
      this._state.go('app.nutrients.analyte');
    });
  }

  private goToAnalyteDetail(analyte: SysAnalyte) {
    this._state.go('app.nutrients.analyte.detail', { id: analyte.Id });
  }
}

angular.module('app.nutrients').controller('AddAnalyteDialogController', AddAnalyteDialogController);
