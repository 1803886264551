import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { NutrientService } from '@services/nutrients/nutrient.service';
import { BaseController } from 'src/app/base.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { unitSizes, AmGraphDataValues } from '@common/enums';
import { PermissionService } from '@services/permission.service';

class NutrientSampleNitrogenComponent implements angular.IComponentOptions {
  bindings = {
    obsNutrient: '=',
    reloadChanges: '<',
    nitrogenChanged: '&',
  };
  controller = NutrientSampleNitrogenController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/components/nutrient-sample-nitrogen.component.html';
}

class NutrientSampleNitrogenController extends BaseController {
  public selectedCompound1;
  public selectedCompound2;
  public selectedCompound4;
  public parentPage: string;
  public asset: Asset;
  public fert: Fertiliser;
  public assetId: number;
  public selectedUnitC;
  public nitrateNValue: number = 0;
  public no3Value: number = 0;
  public nh4Value: number = 0;
  public totalNValue: number = 0;
  public otherNValue: number = 0;

  public obsNutrient: ObsNutrients;
  public noot: NutrientFields;

  public nitrogenSigns: string[] = [];
  public uomUnit: uomUnit;

  public theChartData = [];
  public weightVolUnit: uomUnit;

  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientService: NutrientService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private nitrogenChanged: Function;

  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    NutrientService: NutrientService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientService = NutrientService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.weightVolUnit = this._unitOfMeasureService.getUnits('Weight/Volume', unitSizes.small);
  }

  public compounds = [
    { id: 0, value: 'NO3' },
    { id: 1, value: 'NH4' },
    { id: 2, value: 'Nitrate N' },
    { id: 3, value: 'Other N' },
    { id: 4, value: 'NO2' },
  ];

  $onInit() {
    this.selectedCompound1 = this.compounds[0];
    this.selectedCompound2 = this.compounds[1];
    this.selectedCompound4 = this.compounds[3];
  }

  $onDestroy() {
    this.entityManager.clear();
  }

  $onChanges(changes) {
    //Temp fix for Cancel changes where models are not binding directly to control
    if (changes.reloadChanges?.currentValue > 0) {
      if (this.obsNutrient != null) {
        this.selectedCompound1 = this.compounds[0]; // force NO3 selection instead of NO2
        this.no3Value = this._fertiliserService.NO3N_to_NO3(this.obsNutrient.Noots.NO3_N);
        this.nh4Value = this._fertiliserService.NH4N_to_NH4(this.obsNutrient.Noots.NH4_N);

        this.nitrogenSigns['NO3'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NO3_N);
        this.no3Value *= this.obsNutrient.Noots.NO3_N < 0 && this.obsNutrient.Noots.NO3_N !== -9999 ? -1 : 1;

        this.nitrogenSigns['NH4'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NH4_N);
        this.nh4Value *= this.obsNutrient.Noots.NH4_N < 0 && this.obsNutrient.Noots.NH4_N !== -9999 ? -1 : 1;

        this.calculate(true);
      }
    }
  }

  public calculate(isEdit: boolean) {
    this.doCalculation(isEdit);
  }

  public doCalculation(isEdit: boolean) {
    if (!isEdit) {
      // update database values
      this.obsNutrient.Noots.NO3_N = this._nutrientService.calculateDBValue(this.nitrogenSigns['NitrateN'], this.nitrateNValue);
      this.obsNutrient.Noots.NH4_N = this._nutrientService.calculateDBValue(this.nitrogenSigns['OtherN'], this.otherNValue);
    } else {
      // update nutrient signs
      this.nitrogenSigns['NitrateN'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NO3_N);
      this.nitrogenSigns['OtherN'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NH4_N);
    }

    // update display values NO3
    this.nitrateNValue = this._nutrientService.calculateDisplayValue(this.obsNutrient.Noots.NO3_N);
    const tempNO3Value = this._fertiliserService.NO3N_to_NO3(this.obsNutrient.Noots.NO3_N);
    this.no3Value = tempNO3Value < 0 ? 0 : tempNO3Value;

    // update display values NH4
    this.otherNValue = this._nutrientService.calculateDisplayValue(this.obsNutrient.Noots.NH4_N);
    const tempNH4Value = this._fertiliserService.NH4N_to_NH4(this.obsNutrient.Noots.NH4_N);
    this.nh4Value = tempNH4Value < 0 ? 0 : tempNH4Value;

    // update display values TotalN
    this.totalNValue =
      (this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.nitrateNValue) + (this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.otherNValue);

    // update chart
    this.updateChart();
    this.nitrogenChanged();
  }

  private updateChart() {
    this.theChartData = [
      {
        name: this._languageService.instant('NUTR.CHEM.TOTAL_N'),
        value: this.weightVolUnit.fromBase(this.totalNValue),
      },
      {
        name: this._languageService.instant('NUTR.CHEM.NO3'),
        value: this.weightVolUnit.fromBase(this.no3Value),
      },
      {
        name: this._languageService.instant('NUTR.CHEM.NH4'),
        value: this.weightVolUnit.fromBase(this.nh4Value),
      },
      {
        name: this._languageService.instant('NUTR.CHEM.NITRATE_AS_N'),
        value: this.weightVolUnit.fromBase(this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.nitrateNValue),
      },
      {
        name: this._languageService.instant('NUTR.CHEM.OTHER_N'),
        value: this.weightVolUnit.fromBase(this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.otherNValue),
      },
    ];

    AmCharts.makeChart('nitrogen-summary-chart', {
      type: 'serial',
      theme: 'light',
      dataProvider: this.theChartData,
      backgroundColor: '#EFEFEF',
      backgroundAlpha: 0.8,
      valueAxes: [
        {
          gridColor: '#FFFFFF',
          gridAlpha: 0,
          labelsEnabled: false,
          axisAlpha: 0,
        },
      ],
      gridAboveGraphs: true,
      startDuration: 0,
      graphs: [
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          fillColors: '#67b7dc',
          type: 'column',
          valueField: 'value',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            if (graphDataItem.values) {
              const val = (graphDataItem.values as AmGraphDataValues).value;
              const txt = graphDataItem.category;
              if (val) {
                return txt + ': ' + val.toFixed(2);
              }
            }
            return '';
          },
        },
      ],
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
      },
      categoryField: 'name',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      },
    } as AmCharts.AmSerialChart);
  }
}

angular.module('app.nutrients').component('nutrientSampleNitrogen', new NutrientSampleNitrogenComponent());
