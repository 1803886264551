import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { SysAnalyte } from 'src/app/_DBContext/SysAnalyte';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';
import { Unit } from 'src/app/_DBContext/Unit';
import { UnitBaseClass } from 'src/app/_DBContext/UnitBaseClass';
import { BaseController } from 'src/app/base.controller';

export class UpdateSysAnalyteController extends BaseController {
  public isSwanSystemAccount: boolean;
  public sysAnalyte: SysAnalyte;
  public units: Unit[];
  public unitbaseClasses: UnitBaseClass[];
  public weightAreaNormalUnit: string;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    item: SysAnalyte,
    unitBaseClasses: UnitBaseClass[],
    units: Unit[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.isSwanSystemAccount = PermissionService.isSwanSystemAccount;
    this.sysAnalyte = item;
    this.unitbaseClasses = unitBaseClasses;
    this.units = units;

    this.weightAreaNormalUnit = this._unitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);
  }

  public getMesurementUnit(theId) {
    let theIdName = '-';
    if (this.units != null) {
      for (let j = 0; j < this.units.length; j++) {
        if (this.units[j].Id == theId) {
          theIdName = this.units[j].Name;
          break;
        }
      }
    }
    return theIdName;
  }

  //Not using as something wrong with breeze cache
  public submitSomethingWrong() {
    const sysanalyte = this.sysAnalyte;

    if (sysanalyte.SysAnalyteClients.length === 1) {
      sysanalyte.SysAnalyteClients[0].AccountId = this.accountId;
      sysanalyte.SysAnalyteClients[0].AnalyteId = sysanalyte.Id;
    }

    const p = breeze.Predicate.create('AnalyteId', '==', sysanalyte.Id).and('AccountId', '==', this.accountId);
    const allsysclients = this.entityManager.getEntities('SysAnalyteClient');

    if (allsysclients.length) {
      const sysclient = breeze.EntityQuery.fromEntities(allsysclients).where(p).using(this.entityManager).executeLocally();
      if (sysclient.length) {
        //Check if object exist in Cache, update
        const updateSysClient = sysclient[0] as SysAnalyteClient;
        //updateSysClient.UnitClass = sysanalyte.SysAnalyteClients[0].UnitClass;  //Not required anymore
        updateSysClient.SubClass = sysanalyte.SysAnalyteClients[0].SubClass;
        updateSysClient.MaxThreshold = sysanalyte.SysAnalyteClients[0].MaxThreshold;
        updateSysClient.TargetRequirements = sysanalyte.SysAnalyteClients[0].TargetRequirements;
        updateSysClient.IsIncludeInAnalytes =
          sysanalyte.SysAnalyteClients[0].IsIncludeInAnalytes == null
            ? false
            : sysanalyte.SysAnalyteClients[0].IsIncludeInAnalytes;
        updateSysClient.IsIncludeInFertiliser =
          sysanalyte.SysAnalyteClients[0].IsIncludeInFertiliser == null
            ? false
            : sysanalyte.SysAnalyteClients[0].IsIncludeInFertiliser;
      } else {
        //Check if object exist in Cache, if not add new entity to cache
        this.createNewSysAnalyteClient(sysanalyte.SysAnalyteClients[0]);
      }
    } else {
      //If there is no data in cache fetch from database
      let sysclients: SysAnalyteClient;

      const defer = this._q.defer();
      breeze.EntityQuery.from('SysAnalyteClient')
        .withParameters({ accountId: this.accountId })
        .using(this.entityManager)
        .where(p)
        .execute()
        .then((data) => {
          sysclients = data.results[0] as SysAnalyteClient;
          defer.resolve(sysclients);
        });

      defer.promise.then((data) => {
        if (data) {
          //update existing
        } else {
          //add new
          this.createNewSysAnalyteClient(sysanalyte.SysAnalyteClients[0]);
        }
      });
    }

    this._mdDialog.hide();

    // Navigate to the new Analyte
    this.entityManager.saveChanges().then((value: breeze.SaveResult) => {
      this._languageService.showSaveSuccess();
      this._state.go('app.nutrients.analyte');
    });
  }

  //previous coding for submit commented above. please refer if any change
  public submit() {
    this._mdDialog.hide();

    // Navigate to the new Analyte
    this.entityManager.saveChanges().then((value: breeze.SaveResult) => {
      this._languageService.showSaveSuccess();
      this._state.go('app.nutrients.analyte');
    });
  }

  private createNewSysAnalyteClient(sysanalyteClient: SysAnalyteClient) {
    let clientAnalyte: SysAnalyteClient;
    const clientanalyteType = this.entityManager.metadataStore.getEntityType('SysAnalyteClient') as breeze.EntityType;
    const newclientAnalyteEntity = clientanalyteType.createEntity(clientAnalyte) as SysAnalyteClient;

    newclientAnalyteEntity.AccountId = sysanalyteClient.AccountId;
    newclientAnalyteEntity.AnalyteId = sysanalyteClient.AnalyteId;
    // newclientAnalyteEntity.UnitClass = sysanalyteClient.UnitClass; //not required anymore
    newclientAnalyteEntity.SubClass = sysanalyteClient.SubClass;
    newclientAnalyteEntity.MaxThreshold = sysanalyteClient.MaxThreshold;
    newclientAnalyteEntity.TargetRequirements = sysanalyteClient.TargetRequirements;
    newclientAnalyteEntity.IsIncludeInAnalytes = sysanalyteClient.IsIncludeInAnalytes == null ? false : sysanalyteClient.IsIncludeInAnalytes;
    newclientAnalyteEntity.IsIncludeInFertiliser = sysanalyteClient.IsIncludeInFertiliser == null ? false : sysanalyteClient.IsIncludeInFertiliser;

    this.entityManager.addEntity(newclientAnalyteEntity);
  }

  public cancel() {
    this._mdDialog.hide();
  }
}

angular.module('app.nutrients').controller('UpdateSysAnalyteController', UpdateSysAnalyteController);
