import * as angular from 'angular';
import * as moment from 'moment';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { UnitTypes, unitSizes } from '@common/enums';
import { ApplicationInstance, FertDistribution, TargetRequirement } from '@common/nutrients.interface';
import { LanguageService } from '@services/language.service';
import { DataEntityService } from '@services/data-entity.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit, UnitOfMeasureService } from '@services/unit-of-measure.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { ProgramService } from '@services/nutrients/program.service';
import { BaseController } from 'src/app/base.controller';
import { Budget } from 'src/app/_DBContext/Budget';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { ObjectUtils } from '@indicina/swan-shared/utils/ObjectUtils';

class SWANProgramFertiliserDistributionComponent implements angular.IComponentOptions {
  bindings = {
    programId: '<',
    step: '<',
    stepNumber: '<',
  };
  controller = ProgramFertiliserDistributionController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/programs/program-details/tabs/fertiliser-distribution.component.html';
}

class ProgramFertiliserDistributionController extends BaseController {
  public programId: number;
  public budget: Budget;
  public step;
  public stepNumber;
  public nutrientSettings: NutrientSetting[] = [];
  public applicationInstances: ApplicationInstance[];
  public NutDistPhasees: NutDistPhase[] = [];
  public selectedLegends: string[] = [];
  public flag: number = 0;
  public newApplicationInstances: ApplicationInstance[] = [];
  public prevApplicationInstance: ApplicationInstance[] = [];
  public prevWaterInML: number = 0;
  public IsOnetimebinding: boolean = true;
  public showAll: boolean = false;
  public prevfertSelected: Fertiliser[] = [];
  public prevTargetRequirements: TargetRequirement;
  public waterRaw: any;
  public weightAreaUnit: uomUnit;
  public volAreaUnit: uomUnit;
  public _unitOfMeasureService: UnitOfMeasureService;

  private _q: angular.IQService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _programService: ProgramService;

  constructor(
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    ProgramService: ProgramService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._q = $q;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._programService = ProgramService;

    this.entityManager = DataEntityService.manager;
    this.selectedLegends = [];
    this.weightAreaUnit = UnitOfMeasureService.getUnits(UnitTypes.WeightArea);
    this.volAreaUnit = UnitOfMeasureService.getUnits(UnitTypes.VolumeArea, unitSizes.huge);
    this._unitOfMeasureService = UnitOfMeasureService;
  }

  $onChanges(changes) {
    if (
      changes.stepNumber?.currentValue === 4 &&
      angular.isDefined(this._programService.selectedFerts) &&
      angular.isDefined(this._programService.applicationInstances)
    ) {
      this.newApplicationInstances.length = 0; //New application instances add extra phase to applicationInstances if spread is enabled, else applicationInstances = newApplicationInstances
      this.createNewApplicationInstances(); //If need this method will create new instances based on any spread is defined or not.
    }
  }

  private generateFertDistributionList(appInstances: ApplicationInstance[]) {
    let appInstancevaluesChanged: boolean = false;
    let selectedFertvaluesChanged: boolean = false;
    let targetvaluesChanged: boolean = false;
    let waterinMLChanged: boolean = false;
    if (this.prevfertSelected.length) {
      selectedFertvaluesChanged = ObjectUtils.isEqual(this._programService.selectedFerts, this.prevfertSelected);
    }
    if (this.prevApplicationInstance.length) {
      appInstancevaluesChanged = ObjectUtils.isEqual(this._programService.applicationInstances, this.prevApplicationInstance);
    }

    if (this.prevTargetRequirements) {
      targetvaluesChanged = ObjectUtils.isEqual(this._programService.totalNutrientTarget, this.prevTargetRequirements);
    }
    if (this.prevWaterInML !== this._programService.waterKLPerHa) {
      waterinMLChanged = true; //Planned value changed So Need to recreate first row of table structure as well as graph
    }
    if (!appInstancevaluesChanged || !selectedFertvaluesChanged || waterinMLChanged) {
      //If there any changes in the fertiliser selection, applictaion Instance values or quantity entered, need to recreate array with the new values
      this._programService.fertDistributions.length = 0;
      this.createNewFertDistribution(appInstances);
    }
    if (targetvaluesChanged) {
      //If target values changed that affect only graph array
      this.calculateNutrientDistribution();
    }

    this.prevApplicationInstance = this._programService.applicationInstances.map((a) => Object.assign({}, a));
  }

  private createNewFertDistribution(appInstances: ApplicationInstance[]) {
    const existingFertDistribution: FertDistribution[] = [];

    this.prevfertSelected = this._programService.selectedFerts;
    this.prevTargetRequirements = this._programService.totalNutrientTarget;

    //Reloading an existing program
    if (this.programId) {
      const budgetPred = breeze.Predicate.create('Id', breeze.FilterQueryOp.Equals, parseInt(this.programId.toString()));

      breeze.EntityQuery.from('Budgets')
        .withParameters({ accountId: this.accountId })
        .where(budgetPred)
        .select('NutrientBudgetPacket')
        .using(this.entityManager)
        .execute()
        .then((data) => {
          this.budget = data.results[0] as Budget;
          this.budget.JSONNutrientBudgetPacket = JSON.parse(this.budget.NutrientBudgetPacket);

          this.budget.JSONNutrientBudgetPacket.FertDistributions?.forEach((distribution) => {
            existingFertDistribution.push(distribution);
          });

          this.addNewFertDistribution(appInstances, existingFertDistribution);
        });
    } else {
      //Creating a new Program
      this.addNewFertDistribution(appInstances, existingFertDistribution);
    }
  }

  private addNewFertDistribution(appInstances: ApplicationInstance[], existingFertDistribution: FertDistribution[]) {
    let totalApplying: number = 0;

    angular.forEach(this._programService.selectedFerts, (data) => {
      totalApplying = 0;
      const newFert: FertDistribution = {} as FertDistribution;
      const newAppInstances: ApplicationInstance[] = [];
      newFert.fert = data;

      const fertDistribution = existingFertDistribution?.filter((f) => f.fert.AssetId === data.AssetId) ?? [];

      for (let i = 0; i < appInstances.length; i++) {
        const newAppinstance: ApplicationInstance = {} as ApplicationInstance;

        newAppinstance.value = 0;

        if (fertDistribution.length) {
          const intermediary: ApplicationInstance[] = fertDistribution[0].applicationInstances.filter((a) => a.week === appInstances[i].week);

          newAppinstance.value = intermediary[0]?.value;
        }

        newAppinstance.instanceNum = appInstances[i].instanceNum;
        newAppinstance.week = appInstances[i].week;
        newAppinstance.startDate = appInstances[i].startDate;
        newAppinstance.nutrientSetting = appInstances[i].nutrientSetting;
        newAppInstances.push(newAppinstance);
        totalApplying += newAppinstance.value;
      }

      newFert.totalApplying = totalApplying;
      newFert.applicationInstances = newAppInstances;

      this._programService.fertDistributions.push(newFert);
    });

    this.createWaterAsapuesdoFert(appInstances);
    this.calculateNutrientDistribution(); //This will generate the array for graph.
    this.prevWaterInML = this._programService.waterKLPerHa;
  }

  private createWaterAsapuesdoFert(appInstances: ApplicationInstance[]) {
    let totalApplying: number = 0;
    const waterKLPerHa = this._programService.waterKLPerHa;
    const newAppInstances: ApplicationInstance[] = [];
    const noofWeeks = this._programService.numOfWeeks ? this._programService.numOfWeeks : 52;
    const perweekML = waterKLPerHa / noofWeeks;
    let totalnoofWeeks: number = 0;
    for (let i = 0; i < appInstances.length; i++) {
      const newAppinstance: ApplicationInstance = {} as ApplicationInstance;
      if (i === appInstances.length - 1) {
        totalnoofWeeks = noofWeeks - appInstances[i].week;
      } else {
        totalnoofWeeks = appInstances[i + 1].week - appInstances[i].week;
      }
      newAppinstance.value = totalnoofWeeks * perweekML;
      newAppinstance.instanceNum = appInstances[i].instanceNum;
      newAppinstance.week = appInstances[i].week;
      newAppinstance.startDate = appInstances[i].startDate;
      newAppinstance.nutrientSetting = appInstances[i].nutrientSetting;
      totalApplying += newAppinstance.value;

      if (i === appInstances.length - 1) {
        //Making the Total Planned and Total applied to be equal
        const diff = totalApplying - waterKLPerHa;
        if (diff !== 0) {
          // 5918-rounding can cause negative so we change to ABS
          newAppinstance.value = Math.abs(newAppinstance.value - diff);
          totalApplying = totalApplying - diff;
        }
      }
      newAppInstances.push(newAppinstance);
    }
    this.waterRaw = {
      name: 'Water',
      manufacturer: '',
      TotVolMixInKL: waterKLPerHa,
      applicationInstances: newAppInstances,
      totalApplying: totalApplying,
    };
  }

  public reCalculateWater(row: any) {
    let totalApplying: number = 0;
    row.applicationInstances.forEach((data) => {
      totalApplying += data.value;
    });
    row.totalApplying = totalApplying;
    this.calculateNutrientDistribution();
  }

  public reCalculate(distributionRow: FertDistribution) {
    let totalApplying: number = 0;
    distributionRow.applicationInstances.forEach((data) => {
      totalApplying += data.value;
    });
    distributionRow.totalApplying = totalApplying;
    this.calculateNutrientDistribution();
  }

  private createNewAppInstance(
    instanceno: number,
    nutrientProfileForthatPhase: NutrientSetting,
    startdate: Date,
  ): ApplicationInstance {
    const newElement = {} as ApplicationInstance;
    newElement.instanceNum = instanceno;
    newElement.nutrientSetting = nutrientProfileForthatPhase;
    newElement.startDate = startdate; // need one week adjustment from the getDateofISOWeek date
    newElement.week = startdate.getWeek();
    newElement.spread = true;
    return newElement;
  }

  private calculateNoOfWeeks(spreadStartDate: Date, spreadEndDate: Date): number {
    const week = 7;

    const date1ms = moment(spreadStartDate);
    const date2ms = moment(spreadEndDate);

    const diff = date2ms.diff(date1ms, 'days');

    const totnoofweek: number = Math.floor(diff / week);
    return totnoofweek;
  }

  private createNewNutrientProfile(phase: ApplicationInstance, totnoofweek: number): NutrientSetting {
    const newNutrientSetting: NutrientSetting = {} as NutrientSetting;
    const nutFields: NutrientFields = {} as NutrientFields;
    this.initNutfields(nutFields);
    newNutrientSetting.Noots = nutFields;
    const currentphasenutSettings = phase.nutrientSetting;
    newNutrientSetting.Noots.NO3_N = currentphasenutSettings.Noots.NO3_N / totnoofweek;
    newNutrientSetting.Noots.NH4_N = currentphasenutSettings.Noots.NH4_N / totnoofweek;
    newNutrientSetting.Noots.P = currentphasenutSettings.Noots.P / totnoofweek;
    newNutrientSetting.Noots.K = currentphasenutSettings.Noots.K / totnoofweek;
    newNutrientSetting.Noots.Ca = currentphasenutSettings.Noots.Ca / totnoofweek;
    newNutrientSetting.Noots.Mg = currentphasenutSettings.Noots.Mg / totnoofweek;
    newNutrientSetting.Noots.Na = currentphasenutSettings.Noots.Na / totnoofweek;
    newNutrientSetting.Noots.S = currentphasenutSettings.Noots.S / totnoofweek;
    newNutrientSetting.Noots.Cl = currentphasenutSettings.Noots.Cl / totnoofweek;
    newNutrientSetting.Noots.Cu = currentphasenutSettings.Noots.Cu / totnoofweek;
    newNutrientSetting.Noots.Fe = currentphasenutSettings.Noots.Fe / totnoofweek;
    newNutrientSetting.Noots.Mn = currentphasenutSettings.Noots.Mn / totnoofweek;
    newNutrientSetting.Noots.B = currentphasenutSettings.Noots.B / totnoofweek;
    newNutrientSetting.Noots.Mo = currentphasenutSettings.Noots.Mo / totnoofweek;
    newNutrientSetting.Noots.Zn = currentphasenutSettings.Noots.Zn / totnoofweek;
    return newNutrientSetting;
  }

  /*spread functionality : this._programService.applicationInstances hold each phase and if a spread is enabled in any phase
     this method will create extra phases and add to a new Array*/
  private createNewApplicationInstances() {
    let instanceno: number = 1;
    const Phases: ApplicationInstance[] = this._programService.applicationInstances;
    for (let i = 0; i < Phases.length; i++) {
      const phase: ApplicationInstance = Phases[i];
      const nextphase: ApplicationInstance = Phases[i + 1];
      const spread: boolean = phase.spread;

      if (spread && Phases.length > 1) {
        //If there is a spread , generate the spread week

        const spreadStartDate: Date = phase.startDate;

        let spreadEndDate: Date;
        const isLastPhase = i === Phases.length - 1;
        if (isLastPhase)
          //Is this the last phase and there is spread defined??
          spreadEndDate = this._programService.endDate;
        else spreadEndDate = nextphase.startDate;

        let totnoofweek: number = this.calculateNoOfWeeks(spreadStartDate, spreadEndDate);
        if (isLastPhase) {
          totnoofweek++;
        }
        if (totnoofweek > 0) {
          const newNutrientSetting = this.createNewNutrientProfile(phase, totnoofweek);
          let days = 0;

          for (let row = 0; row < totnoofweek; row++) {
            const newdate = moment(spreadStartDate).add(days, 'd');
            const newElement = this.createNewAppInstance(instanceno, newNutrientSetting, newdate.toDate());
            newElement.week = phase.week + row;
            this.newApplicationInstances.push(newElement);
            instanceno++;
            days += 7;
          }
        }
      } else {
        //If there is no spread then copy that week from current to NewInstance array.
        phase.instanceNum = instanceno;
        //phase.week = phase.startDate.getWeek();
        phase.spread = false;
        this.newApplicationInstances.push(phase);
        instanceno++;
      }
    }

    //Based on the New Application Instances, create a fert distribution list.
    this.generateFertDistributionList(this.newApplicationInstances);
  }

  private calculateNutrientDistribution() {
    const Phases: ApplicationInstance[] = this._programService.applicationInstances;
    this.NutDistPhasees.length = 0;
    //this.newApplicationInstances.length = 0;

    // Really should redo all these calcs in terms of these variables when we get the chance
    const noots = ['NO3_N', 'OtherN', 'TotalN', 'P', 'K', 'Ca', 'Mg', 'S', 'Na', 'Cl', 'Cu', 'Fe', 'Mn', 'Zn', 'B', 'Mo'];

    let TotWeightageofNo3N = 0;
    let TotWeightageofOtherN = 0;
    let TotWeightageofP = 0;
    let TotWeightageofK = 0;
    let TotWeightageofCa = 0;
    let TotWeightageofMg = 0;
    let TotWeightageofS = 0;
    let TotWeightageofNa = 0;
    let TotWeightageofCl = 0;
    let TotWeightageofCu = 0;
    let TotWeightageofFe = 0;
    let TotWeightageofMn = 0;
    let TotWeightageofZn = 0;
    let TotWeightageofB = 0;
    let TotWeightageofMo = 0;

    //Calculate TotalWeightageof nutreint element in all phases
    angular.forEach(Phases, (data) => {
      // the conversion is not required due to the fact that the user input is the number of 'N' on NO3
      TotWeightageofNo3N += data.nutrientSetting.Noots.NO3_N;

      TotWeightageofOtherN += data.nutrientSetting.Noots.NH4_N;

      TotWeightageofP += data.nutrientSetting.Noots.P;
      TotWeightageofK += data.nutrientSetting.Noots.K;
      TotWeightageofCa += data.nutrientSetting.Noots.Ca;
      TotWeightageofMg += data.nutrientSetting.Noots.Mg;
      TotWeightageofS += data.nutrientSetting.Noots.S;
      TotWeightageofNa += data.nutrientSetting.Noots.Na;
      TotWeightageofCl += data.nutrientSetting.Noots.Cl;
      TotWeightageofCu += data.nutrientSetting.Noots.Cu;
      TotWeightageofFe += data.nutrientSetting.Noots.Fe;
      TotWeightageofMn += data.nutrientSetting.Noots.Mn;
      TotWeightageofZn += data.nutrientSetting.Noots.Zn;
      TotWeightageofB += data.nutrientSetting.Noots.B;
      TotWeightageofMo += data.nutrientSetting.Noots.Mo;
    });

    let NutDistPhase: NutDistPhase;
    let TotNO3NCum = 0;
    let NO3NCumPlanned = 0;
    let NO3NCumTgt = 0;
    let TotOtherNCum = 0;
    let OtherNCumPlanned = 0;
    let OtherNCumTgt = 0;
    let TotalNCumTgt = 0;
    let TotPCum = 0;
    let PCumTgt = 0;
    let PCumPlanned = 0;
    let TotKCum = 0;
    let KCumTgt = 0;
    let KCumPlanned = 0;
    let TotMgCum = 0;
    let MgCumTgt = 0;
    let MgCumPlanned = 0;
    let TotSCum = 0;
    let SCumTgt = 0;
    let SCumPlanned = 0;
    let TotMoCum = 0;
    let MoCumTgt = 0;
    let MoCumPlanned = 0;
    let TotBCum = 0;
    let BCumTgt = 0;
    let BCumPlanned = 0;
    let TotZnCum = 0;
    let ZnCumTgt = 0;
    let ZnCumPlanned = 0;
    let TotMnCum = 0;
    let MnCumTgt = 0;
    let MnCumPlanned = 0;
    let TotCuCum = 0;
    let CuCumTgt = 0;
    let CuCumPlanned = 0;
    let TotFeCum = 0;
    let FeCumTgt = 0;
    let FeCumPlanned = 0;
    let TotClCum = 0;
    let ClCumTgt = 0;
    let ClCumPlanned = 0;
    let TotNaCum = 0;
    let NaCumTgt = 0;
    let NaCumPlanned = 0;
    let TotCaCum = 0;
    let CaCumTgt = 0;
    let CaCumPlanned = 0;

    //Each Fertiliser and its applictaion instances get here
    this.NutDistPhasees.length = 0;

    /*Generating the graph values starts here, here we are considering each Phase, target nutrient value(14 elements), planned nutrient value(14 elements) and cumulative values*/
    for (let i = 0; i < this.newApplicationInstances.length; i++) {
      NutDistPhase = {} as NutDistPhase;
      this.initNutDist(NutDistPhase);
      let weightageProfile: NutrientSetting = this.newApplicationInstances[i].nutrientSetting;
      NutDistPhase.Phase = DateUtils.Locale.asDateMedium(this.newApplicationInstances[i].startDate);

      if (!weightageProfile.Noots) {
        const settings = this.entityManager.getEntityByKey('NutrientSetting', weightageProfile.Id);
        weightageProfile = settings as NutrientSetting;
      }

      //Target Nutrient Values for each phase
      NutDistPhase.NO3NWeeklyTarget = this.calculateTargetNutrientValue('NO3_N', weightageProfile.Noots.NO3_N, TotWeightageofNo3N);
      NutDistPhase.OtherNWeeklyTarget = this.calculateTargetNutrientValue(
        'OtherN',
        weightageProfile.Noots.NH4_N,
        TotWeightageofOtherN,
      );
      NutDistPhase.TotalNWeeklyTarget = NutDistPhase.NO3NWeeklyTarget + NutDistPhase.OtherNWeeklyTarget;
      NutDistPhase.PWeeklyTarget = this.calculateTargetNutrientValue('P', weightageProfile.Noots.P, TotWeightageofP);
      NutDistPhase.KWeeklyTarget = this.calculateTargetNutrientValue('K', weightageProfile.Noots.K, TotWeightageofK);
      NutDistPhase.CaWeeklyTarget = this.calculateTargetNutrientValue('Ca', weightageProfile.Noots.Ca, TotWeightageofCa);
      NutDistPhase.MgWeeklyTarget = this.calculateTargetNutrientValue('Mg', weightageProfile.Noots.Mg, TotWeightageofMg);
      NutDistPhase.SWeeklyTarget = this.calculateTargetNutrientValue('S', weightageProfile.Noots.S, TotWeightageofS);
      NutDistPhase.NaWeeklyTarget = this.calculateTargetNutrientValue('Na', weightageProfile.Noots.Na, TotWeightageofNa);
      NutDistPhase.ClWeeklyTarget = this.calculateTargetNutrientValue('Cl', weightageProfile.Noots.Cl, TotWeightageofCl);
      NutDistPhase.CuWeeklyTarget = this.calculateTargetNutrientValue('Cu', weightageProfile.Noots.Cu, TotWeightageofCu);
      NutDistPhase.FeWeeklyTarget = this.calculateTargetNutrientValue('Fe', weightageProfile.Noots.Fe, TotWeightageofFe);
      NutDistPhase.MnWeeklyTarget = this.calculateTargetNutrientValue('Mn', weightageProfile.Noots.Mn, TotWeightageofMn);
      NutDistPhase.BWeeklyTarget = this.calculateTargetNutrientValue('B', weightageProfile.Noots.B, TotWeightageofB);
      NutDistPhase.MoWeeklyTarget = this.calculateTargetNutrientValue('Mo', weightageProfile.Noots.Mo, TotWeightageofMo);
      NutDistPhase.ZnWeeklyTarget = this.calculateTargetNutrientValue('Zn', weightageProfile.Noots.Zn, TotWeightageofZn);

      //Cumulative Target Nutrient Values for each phase
      NO3NCumTgt += NutDistPhase.NO3NWeeklyTarget;
      NutDistPhase.NO3NCumTgt = NO3NCumTgt;
      OtherNCumTgt += NutDistPhase.OtherNWeeklyTarget;
      NutDistPhase.OtherNCumTgt = OtherNCumTgt;

      TotalNCumTgt += NutDistPhase.TotalNWeeklyTarget;
      NutDistPhase.TotalNCumTgt = TotalNCumTgt;

      PCumTgt += NutDistPhase.PWeeklyTarget;
      NutDistPhase.PCumTgt = PCumTgt;

      KCumTgt += NutDistPhase.KWeeklyTarget;
      NutDistPhase.KCumTgt = KCumTgt;

      CaCumTgt += NutDistPhase.CaWeeklyTarget;
      NutDistPhase.CaCumTgt = CaCumTgt;

      CuCumTgt += NutDistPhase.CuWeeklyTarget;
      NutDistPhase.CuCumTgt = CuCumTgt;

      MgCumTgt += NutDistPhase.MgWeeklyTarget;
      NutDistPhase.MgCumTgt = MgCumTgt;

      SCumTgt += NutDistPhase.SWeeklyTarget;
      NutDistPhase.SCumTgt = SCumTgt;

      NaCumTgt += NutDistPhase.NaWeeklyTarget;
      NutDistPhase.NaCumTgt = NaCumTgt;

      ClCumTgt += NutDistPhase.ClWeeklyTarget;
      NutDistPhase.ClCumTgt = ClCumTgt;

      FeCumTgt += NutDistPhase.FeWeeklyTarget;
      NutDistPhase.FeCumTgt = FeCumTgt;

      MnCumTgt += NutDistPhase.MnWeeklyTarget;
      NutDistPhase.MnCumTgt = MnCumTgt;

      ZnCumTgt += NutDistPhase.ZnWeeklyTarget;
      NutDistPhase.ZnCumTgt = ZnCumTgt;

      BCumTgt += NutDistPhase.BWeeklyTarget;
      NutDistPhase.BCumTgt = BCumTgt;

      MoCumTgt += NutDistPhase.MoWeeklyTarget;
      NutDistPhase.MoCumTgt = MoCumTgt;

      TotNO3NCum = 0;
      TotOtherNCum = 0;
      TotPCum = 0;
      TotKCum = 0;
      TotCaCum = 0;
      TotMgCum = 0;
      TotSCum = 0;
      TotNaCum = 0;
      TotClCum = 0;
      TotCuCum = 0;
      TotFeCum = 0;
      TotMnCum = 0;
      TotZnCum = 0;
      TotBCum = 0;
      TotMoCum = 0;

      //As we are condiering a phase, sometimes a phase have multiple fertilisers
      angular.forEach(this._programService.fertDistributions, (data: FertDistribution) => {
        TotNO3NCum += this.calculatePlannedNutrientValue(data, i, 'NO3_N');
        TotOtherNCum += this.calculatePlannedNutrientValue(data, i, 'NH4_N');
        TotPCum += this.calculatePlannedNutrientValue(data, i, 'P');
        TotKCum += this.calculatePlannedNutrientValue(data, i, 'K');
        TotCaCum += this.calculatePlannedNutrientValue(data, i, 'Ca');
        TotMgCum += this.calculatePlannedNutrientValue(data, i, 'Mg');
        TotSCum += this.calculatePlannedNutrientValue(data, i, 'S');
        TotNaCum += this.calculatePlannedNutrientValue(data, i, 'Na');
        TotClCum += this.calculatePlannedNutrientValue(data, i, 'Cl');
        TotCuCum += this.calculatePlannedNutrientValue(data, i, 'Cu');
        TotFeCum += this.calculatePlannedNutrientValue(data, i, 'Fe');
        TotMnCum += this.calculatePlannedNutrientValue(data, i, 'Mn');
        TotZnCum += this.calculatePlannedNutrientValue(data, i, 'Zn');
        TotBCum += this.calculatePlannedNutrientValue(data, i, 'B');
        TotMoCum += this.calculatePlannedNutrientValue(data, i, 'Mo');

        data.fert.Unit = this._unitOfMeasureService.fertiliserUnits(data.fert.Units, true);
      });

      //Water logic comes here, Nutrients in water also added with fertiliser nutrients for each phase.
      if (this.waterRaw) {
        const waterApplied = this.waterRaw.applicationInstances[i].value;
        TotNO3NCum += this.calculateNutrientsInWater(waterApplied, 'NO3_N');
        TotOtherNCum += this.calculateNutrientsInWater(waterApplied, 'OtherN');
        TotPCum += this.calculateNutrientsInWater(waterApplied, 'P');
        TotKCum += this.calculateNutrientsInWater(waterApplied, 'K');
        TotCaCum += this.calculateNutrientsInWater(waterApplied, 'Ca');
        TotMgCum += this.calculateNutrientsInWater(waterApplied, 'Mg');
        TotSCum += this.calculateNutrientsInWater(waterApplied, 'S');
        TotNaCum += this.calculateNutrientsInWater(waterApplied, 'Na');
        TotClCum += this.calculateNutrientsInWater(waterApplied, 'Cl');
        TotCuCum += this.calculateNutrientsInWater(waterApplied, 'Cu');
        TotFeCum += this.calculateNutrientsInWater(waterApplied, 'Fe');
        TotMnCum += this.calculateNutrientsInWater(waterApplied, 'Mn');
        TotZnCum += this.calculateNutrientsInWater(waterApplied, 'Zn');
        TotBCum += this.calculateNutrientsInWater(waterApplied, 'B');
        TotMoCum += this.calculateNutrientsInWater(waterApplied, 'Mo');
      }

      //Weekly Planned Nutrient Values
      NutDistPhase.NO3NWeeklyPlanned = TotNO3NCum;
      NutDistPhase.OtherNWeeklyPlanned = TotOtherNCum;
      const temp = TotNO3NCum + TotOtherNCum;
      NutDistPhase.TotalNWeeklyPlanned = temp;
      NutDistPhase.PWeeklyPlanned = TotPCum;
      NutDistPhase.KWeeklyPlanned = TotKCum;
      NutDistPhase.CaWeeklyPlanned = TotCaCum;
      NutDistPhase.MgWeeklyPlanned = TotMgCum;
      NutDistPhase.SWeeklyPlanned = TotSCum;
      NutDistPhase.NaWeeklyPlanned = TotNaCum;
      NutDistPhase.ClWeeklyPlanned = TotClCum;
      NutDistPhase.CuWeeklyPlanned = TotCuCum;
      NutDistPhase.FeWeeklyPlanned = TotFeCum;
      NutDistPhase.MnWeeklyPlanned = TotMnCum;
      NutDistPhase.ZnWeeklyPlanned = TotZnCum;
      NutDistPhase.BWeeklyPlanned = TotBCum;
      NutDistPhase.MoWeeklyPlanned = TotMoCum;

      //Cumulative Planned Nutrient Values for each phase
      NO3NCumPlanned += TotNO3NCum;
      OtherNCumPlanned += TotOtherNCum;
      PCumPlanned += TotPCum;
      KCumPlanned += TotKCum;
      CaCumPlanned += TotCaCum;
      MgCumPlanned += TotMgCum;
      SCumPlanned += TotSCum;
      NaCumPlanned += TotNaCum;
      ClCumPlanned += TotClCum;
      CuCumPlanned += TotCuCum;
      FeCumPlanned += TotFeCum;
      MnCumPlanned += TotMnCum;
      ZnCumPlanned += TotZnCum;
      BCumPlanned += TotBCum;
      MoCumPlanned += TotMoCum;

      //Finally, Planned Cumulative for each element
      NutDistPhase.NO3NCumPlanned = NO3NCumPlanned;
      NutDistPhase.OtherNCumPlanned = OtherNCumPlanned;
      NutDistPhase.TotalNCumPlanned = NO3NCumPlanned + OtherNCumPlanned;
      NutDistPhase.PCumPlanned = PCumPlanned;
      NutDistPhase.KCumPlanned = KCumPlanned;
      NutDistPhase.CaCumPlanned = CaCumPlanned;
      NutDistPhase.MgCumPlanned = MgCumPlanned;
      NutDistPhase.SCumPlanned = SCumPlanned;
      NutDistPhase.NaCumPlanned = NaCumPlanned;
      NutDistPhase.ClCumPlanned = ClCumPlanned;
      NutDistPhase.CuCumPlanned = CuCumPlanned;
      NutDistPhase.FeCumPlanned = FeCumPlanned;
      NutDistPhase.MnCumPlanned = MnCumPlanned;
      NutDistPhase.ZnCumPlanned = ZnCumPlanned;
      NutDistPhase.BCumPlanned = BCumPlanned;
      NutDistPhase.MoCumPlanned = MoCumPlanned;

      noots.forEach((noot) => {
        ['CumPlanned', 'WeeklyPlanned', 'CumTgt', 'WeeklyTarget'].forEach((suff) => {
          const tag = noot + suff;
          NutDistPhase[tag] = this.weightAreaUnit.fromBaseRounded(NutDistPhase[tag], this._fertiliserService.nootDP(noot));
        });
      });

      this.NutDistPhasees.push(NutDistPhase);
    }
    this.createChartNew(0);
  }

  private calculateNutrientsInWater(waterApplied: number, element) {
    const nutrientinWaterInTotal_element =
      angular.isDefined(this._programService.nutrientsInWater.Noot[element]) &&
      angular.isDefined(this._programService.nutrientsInWater.Noot)
        ? this._programService.nutrientsInWater.Noot[element]
        : 0;
    const nutrientinWaterapplied_element = (nutrientinWaterInTotal_element / this._programService.waterKLPerHa) * waterApplied;
    return this.weightAreaUnit.fromBase(nutrientinWaterapplied_element);
  }

  private calculateTargetNutrientValue(
    element: string,
    weightageofElement: number,
    totalweightofElementInAllPhases: number,
  ): number {
    const TargetValueOfElement =
      angular.isDefined(this._programService.totalNutrientTarget.Noot[element]) &&
      angular.isDefined(this._programService.totalNutrientTarget.Noot)
        ? this._programService.totalNutrientTarget.Noot[element]
        : 0;
    const t = (TargetValueOfElement * weightageofElement) / totalweightofElementInAllPhases;
    return this.weightAreaUnit.fromBase(t);
  }

  private initNutDist(NutDistPhase: NutDistPhase) {
    NutDistPhase.NO3NWeeklyTarget = 0;
    NutDistPhase.NO3NCumPlanned = 0;
    NutDistPhase.NO3NCumTgt = 0;
    NutDistPhase.OtherNWeeklyTarget = 0;
    NutDistPhase.TotalNWeeklyTarget = 0;
    NutDistPhase.PWeeklyTarget = 0;
    NutDistPhase.KWeeklyTarget = 0;
    NutDistPhase.CaWeeklyTarget = 0;
    NutDistPhase.MgWeeklyTarget = 0;
    NutDistPhase.SWeeklyTarget = 0;
    NutDistPhase.NaWeeklyTarget = 0;
    NutDistPhase.ClWeeklyTarget = 0;
    NutDistPhase.CuWeeklyTarget = 0;
    NutDistPhase.FeWeeklyTarget = 0;
    NutDistPhase.MnWeeklyTarget = 0;
    NutDistPhase.BWeeklyTarget = 0;
    NutDistPhase.MoWeeklyTarget = 0;
  }

  private initNutfields(NutDistPhase: NutrientFields) {
    NutDistPhase.NO3_N = 0;
    NutDistPhase.NH4_N = 0;
    NutDistPhase.P = 0;
    NutDistPhase.K = 0;
    NutDistPhase.Ca = 0;
    NutDistPhase.Mg = 0;
    NutDistPhase.S = 0;
    NutDistPhase.Na = 0;
    NutDistPhase.Cl = 0;
    NutDistPhase.Cu = 0;
    NutDistPhase.Fe = 0;
    NutDistPhase.Mn = 0;
    NutDistPhase.B = 0;
    NutDistPhase.Mo = 0;
  }

  private calculatePlannedNutrientValue(data: FertDistribution, i: number, element: string): number {
    //Get a fertiliser
    let result: number = 0;
    let perofelementin1Kg = 0;
    let amountofElementin1Kg = 0;
    const fertinkg = data.applicationInstances[i].value;
    switch (element) {
      case 'TotalN':
        perofelementin1Kg = data.fert.Asset.ObsNutrients[0].Noots.NO3_N + data.fert.Asset.ObsNutrients[0].Noots.NH4_N;
        //weightageofElementthisPhase = weightageChart.Noots['NO3_N'] * re + (weightageChart.Noots['NH4_N'] * (1 - re))
        break;
      default:
        perofelementin1Kg = data.fert.Asset.ObsNutrients[0].Noots[element]; //nutrients[element];
        // weightageofElementthisPhase = weightageChart.Noots[element];
        break;
    }

    amountofElementin1Kg = perofelementin1Kg / 100; // Amount of NO3_N  in 1 Kg
    //applyingElement = weightageofElementthisPhase * amountofElementin1Kg;//So we got how much NO3_N needs to apply in 1 kg of this fert

    //So this fert we got total 40 kg in first phase
    result = fertinkg * amountofElementin1Kg;
    const t = result;
    return this.weightAreaUnit.fromBase(t);
  }

  private legendclick(graph: any) {
    const chart = graph.chart;
    if (graph.hidden) {
      //and now enabling the graph
      for (let i = 0; i < chart.graphs.length; i++) {
        if (graph.id == chart.graphs[i].id) {
          chart.showGraph(chart.graphs[i]);
          const selected: string = graph.id;
          this.selectedLegends.push(selected);
          break;
        }
      }
    } else {
      //currently enabled. so making it hidden.
      for (let i = 0; i < chart.graphs.length; i++) {
        if (graph.id == chart.graphs[i].id) {
          chart.hideGraph(chart.graphs[i]);
          const index = this.selectedLegends.indexOf(graph.id);
          this.selectedLegends.splice(index, 1);
          break;
        }
      }
    }
  }

  private createChartNew(shownone: number) {
    const wp = this._languageService.instant('NUTR.PROG.WEEKLY_PLANNED');
    const cp = this._languageService.instant('NUTR.PROG.CUMULATIVE_PLANNED');
    const wt = this._languageService.instant('NUTR.PROG.WEEKLY_TARGET');
    const ct = this._languageService.instant('NUTR.PROG.CUMULATIVE_TARGET');
    const tn = this._languageService.instant('NUTR.CHEM.TOTAL_N');
    const on = this._languageService.instant('NUTR.CHEM.OTHER_N');

    const nutrient: string[] = [
      'TotalNWeeklyTarget',
      'TotalNWeeklyPlanned',
      'TotalNCumTgt',
      'TotalNCumPlanned',
      'PWeeklyTarget',
      'PWeeklyPlanned',
      'PCumTgt',
      'PCumPlanned',
      'KWeeklyTarget',
      'KWeeklyPlanned',
      'KCumTgt',
      'KCumPlanned',
      'SWeeklyTarget',
      'SWeeklyPlanned',
      'SCumTgt',
      'SCumPlanned',
      'NO3NWeeklyTarget',
      'NO3NWeeklyPlanned',
      'NO3NCumTgt',
      'NO3NCumPlanned',
      'OtherNWeeklyTarget',
      'OtherNWeeklyPlanned',
      'OtherNCumTgt',
      'OtherNCumPlanned',
      'CaWeeklyTarget',
      'CaWeeklyPlanned',
      'CaCumTgt',
      'CaCumPlanned',
      'MgWeeklyTarget',
      'MgWeeklyPlanned',
      'MgCumTgt',
      'MgCumPlanned',
      'NaWeeklyTarget',
      'NaWeeklyPlanned',
      'NaCumTgt',
      'NaCumPlanned',
      'ClWeeklyTarget',
      'ClWeeklyPlanned',
      'ClCumTgt',
      'ClCumPlanned',
      'CuWeeklyTarget',
      'CuWeeklyPlanned',
      'CuCumTgt',
      'CuCumPlanned',
      'FeWeeklyTarget',
      'FeWeeklyPlanned',
      'FeCumTgt',
      'FeCumPlanned',
      'MnWeeklyTarget',
      'MnWeeklyPlanned',
      'MnCumTgt',
      'MnCumPlanned',
      'ZnWeeklyTarget',
      'ZnWeeklyPlanned',
      'ZnCumTgt',
      'ZnCumPlanned',
      'BWeeklyTarget',
      'BWeeklyPlanned',
      'BCumTgt',
      'BCumPlanned',
      'MoWeeklyTarget',
      'MoWeeklyPlanned',
      'MoCumTgt',
      'MoCumPlanned',
    ];

    const nutrientTitles: string[] = [
      tn + wt,
      tn + wp,
      tn + ct,
      tn + cp,
      'P' + wt,
      'P' + wp,
      'P' + ct,
      'P' + cp,
      'K' + wt,
      'K' + wp,
      'K' + ct,
      'K' + cp,
      'S' + wt,
      'S' + wp,
      'S' + ct,
      'S' + cp,
      'NO3N' + wt,
      'NO3N' + wp,
      'NO3N' + ct,
      'NO3N' + cp,
      on + wt,
      on + wp,
      on + ct,
      on + cp,
      'Ca' + wt,
      'Ca' + wp,
      'Ca' + ct,
      'Ca' + cp,
      'Mg' + wt,
      'Mg' + wp,
      'Mg' + ct,
      'Mg' + cp,
      'Na' + wt,
      'Na' + wp,
      'Na' + ct,
      'Na' + cp,
      'Cl' + wt,
      'Cl' + wp,
      'Cl' + ct,
      'Cl' + cp,
      'Cu' + wt,
      'Cu' + wp,
      'Cu' + ct,
      'Cu' + cp,
      'Fe' + wt,
      'Fe' + wp,
      'Fe' + ct,
      'Fe' + cp,
      'Mn' + wt,
      'Mn' + wp,
      'Mn' + ct,
      'Mn' + cp,
      'Zn' + wt,
      'Zn' + wp,
      'Zn' + ct,
      'Zn' + cp,
      'B' + wt,
      'B' + wp,
      'B' + ct,
      'B' + cp,
      'Mo' + wt,
      'Mo' + wp,
      'Mo' + ct,
      'Mo' + cp,
    ];

    const nutrientgraphcolor: string[] = [
      '#d62929',
      '#eb9494',
      '#961d1d',
      '#e77e7e',
      '#4da6ff',
      '#99ccff',
      '#004d99',
      '#80bfff',
      '#994d00',
      '#ffb366',
      '#e67300',
      '#ffbf80',
      ' #47d147',
      '#85e085',
      '#248f24',
      '#70db70',
      '#00cccc',
      '#66ffff',
      '#00b3b3',
      '#4dffff',
      ' #ffbb33',
      '#ffdd99',
      '#cc8800',
      '#ffcc66',
      '#ff884d',
      '#ffccb3',
      '#ff884d',
      '#ffbb99',
      '#bf4080',
      '#e6b3cc',
      '#ac3973',
      '#df9fbf',
      '#7575a3',
      '#c2c2d6',
      '#5c5c8a',
      '#b3b3cc',
      '39ac73',
      '#b3e6cc',
      '#39ac73',
      '#8cd9b3',
      '#00ace6',
      '#b3ecff',
      '#0086b3',
      '#80dfff',
      '#999966',
      '#ccccb3',
      '#8a8a5c',
      '#b8b894',
      '#33cc33',
      '#85e085',
      '#248f24',
      '#70db70',
      '#ff884d',
      '#ffccb3',
      '#ff661a',
      '#ffbb99',
      '#2d862d',
      '#79d279',
      '#206020',
      '#79d279',
      '#5353c6',
      '#c6c6ec',
      '#3939ac',
      '#8c8cd9',
    ];

    const graphArry = [] as AmCharts.AmGraph[];
    let showlegend: boolean = true;

    //Default graph's are active on load
    if (shownone === 1) {
      this.selectedLegends.length = 0;
    } else {
      if (this.IsOnetimebinding) {
        for (let i = 0; i < 4; i++) {
          const graph = 'AmGraph-' + (i + 1);
          this.selectedLegends.push(graph);
        }
        this.IsOnetimebinding = false;
      }
    }

    for (let i = 0; i < nutrient.length; i++) {
      const graph = {} as AmCharts.AmGraph;
      graph.id = 'AmGraph-' + (i + 1);
      graph.balloonText = nutrientTitles[i] + ': [[value]]';
      graph.title = nutrientTitles[i];
      graph.valueField = nutrient[i];

      if (this.selectedLegends?.length) {
        const index = this.selectedLegends.indexOf(graph.id);
        if (index >= 0) {
          showlegend = false;
        } else {
          showlegend = true;
        }
      }

      if (nutrient[i].includes('Cum')) {
        graph.fillAlphas = 0;
        graph.bullet = 'round';
        graph.bulletBorderAlpha = 1;
        graph.bulletColor = '#FFFFFF';
        graph.useLineColorForBulletBorder = true;
        graph.fillAlphas = 0;
        graph.lineThickness = 2;
        graph.lineAlpha = 1;
        graph.bulletSize = 7;
        //graph.type = 'smoothedLine';
        graph.valueAxis = 'ValueAxis-2' as any;
        graph.lineColor = nutrientgraphcolor[i];
      } else {
        graph.valueAxis = 'ValueAxis-1' as any;
        graph.fillAlphas = 0.8;
        graph.type = 'column';
        graph.lineAlpha = 0.2;
        graph.fillColors = nutrientgraphcolor[i];
      }
      graph.hidden = showlegend;
      graphArry.push(graph);
    }

    AmCharts.makeChart('chartnewDiv', {
      type: 'serial',
      theme: 'light',
      categoryField: 'Phase',
      rotate: false,
      startDuration: 0,
      categoryAxis: {
        parseDates: false,
        dashLength: 1,
        autoGridCount: true,
        minHorizontalGap: 50,
        labelRotation: 75,
      },
      trendLines: [],
      graphs: graphArry,
      guides: [],
      valueAxes: [
        {
          id: 'ValueAxis-1',
          position: 'left',
          axisAlpha: 0,
          title: this._languageService.instant('NUTR.PROG.PHASE_TARGETED') + ` (${this.weightAreaUnit.name})`,
        },
        {
          id: 'ValueAxis-2',
          dashLength: 1,
          position: 'right',
          title: this._languageService.instant('NUTR.PROG.PHASE_CUMULATIVE') + ` (${this.weightAreaUnit.name})`,
        },
      ],
      allLabels: [],
      balloon: {},
      titles: [],
      dataProvider: this.NutDistPhasees,
      legend: {
        useGraphSettings: true,
        horizontalGap: 5,
        maxColumns: 4,
        position: 'bottom',
        clickMarker: this.legendclick.bind(this),
        clickLabel: this.legendclick.bind(this),
      },
    });
  }
}

interface NutDistPhase {
  Phase: string;
  NO3NWeeklyTarget: number;
  NO3NWeeklyPlanned: number;
  NO3NCumTgt: number;
  NO3NCumPlanned: number;
  OtherNWeeklyTarget: number;
  OtherNWeeklyPlanned: number;
  OtherNCumTgt: number;
  OtherNCumPlanned: number;
  TotalNWeeklyTarget: number;
  TotalNWeeklyPlanned: number;
  TotalNCumTgt: number;
  TotalNCumPlanned: number;
  PWeeklyTarget: number;
  PWeeklyPlanned: number;
  PCumTgt: number;
  PCumPlanned: number;
  KWeeklyTarget: number;
  KWeeklyPlanned: number;
  KCumTgt: number;
  KCumPlanned: number;
  CaWeeklyTarget: number;
  CaWeeklyPlanned: number;
  CaCumTgt: number;
  CaCumPlanned: number;
  MgWeeklyTarget: number;
  MgWeeklyPlanned: number;
  MgCumTgt: number;
  MgCumPlanned: number;
  SWeeklyTarget: number;
  SWeeklyPlanned: number;
  SCumTgt: number;
  SCumPlanned: number;
  NaWeeklyTarget: number;
  NaWeeklyPlanned: number;
  NaCumTgt: number;
  NaCumPlanned: number;
  ClWeeklyTarget: number;
  ClWeeklyPlanned: number;
  ClCumTgt: number;
  ClCumPlanned: number;
  CuWeeklyTarget: number;
  CuWeeklyPlanned: number;
  CuCumTgt: number;
  CuCumPlanned: number;
  FeWeeklyTarget: number;
  FeWeeklyPlanned: number;
  FeCumTgt: number;
  FeCumPlanned: number;
  MnWeeklyTarget: number;
  MnWeeklyPlanned: number;
  MnCumTgt: number;
  MnCumPlanned: number;
  ZnWeeklyTarget: number;
  ZnWeeklyPlanned: number;
  ZnCumTgt: number;
  ZnCumPlanned: number;
  BWeeklyTarget: number;
  BWeeklyPlanned: number;
  BCumTgt: number;
  BCumPlanned: number;
  MoWeeklyTarget: number;
  MoWeeklyPlanned: number;
  MoCumTgt: number;
  MoCumPlanned: number;
}

angular.module('app.nutrients').component('swanProgramFertiliserDistribution', new SWANProgramFertiliserDistributionComponent());
