// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nutrients-program .md-subheader {
  z-index: 20 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/programs/program-details/program-details.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ","sourcesContent":["#nutrients-program {\r\n  .md-subheader {\r\n    z-index: 20 !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
