import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ApplicationInstance } from '@common/nutrients.interface';
import { SWANConstants } from '@common/SWANConstants';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { ProfileService } from '@services/nutrients/profile.service';
import { ProgramService } from '@services/nutrients/program.service';
import { Budget } from 'src/app/_DBContext/Budget';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { AlterElementWeightingDialogController } from 'src/app/pages/nutrients/profiles/alterElementWeighting-dialog.controller';
import { AddNutrientPhaseDialogController } from './dialogs/addNutrientPhase-dialog.component';
import { BaseController } from 'src/app/base.controller';

class SWANProgramDistributionProfileComponent implements angular.IComponentOptions {
  bindings = {
    programId: '<',
    step: '<',
    stepNumber: '<',
  };
  controller = ProgramDistributionProfileController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/programs/program-details/tabs/distribution-profile.component.html';
}

class ProgramDistributionProfileController extends BaseController {
  public minDate = SWANConstants.MinDate;
  public programId: number;
  public budget: Budget;
  public step;
  public stepNumber;
  public canProceedToStep4 = 0;

  public colHeaders = [
    'Name',
    'Application Instance Start Date',
    'Week',
    'Spread',
    'Total N',
    'NO3_N',
    'NH4_N',
    'P',
    'K',
    'Ca',
    'Mg',
  ];
  public allowedElementWeightings = ['NO3_N', 'NH4_N', 'P', 'K', 'Ca', 'Mg'];
  public profileChart: AmCharts.AmSerialChart;

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _profileService: ProfileService;
  private _programService: ProgramService;

  constructor(
    $scope: angular.IScope,
    $mdDialog: angular.material.IDialogService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    ProfileService: ProfileService,
    ProgramService: ProgramService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._profileService = ProfileService;
    this._programService = ProgramService;

    this.entityManager = DataEntityService.manager;
  }

  $onChanges(changes) {
    if (
      changes.stepNumber?.currentValue === 3 &&
      angular.isDefined(this._programService.selectedNutrientProfile)
    ) {
      if (this._programService.validDistributionProfile()) {
        if (!this._programService.hasDistributionProfilePageLoaded) {
          this.loadPage();
          this.canProceedToStep4 = 1;
          this._programService.hasDistributionProfilePageLoaded = true;
        } else {
          if (!this._programService.validDistributionProfile()) {
            this._programService.applicationInstances = [];
            this._languageService.warning('NUTR.PROG.THE_NUMBER_OF_WEEKS');
            this.canProceedToStep4 = 0;
          }
        }
      } else {
        this._programService.applicationInstances = [];
        this._languageService.warning('NUTR.PROG.THE_NUMBER_OF_WEEKS');
        this.canProceedToStep4 = 0;
      }
    }
  }

  $onDestroy() {
    this._programService.hasDistributionProfilePageLoaded = false;
  }

  public alterElementWeighting(ev, compound: string) {
    // all the fert element must be editable
    this._mdDialog.show({
      escapeToClose: false,
      controller: AlterElementWeightingDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/profiles/alterElementWeighting-dialog.html',
      locals: {
        applicationInstances: this._programService.applicationInstances,
        selectedCompound: compound,
        isReadOnly: false,
      },
    } as angular.material.IDialogOptions);
  }

  private reloadApplicationInstances() {
    const applicationInstances = [] as ApplicationInstance [];

    if (this._programService.validDistributionProfile()) {
      let displayWeek: number;

      for (let index = 0; index < this._programService.nutrientSettings.length; index++) {
        let startDate: moment.Moment;
        const percentage = this._programService.nutrientSettings[index].Percentage;
        const isFirstPhase = index == 0;
        const weeks = this.showWeek(percentage);

        if (!isFirstPhase) {
          startDate = moment(this._programService.startDate).add(weeks - 1, 'weeks');
          displayWeek = startDate.diff(moment(this._programService.startDate), 'weeks', true);
          displayWeek++;
        } else {
          displayWeek = 1;
          startDate = moment(this._programService.startDate);
        }

        applicationInstances.push({
          instanceNum: index,
          nutrientSetting: this._programService.nutrientSettings[index],
          startDate: startDate.toDate(),
          week: displayWeek,
          spread: this._programService.applicationInstances[index]?.spread,
        } as ApplicationInstance);

      }

      this._programService.applicationInstances = ArrayUtils.sortByNumber(applicationInstances, (x) => x.week);

      this.displayWeekDuplicateWarnings();
    } else {
      this._programService.applicationInstances = [];
      this._languageService.warning('NUTR.PROG.THE_NUMBER_OF_WEEKS');
      this.canProceedToStep4 = 0;
    }
  }

  private displayWeekDuplicateWarnings() {
    const appIntCounter = {};
    const db = this._programService.applicationInstances;
    for (let i = 0; i < db.length; i += 1) {
      appIntCounter[db[i].week.toString()] = (appIntCounter[db[i].week.toString()] || 0) + 1;
    }

    for (const key in appIntCounter) {
      if (appIntCounter[key] > 1) {
        this._languageService.warning('NUTR.PROG.WEEK_DUPLICATES', 'COMMON.WARNING', { n: key });
      }
    }
  }

  private loadPage() {
    this.fetchData();
  }

  private fetchData() {
    this.fetchNutrientSettings();
  }

  private cloneNutrientSettings(nutrientSettings: NutrientSetting[]) {
    const nootSettingEntityType = this.entityManager.metadataStore.getEntityType('NutrientSetting') as breeze.EntityType;

    // reject all clone nutrient settings entities
    this._programService.nutrientSettings.map((ns) => {
      try {
        ns.entityAspect.rejectChanges();
      } catch (err) {
        // do nothing
      }
    });

    this._programService.nutrientSettings = [];
    nutrientSettings.forEach((ns) => {
      const nootSetting = nootSettingEntityType.createEntity() as NutrientSetting;
      nootSetting.NutrientProfile = this._programService.budgetNutrientProfile;
      nootSetting.Name = ns.Name;
      nootSetting.Percentage = ns.Percentage;
      nootSetting.Noots = ns.Noots;

      this.entityManager.addEntity(nootSetting);

      this._programService.nutrientSettings.push(nootSetting);
    });
  }

  private fetchNutrientSettings() {
    // Note: Here we fetch using the original nutrient profile assetId
    // const pred = breeze.Predicate.create(
    //   'AssetId',
    //   breeze.FilterQueryOp.Equals,
    //   this._programService.selectedNutrientProfile.AssetId,
    // );

    const successCallback = (data: breeze.QueryResult) => {
      // duplicate nutrient setting for budget nutrient profile
      this.cloneNutrientSettings(data.results as NutrientSetting[]);
      this.reloadApplicationInstances();
      this.profileChart = this._profileService.getChart(
        'distribution-profile-chart',
        this._programService.applicationInstances,
        'week',
        false,
      );
    };

    const failCallback = () => {};
    const assetId = this._programService.selectedNutrientProfile.AssetId;
    // Note: Here we fetch using the original nutrient profile assetId
    breeze.EntityQuery.from('assets/' + assetId + '/NutrientSettings')
      .using(this.entityManager)
      .execute()
      .then(successCallback, failCallback);
  }

  private reloadChart() {
    this._programService.nutrientSettings = (this.entityManager.getEntities('NutrientSetting') as NutrientSetting[]).filter((ns) => {
      return ns.AssetId === this._programService.budgetNutrientProfile.AssetId;
    });

    this.reloadApplicationInstances(); //if active: resets the start date.
    this.profileChart = this._profileService.getChart(
      'distribution-profile-chart',
      this._programService.applicationInstances,
      'week',
      false,
    );
  }

  private reloadNoChangeChart() {
    this._programService.nutrientSettings = (this.entityManager.getEntities('NutrientSetting') as NutrientSetting[]).filter((ns) => {
      return ns.AssetId === this._programService.budgetNutrientProfile.AssetId;
    });

    this.profileChart = this._profileService.getChart(
      'distribution-profile-chart',
      this._programService.applicationInstances,
      'week',
      false,
    );
  }

  public openAddNutrientPhaseDialog() {
    if (this._programService.nutrientSettings[this._programService.nutrientSettings.length - 1].Percentage === 100) {
      this._languageService.warning('NUTR.PROG.UNABLE_TO_INSERT');
      return;
    }

    this._mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      controller: AddNutrientPhaseDialogController,
      controllerAs: 'ctrl',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/programs/program-details/tabs/dialogs/addNutrientPhase-dialog.html',
      onRemoving: this.reloadChart.bind(this),
      locals: {
        nutrientSettings: this._programService.nutrientSettings,
      },
    } as angular.material.IDialogOptions);
  }

  public deleteProfileInstanceConfirm(appInstance: ApplicationInstance) {
    const confirm = (this._languageService.confirm() as any)
      .title('NUTR.COMMON.ARE_YOU_SURE_DELETE_PROFILE')
      .htmlContent('NUTR.PROF.WILL_BE_DELETED', { name: appInstance.nutrientSetting.Name })
      .ok('COMMON.OK')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      const nutrientSettingEntity = this.entityManager.getEntities('NutrientSetting').filter((ns: NutrientSetting) => {
        return ns.Id === appInstance.nutrientSetting.Id;
      })[0] as NutrientSetting;

      const entityDeletions: breeze.Entity[] = [];

      // nutrient setting will always be a new clone
      if (nutrientSettingEntity.Id < 0) {
        this.entityManager.detachEntity(nutrientSettingEntity);
      } else {
        nutrientSettingEntity.entityAspect.setDeleted();
        entityDeletions.push(nutrientSettingEntity);
      }

      const successCallback = (savedChanges) => {
        this._languageService.success('NUTR.COMMON.DELETION_SAVED');
        console.log(savedChanges);

        this._programService.applicationInstances = this._programService.applicationInstances.filter((ai) => {
          return ai.instanceNum !== appInstance.instanceNum;
        });

        this.displayWeekDuplicateWarnings();
      };

      const failCallback = (saveFailed) => {
        this._languageService.warning('NUTR.COMMON.DELETION_UNSUCCESSFULL', 'COMMON.WARNING', { m: saveFailed.message });

        if (saveFailed.entityErrors) {
          saveFailed.entityErrors.map((error) => {
            this._languageService.info('{{e}}', 'COMMON.INFORMATION', { e: error.errorMessage });
          });
        }
      };

      if (entityDeletions.length) {
        this.entityManager.saveChanges(entityDeletions, null, successCallback, failCallback);
      } else {
        this._programService.applicationInstances = this._programService.applicationInstances.filter((ai) => {
          return ai.instanceNum !== appInstance.instanceNum;
        });

        this.displayWeekDuplicateWarnings();
      }
    });
  }

  public updateWeek(applicationInstance: ApplicationInstance, oldDate: Date) {
    const newDate = applicationInstance.startDate.toString('yyyy-MM-dd') + 'T12:00:00.000Z';
    if (applicationInstance.startDate < this._programService.startDate) {
      applicationInstance.startDate = moment.utc(oldDate).toDate();
      this._languageService.warning('NUTR.PROG.SELECTED_DATE_BEFORE');
      return;
    } else if (applicationInstance.startDate > this._programService.endDate) {
      applicationInstance.startDate = moment.utc(oldDate).toDate();
      this._languageService.warning('NUTR.PROG.SELECTED_DATE_AFTER');
      return;
    } else {
      const ms = moment.utc(newDate).diff(moment.utc(oldDate));

      if (isNaN(applicationInstance.week)) {
        applicationInstance.week = 0;
      }

      applicationInstance.week += moment.duration(ms).asDays() / 7;
      applicationInstance.nutrientSetting.Percentage = (applicationInstance.week / this._programService.numOfWeeks) * 100;

      this._programService.applicationInstances = ArrayUtils.sortByNumber(this._programService.applicationInstances, x => x.week);

      if (applicationInstance.instanceNum == 0) {
        // changing the 1st phase ()
        applicationInstance.startDate = moment.utc(newDate).toDate();
        this._programService.applicationInstances[0].startDate = moment.utc(newDate).toDate();
        this._programService.applicationInstances[0].week = applicationInstance.week;
      }

      this.reloadNoChangeChart();
    }
  }

  public isSunday(date: Date) {
    return date.getDay() === 0;
  }

  private showWeek(percentage: number): number {
    const week = Math.round(this._programService.numOfWeeks) * (percentage / 100);
    const result = Math.round(Number(week.toFixed(1)));
    return result;
  }
}

angular.module('app.nutrients').component('swanProgramDistributionProfile', new SWANProgramDistributionProfileComponent());
