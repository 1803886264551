import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { DataEntityService } from '@services/data-entity.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { TableControl } from '@common/helpers/TableControl';
import { AddFertiliserDialogController } from './addFertiliser-dialog.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

export class FertiliserController extends BaseController {
  public fertiliserId: number;
  public iActive = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;
  public showShared = false;
  public nameFilter = '';
  public typeFilter = '';
  public ownerFilter = '';
  public manufacturerFilter = '';
  public filteredFertilisers: Fertiliser[] = [];
  public tableControl = new TableControl();

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _nutrientsService: NutrientsService;

  private _fertilisers: Fertiliser[] = [];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsFertilisersFull);

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;
    this.fertiliserId = Number($state.params.id);

    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      this.typeFilter = NutrientsService.getTypeFilter();
      this.ownerFilter = NutrientsService.getOwnerFilter();
      this.manufacturerFilter = NutrientsService.getManufacturerFilter();
      this.showActive = NutrientsService.getActiveState();
      this.showArchived = NutrientsService.getArchivedState();
      this.showShared = NutrientsService.getSharedState();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
      NutrientsService.setTypeFilter(this.typeFilter);
      NutrientsService.setOwnerFilter(this.ownerFilter);
      NutrientsService.setManufacturerFilter(this.manufacturerFilter);
      NutrientsService.setActiveState(this.showActive);
      NutrientsService.setArchivedState(this.showArchived);
      NutrientsService.setSharedState(this.showShared);
    }
  }

  $onInit() {
    this._fetchData();
    this.setWatches();
  }

  private _fetchData() {
    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null);

    breeze.EntityQuery.from('AccountSharedAssets')
      .expand('Fertiliser')
      //.select("Fertiliser")
      .withParameters({ accountId: this.accountId })
      .orderBy('Name')
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.iActive = 0;
        this.iArchived = 0;
        const assets = data.results as Asset[];
        this._fertilisers = [];
        assets.forEach((asset) => {
          this._fertilisers.push(asset.Fertiliser);
          if (this.showShared) {
            if (asset.Status == 'Archived') this.iArchived++;
            else this.iActive++;
          } else {
            if (asset.OwnerAccountId == this.accountId && asset.Status == 'Archived') this.iArchived++;
            else if (asset.OwnerAccountId == this.accountId && asset.Status == 'Active') this.iActive++;
          }
        });
        this.filterFertilisers();
      });
  }

  public openAddFertiliserDialog() {
    const sharedFertilisers = this._fertilisers.filter((f) => f.Asset.Shared === true);
    this._mdDialog.show({
      controller: AddFertiliserDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/fertiliser/addFertiliser-dialog.html',
      locals: {
        sharedAssets: sharedFertilisers,
      },
    } as angular.material.IDialogOptions);
  }

  public gotoTankMixReceipeReport() {
    this._state.go('app.nutrients.tankmix');
  }

  public gotoFertiliserDetail(fertiliser: Fertiliser) {
    if (fertiliser.Type === 'Tank Mix') {
      this._state.go('app.nutrients.tankmix.detail', { id: fertiliser.AssetId });
    } else {
      this._state.go('app.nutrients.fertiliser.detail', { id: fertiliser.AssetId });
    }
  }

  public getFertiliserOwner(ownerAccountId: number) {
    if (ownerAccountId == 1) {
      return 'SWAN Systems';
    } else {
      return '';
    }
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter', 'vm.typeFilter', 'vm.ownerFilter', 'vm.manufacturerFilter'], () => {
      if (this._fertilisers.length) {
        this.filterFertilisers();
      }
    });
  }

  public toggleSharedFertilisers() {
    this.iActive = 0;
    this.iArchived = 0;
    this._fertilisers.forEach((fertiliser) => {
      if (this.showShared) {
        if (fertiliser.Asset.Status == 'Archived') this.iArchived++;
        else this.iActive++;
      } else {
        if (fertiliser.Asset.OwnerAccountId == this.accountId && fertiliser.Asset.Status == 'Archived') this.iArchived++;
        else if (fertiliser.Asset.OwnerAccountId == this.accountId && fertiliser.Asset.Status == 'Active') this.iActive++;
      }
    });
    this.filterFertilisers();
  }

  private filterFertilisers() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    this._nutrientsService.setTypeFilter(this.typeFilter);
    this._nutrientsService.setManufacturerFilter(this.manufacturerFilter);
    this._nutrientsService.setSharedState(this.showShared);
    this._nutrientsService.setActiveState(this.showActive);
    this._nutrientsService.setArchivedState(this.showArchived);
    if (this._fertilisers) {
      this.filteredFertilisers = this._fertilisers.filter((a) => a.Asset.Name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);
      this.filteredFertilisers = this.filteredFertilisers.filter(
        (a) => a.Type.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1,
      );
      if (this.manufacturerFilter != '') {
        this.filteredFertilisers = this.filteredFertilisers.filter(
          (a) => a.Manufacturer != null && a.Manufacturer.toLowerCase().indexOf(this.manufacturerFilter.toLowerCase()) > -1,
        );
      }
      if (!this.showActive) {
        this.filteredFertilisers = this.filteredFertilisers.filter((a) => a.Asset.Status !== 'Active');
      }
      if (!this.showArchived) {
        this.filteredFertilisers = this.filteredFertilisers.filter((a) => a.Asset.Status !== 'Archived');
      }
      if (!this.showShared) {
        this.filteredFertilisers = this.filteredFertilisers.filter((a) => a.Asset.OwnerAccountId == this.accountId);
      }
    }
  }

  public toggleButton(button: string): void {
    if (button == 'Active') this.showActive = !this.showActive;
    if (button == 'Archived') this.showArchived = !this.showArchived;
    this.filterFertilisers();
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
    this.ownerFilter = '';
    this.manufacturerFilter = '';
  }
}

angular.module('app.nutrients').controller('FertiliserController', FertiliserController);
