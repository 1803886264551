import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { SWANConstants } from '@common/SWANConstants';
import { SWANConstantsEnum } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IIdNameItem } from '@common/models/interfaces';
import { DataEntityService } from '@services/data-entity.service';
import { InitialisationService } from '@services/initialisation.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOptions, UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

class ProfileUomComponent implements angular.IComponentOptions {
  bindings = {
  };
  controller = ProfileUomController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/profile/tabs/profile-uom.html';
}

interface uomBaseClass {
  scalePrefs: {};
  scaleOptions: {};
  content: string;
  description: string;
  hidden: boolean;
}

// We store/load unit info here in too many different formats. This is just the basic common info we need.
interface unitBasic {
  unitId: number;
  symbol: string;
  description: string;
  context: string;
}

class ProfileUomController extends BaseController {
  public unitBaseClasses = [] as IIdNameItem[];
  public premixedUoms = [] as any[];
  public premixedUom = null as any;
  public isAnyChange = false;
  public isLoading = false;
  public uomsByBaseclass = [] as uomBaseClass[];
  public foreignUnits = false;

  private _http: angular.IHttpService;
  private _dataEntityService: DataEntityService;
  private _initialisationService: InitialisationService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private uomPreferences = [] as fuse.uomPreferenceDto[];
  private unitScales = [] as fuse.unitScaleDto[];
  private unitOptions: UnitOptions;
  private sizeNames: string[] = [];
  private unitSizes: Record<string, number> = {};
  private baseClassDescriptions = {};
  private baseClassNames = [];

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    InitialisationService: InitialisationService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._dataEntityService = DataEntityService;
    this._initialisationService = InitialisationService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.uomPreferences = SWANConstants.uomPreferences;

    NotifyingService.subscribe(NotifyEvents.App.SaveChanges.Profile, $scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });
  }

  $onInit() {
    this.clearNavColor();

    this._http.get(CommonHelper.getApiUrl('profile/getUnitScales')).then((res) => {
      this.unitScales = res.data as fuse.unitScaleDto[];
      this.unitOptions = new UnitOptions(res.data as fuse.unitScaleDto[]);

      const baseClasses = {};

      this.unitSizes = {};

      this.unitScales.forEach((a) => {
        baseClasses[a.context] = a.baseClassId;
        this.unitSizes[a.scale.name] = a.scale.id;
        this.baseClassDescriptions[a.context] = a.baseClassDescription;
      });

      const unitSizeList = Object.keys(this.unitSizes).map((key) => ({
        name: key,
        value: this.unitSizes[key]
      }));

      this.sizeNames = ArrayUtils.sortByNumber(unitSizeList, x => x.value).map(x => x.name);
      this.baseClassNames = ArrayUtils.sortStrings(Object.keys(baseClasses));

      this.sortUserUoms();
    });

    this.loadUomPreferences();
  }

  public async loadUomPreferences() {
    this.premixedUoms = [];

    const systemUoms = ((await this._http.get(CommonHelper.getApiUrl('profile/getPremixedUoms'))).data as string[])?.map(
      (premixedUom) => ({
        label: this._languageService.instant('TOOLBAR.UOM.' + premixedUom),
        value: `premix:${premixedUom}`,
      })
    );

    const users = ((await this._http.get(CommonHelper.getApiUrl('profile/getUsers'))).data as fuse.userBasicDto[])?.map(
      (user) => ({
        label: user.name,
        value: `user:${user.id}`,
      })
    );

    this.premixedUoms = [...this.premixedUoms, ...systemUoms, ...users];
  }

  private sortUserUoms(premix = false) {
    this.uomsByBaseclass = [];
    this.baseClassNames.forEach((baseClass) => {
      const userUom = {} as uomBaseClass;
      userUom.content = baseClass;
      userUom.description = this.baseClassDescriptions[baseClass];
      userUom.hidden = true;
      userUom.scaleOptions = {}; //Array(maxScale);
      userUom.scalePrefs = {}; //Array(maxScale);

      this.sizeNames.forEach((scale, i) => {
        let uomPreference: unitBasic;

        scale = scale[0].toUpperCase() + scale.substring(1);
        uomPreference = premix
          ? this.getUomPreference(baseClass, this.unitSizes[scale])
          : this._unitOfMeasureService.getUnits(baseClass, this.unitSizes[scale], false);

        const unitOpts: unitBasic[] = this.unitOptions.getAllowedUnitScales(userUom.content, scale);

        // If user preference not in list (ie. custom unit for another account), add to list so will be visible
        if (uomPreference?.unitId > 0 && !unitOpts.find((u) => u.unitId == uomPreference.unitId)) {
          unitOpts.push(uomPreference);
        }

        // If symbols ambiguous, replace with descriptions
        unitOpts.forEach((us) => {
          let displayName = us.symbol;

          if (!us.symbol || unitOpts.filter((u) => u.symbol == us.symbol).length > 1) {
            displayName = us.description ?? `${us.symbol} (${us.unitId})`; // only if ambiguous symbol AND no description, currently shouldn't happen
          }

          us['displayName'] = displayName;
        });

        // True if unit preference is custom unit defined by another account
        if (uomPreference?.['authAccountId'] && uomPreference['authAccountId'] != this.account.accountId) {
          this.foreignUnits = true;
          uomPreference['displayName'] = '** ' + uomPreference['displayName'];
        }

        userUom.scalePrefs[scale] = uomPreference?.unitId;
        userUom.scaleOptions[scale] = unitOpts;

        if (unitOpts.length > 1) {
          userUom.hidden = false;
        } else if (unitOpts.length == 1 && !uomPreference) {
          userUom.scalePrefs[scale] = unitOpts[0].unitId;
        }
      });

      this.uomsByBaseclass.push(userUom);
    });
  }

  private clearNavColor() {
    const allNavElements = angular.element('.ms-navigation-button').toArray();

    allNavElements.forEach((navElement) => {
      navElement.style.backgroundColor = SWANConstantsEnum.navBackgroundColor;
    });
  }

  public premixedUomChanged() {
    this.isAnyChange = true;
    this.isLoading = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (this.premixedUom != null) {
      const [, value] = this.premixedUom.split(':');
      let url = null;

      if (this.premixedUom.startsWith('premix:')) {
        url = CommonHelper.getApiUrl('profile/getPremixedUomPreferences/' + value);
      } else if (this.premixedUom.startsWith('user:')) {
        url = CommonHelper.getApiUrl('users/getUserUomPreferences/' + value);
      } else {
        return;
      }

      this._http.get(url).then(
        (res) => {
          this.uomPreferences = res.data as fuse.uomPreferenceDto[];
          this.sortUserUoms(true);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    }
  }

  public getUomPreference(baseClass: string, scaleId: number) {
    return this.uomPreferences.find((a) => a.context == baseClass && a.scale.id == scaleId);
  }

  public userUomChanged() {
    this.isAnyChange = true;
    this.premixedUom = null;
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public cancel() {
    this.sortUserUoms();
    this.isAnyChange = false;
    this.premixedUom = null;
    this._dataEntityService.hasDirtyCustomForm = false;
  }

  public async saveChanges(postSaveActions: PostSaveActions = null) {
    const unitPreference = this.updateUomPreferences();
    const updateResponse = await this._http.post(CommonHelper.getApiUrl('profile/updateUomPreferences'), unitPreference);

    if (updateResponse) {
      this.isAnyChange = false;
      this.premixedUom = null;
      this._dataEntityService.hasDirtyCustomForm = false;
      this._languageService.showSaveSuccess();

      this._unitOfMeasureService.setUnits(
        (await this._http.get(CommonHelper.getApiUrl('users/user_units'))).data as fuse.uomPreferenceDto[]
      );

      await this._initialisationService.reInit();

      if (super.executeAnyPostSaveActions(postSaveActions)) {
        return;
      };
    } else {
      this._languageService.whoops();
    }
  }

  private updateUomPreferences(): fuse.unitScaleDto[] {
    const unitPreference = [] as fuse.unitScaleDto[];

    this.uomsByBaseclass.forEach((uomOpts) => {
      this.sizeNames.forEach((scale) => {
        if (uomOpts.scalePrefs[scale]) {
          const unitScale = uomOpts.scaleOptions[scale].find((opt) => opt.unitId == uomOpts.scalePrefs[scale]);
          if (unitScale) {
            unitPreference.push(unitScale);
          } else {
            console.log(`Error: UnitScale not found for unit ID ${uomOpts.scalePrefs[scale]}`);
          }
        }
      });
    });

    return unitPreference;
  }
}

angular.module('app.profile').component('profileUom', new ProfileUomComponent());
