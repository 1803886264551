import { IIdNameItem, IBooleanDropListItem, IStringDropListItem } from '@common/models/interfaces';

export interface IWaterBudgetSiteSetting {
  checked: boolean;
  key: string;
  name: string;
  valueType: string;
  defaultStringValue: string;
  defaultNumberValue: number;
  minValue: number;
  maxValue: number;
}
export interface IIrrigationReportSetting {
  irrigationMillimeter: boolean;
  irrigationRuntime: boolean;
  irrigationProportion: boolean;
  irrigationVolume: boolean;
  soilMoisture: boolean;
  printAllSites: boolean;
  displayUnitId: number;
  applicationType: number;
}
export interface IIrrigationReportRowSetting {
  id: number;
  baseName: string;
  name: string;
}
export interface ISetIrrigationSetting {
  isIrrigationChanged: boolean;
  irrigationApplication: number;
  irrigationRuntime: number;
  irrigationPercent: number;
  irrigationLockStatus?: boolean;
  isFertigateEntire: boolean;
  isFertigateFixed: boolean;
  fertigationApplication: number;
  fertigationRuntime: number;
  steppedIrrigation: number;
}
export class WaterConstants {
  public static IrrigationOptimiseSubscription = 'Irrigation Optimise';
  public static irrigationNormalPolicy = 'Normal';
  public static irrigationMustPolicy = 'Must';
  public static irrigationMustNotPolicy = 'Must Not';

  public static irrigationUnits = [
    { id: 0, name: 'DEPTH_UNIT/day', description: 'Application' },
    { id: 1, name: 'hh:mm', description: 'Run Time' },
    { id: 2, name: '%', description: 'Percentage' },
  ] as IIdNameItem[];

  public static fertigationUnits = [
    { id: 0, name: 'DEPTH_UNIT/day', description: 'Application' },
    { id: 1, name: 'hh:mm', description: 'Run Time' },
  ] as IIdNameItem[];

  public static observeSchedules = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ] as IIdNameItem[];

  public static patterns = [
    { id: 0, name: 'Lower' },
    { id: 1, name: 'Middle' },
    { id: 2, name: 'Upper' },
    { id: 3, name: 'ZigZag' },
  ] as IIdNameItem[];

  public static smbPreferences = [
    { id: 0, name: 'Wet' },
    { id: 1, name: 'Dry' },
  ] as IIdNameItem[];

  public static irrigationPolicies = [
    { id: 0, name: this.irrigationNormalPolicy },
    { id: 1, name: this.irrigationMustPolicy },
    { id: 2, name: this.irrigationMustNotPolicy },
  ] as IIdNameItem[];

  public static systemCoefficiencyKey = 'Irrigation System Efficiency';
  public static usefulRainfallKey = '% of rainfall contributing to budget';
  public static budgetedIrrigationKey = 'Budgeted Irrigation (ML/ha)';
  public static activeState = 'Active';
  public static archivedState = 'Archived';
  public static irrigationReport_irrigation = 'Irrigation';
  public static irrigationReport_runtime = 'Run time, HH:MM';
  public static irrigationReport_proportion = 'Proportion of "100%"';
  public static irrigationReport_volume = 'Volume';
  public static irrigationReport_soilMoisture = 'Soil Moisture Balance';
  public static irrigationReport_oneLineMargin = [24, 70, 24, 70];
  public static irrigationReport_twoLineMargin = [24, 60, 24, 60];
  public static irrigationReport_threeLineMargin = [24, 80, 24, 80];
  public static irrigationReport_fourLineMargin = [24, 80, 24, 80];
  public static irrigationReport_fiveLineMargin = [24, 70, 24, 70];

  public static waterBudgetStatuses = [
    { id: this.activeState, name: this.activeState },
    { id: this.archivedState, name: this.archivedState },
  ] as IStringDropListItem[];

  public static metricDisplayValues = [50, 100, 150, 500, 750, 1000];
  public static milesDisplayValues = [30, 60, 100, 300, 500];

  public static weatherStationYears = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
  ] as IIdNameItem[];

  public static waterBudgetMonthAdjustActions = [
    { id: 0, name: 'VolumeAreaUnit' },
    { id: 1, name: 'Volume proportional to area', description: 'WATER_BUDGETS.VOLUME_PROPORTIONAL_TO_AREA' },
    { id: 2, name: 'Reset to required value', description: 'WATER_BUDGETS.RESET_TO_REQUIRED_VALUE' },
    { id: 3, name: '% of required value', description: 'WATER_BUDGETS.PERCENT_OF_REQUIRED' },
    { id: 4, name: '% of budgeted value', description: 'WATER_BUDGETS.PERCENT_OF_BUDGET' },
  ] as IIdNameItem[];

  public static waterBudgetSiteAdjustActions = [
    { id: 0, name: 'VolumeAreaUnit' },
    { id: 1, name: 'Volume evenly across all months', description: 'WATER_BUDGETS.VOLUME_EVENLY' },
    {
      id: 5,
      name: 'Volume across months proportional to required volume',
      description: 'WATER_BUDGETS.VOLUME_PROPORTIONAL_TO_MONTH_REQUIRED',
    },
    { id: 2, name: 'Reset to required value', description: 'WATER_BUDGETS.RESET_TO_REQUIRED_VALUE' },
    { id: 3, name: '% of required value', description: 'WATER_BUDGETS.PERCENT_OF_REQUIRED' },
    { id: 4, name: '% of budgeted value', description: 'WATER_BUDGETS.PERCENT_OF_BUDGET' },
  ] as IIdNameItem[];

  public static waterBudgetAllAdjustActions = [
    { id: 0, name: 'VolumeAreaUnit' },
    { id: 1, name: 'Volume proportional to required volume', description: 'WATER_BUDGETS.VOLUME_PROPORTIONAL_TO_REQUIRED' },
    { id: 2, name: 'Reset to required value', description: 'WATER_BUDGETS.RESET_TO_REQUIRED_VALUE' },
    { id: 3, name: '% of required value', description: 'WATER_BUDGETS.PERCENT_OF_REQUIRED' },
    { id: 4, name: '% of budgeted value', description: 'WATER_BUDGETS.PERCENT_OF_BUDGET' },
  ] as IIdNameItem[];

  public static requiredColumnVisibilities = [
    { id: true, name: 'Hide' },
    { id: false, name: 'Show' },
  ] as IBooleanDropListItem[];

  public static weatherSource_station = 'Weather Station';
  public static weatherSource_manual = 'Manual Entry';
  public static weatherSource_ignore = 'Ignore Weather (all zero)';

  public static weatherSources = [
    { id: 'W', name: this.weatherSource_station },
    { id: 'M', name: this.weatherSource_manual },
    { id: 'I', name: this.weatherSource_ignore },
  ] as IStringDropListItem[];
  
  public static irrigationOverrideUnits = [
    { id: 0, name: 'VOLUME_UNIT', description: 'Volume' },
    { id: 1, name: 'DEPTH_UNIT/day', description: 'Application' },
    { id: 2, name: 'hh:mm', description: 'Run Time' },
  ] as IIdNameItem[];
}
