import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

export class TankMixesReportController extends BaseController {
  public arrLogsDataCSVhdr: string[];
  public fertVolUnit: uomUnit;
  public mycsvDetails: any[] = [];
  public selectedTankMixes: Asset[] = [];
  public weightUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    arrLogsDataCSVhdr: string[],
    fertVolUnit: uomUnit,
    mycsvDetails: any[],
    selectedTankMixes: Asset[],
    weightUnit: uomUnit,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.arrLogsDataCSVhdr = arrLogsDataCSVhdr;
    this.fertVolUnit = fertVolUnit;
    this.mycsvDetails = mycsvDetails;
    this.selectedTankMixes = selectedTankMixes;
    this.weightUnit = weightUnit;
  }

  public close() {
    this._mdDialog.hide();
  }
}
