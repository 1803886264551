// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nutrient-programs md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#nutrient-programs md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#nutrient-programs md-input-container .md-input {
  color: white;
  border-color: white;
  width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/programs/programs.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ;AAEQ;EACI,YAAA;EACA,mBAAA;EACA,WAAA;AAAZ","sourcesContent":["#nutrient-programs {\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n            width: 80px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
