// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nutrient-analytes md-checkbox {
  margin-bottom: 0px;
}
#nutrient-analytes md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#nutrient-analytes md-input-container .md-errors-spacer {
  min-height: 0px !important;
}
#nutrient-analytes md-input-container .md-input {
  color: white;
  border-color: white;
  width: 80px;
}

#update-sys-analyte-dialog .title {
  padding-left: 16px;
}

#update-sys-analyte-client-dialog .title {
  padding-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/analytes/analytes.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AAEI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ;AAEQ;EACI,YAAA;EACA,mBAAA;EACA,WAAA;AAAZ;;AAKI;EACI,kBAAA;AAFR;;AAMI;EACI,kBAAA;AAHR","sourcesContent":["#nutrient-analytes {\r\n    md-checkbox {\r\n        margin-bottom: 0px;\r\n    }\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n            width: 80px;\r\n        }\r\n    }\r\n}\r\n#update-sys-analyte-dialog {\r\n    .title {\r\n        padding-left: 16px;\r\n    }\r\n}\r\n#update-sys-analyte-client-dialog {\r\n    .title {\r\n        padding-left: 16px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
