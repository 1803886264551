import * as angular from 'angular';
import * as moment from 'moment';
import { P_to_P2O5, K_to_K2O, S_to_SO4 } from '@common/ratios';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { ApplicationInstance, FertDistribution, Nutrient } from '@common/nutrients.interface';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NutrientsService } from '@services/nutrients.service';
import { DayNumberService } from '@services/day-number.service';
import { ProgramService, SelectedSamplePointRow } from '@services/nutrients/program.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { Asset } from 'src/app/_DBContext/Asset';
import { Budget } from 'src/app/_DBContext/Budget';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';

export class ProgramDetailsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _nutrientsService: NutrientsService;
  private _programService: ProgramService;

  public stepNumber = 1;
  public programId: number;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    ProgramService: ProgramService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._nutrientsService = NutrientsService;
    this._programService = ProgramService;

    this.entityManager = DataEntityService.manager;
    this.programId = $state.params.id;
  }

  $onInit() {
    this._programService.programName = '';
  }

  public saveChanges() {
    // save the budgetnutrientprofile first
    const entityArray = [];
    entityArray.push(this._programService.budgetNutrientProfile);

    this._programService.nutrientSettings.forEach((ns) => {
      if (ns.entityAspect.entityState !== breeze.EntityState.Detached) {
        entityArray.push(ns);
      }
    });

    this.entityManager.saveChanges(entityArray).then((savedEntities) => {
      const savedBudgetNootProfile = savedEntities.entities.filter((e) => {
        return e instanceof Asset;
      })[0] as Asset;

      const savedNutrientSettings = savedEntities.entities.filter((e) => {
        return e instanceof NutrientSetting;
      }) as NutrientSetting[];

      const budgetEntityType = this.entityManager.metadataStore.getEntityType('Budget') as breeze.EntityType;
      const newBudgetEntity = budgetEntityType.createEntity() as Budget;

      newBudgetEntity.Status = SWANConstants.BudgetStatus.Active;
      newBudgetEntity.Name = this._programService.programName;
      newBudgetEntity.Water_KL = this._programService.waterKLPerHa;
      newBudgetEntity.AuthAccountId = this.accountId;

      const applicationInstancesJson = this._programService.applicationInstances.map((inst) => {
        const savedNutrientSettingId = savedNutrientSettings.filter((sns) => {
          return sns.AssetId === inst.nutrientSetting.AssetId && sns.Name === inst.nutrientSetting.Name;
        })[0].Id;

        const nutrientSettingsJson = {
          Id: savedNutrientSettingId,
          AssetId: inst.nutrientSetting.AssetId,
          Name: inst.nutrientSetting.Name,
        } as NutrientSetting;

        return {
          instanceNum: inst.instanceNum,
          nutrientSetting: nutrientSettingsJson,
          spread: inst.spread,
          startDate: inst.startDate,
          startDateYMD: this._dayNumberService.convertCalendarDateToYMD(inst.startDate),
          week: inst.week,
          value: null,
        } as ApplicationInstance;
      });

      // Convert selectedFertilisers to new entities to prevent circular JSON stringify error
      const selectedFertilisers = [];
      this._programService.selectedFerts.forEach((fert) => {
        selectedFertilisers.push({
          AssetId: fert.AssetId,
          MixingCost: fert.MixingCost ? fert.MixingCost : 0,
          Quantity: fert.QuantityLorKG,
        });
      });

      const samplePointRowsJson = this._programService.selectedSamplePointRows.map((row) => {
        const samplePointJson = {
          AssetId: row.SamplePoint.AssetId,
          Name: row.SamplePoint.Name,
        } as Asset;

        return {
          Percentage: row.Percentage,
          SamplePoint: samplePointJson,
        } as SelectedSamplePointRow;
      });

      const fertDistributionJson = this._programService.fertDistributions.map((fertDist) => {
        const fertiliser = {
          AssetId: fertDist.fert.AssetId,
        } as Fertiliser;

        const applicationInstancesJson = fertDist.applicationInstances.map((inst) => {
          const nutrientSettingsJson = {
            Id: inst.nutrientSetting.Id,
            AssetId: inst.nutrientSetting.AssetId,
            Name: inst.nutrientSetting.Name,
          } as NutrientSetting;

          return {
            instanceNum: inst.instanceNum,
            nutrientSetting: nutrientSettingsJson,
            spread: inst.spread,
            startDate: inst.startDate,
            startDateYMD: this._dayNumberService.convertCalendarDateToYMD(inst.startDate),
            week: inst.week,
            value: inst.value,
          } as ApplicationInstance;
        });

        return {
          fert: fertiliser,
          totalApplying: fertDist.totalApplying,
          applicationInstances: applicationInstancesJson,
        } as FertDistribution;
      });

      const ms = moment(this._programService.endDate).diff(moment(this._programService.startDate));
      const duration = moment.duration(ms);
      const weeks = duration.asDays() / 7;

      /**
       * Saving Total Nutrient Targets, we need to check P/P2O5 K/K2O or S/SO4 coming from fert req
       */
      const totalNutrientTargets = this._programService.totalNutrientTarget;
      const nutrientVariations = this._programService.nutVariation;

      //check whether P or P2O5 selected
      if (nutrientVariations?.length) {
        //Original values coming
        const variationsP = nutrientVariations.filter((f) => f.NutrientName === 'P');
        if (!variationsP[0]?.IsOriginal) {
          //P2O5 selected
          const p2O5 = totalNutrientTargets.Noot.P;
          totalNutrientTargets.Noot.P = p2O5 / P_to_P2O5;
        }

        //check whether K or K2O selected
        const variationsK = nutrientVariations.filter((f) => f.NutrientName === 'K');
        if (!variationsK[0]?.IsOriginal) {
          //K2O selected
          const K2O = totalNutrientTargets.Noot.K;
          totalNutrientTargets.Noot.K = K2O / K_to_K2O;
        }

        //check whether S or SO4 selected
        const variationsS = nutrientVariations.filter((f) => f.NutrientName === 'S');
        if (!variationsS[0]?.IsOriginal) {
          //SO4 selected
          const SO4 = totalNutrientTargets.Noot.S;
          totalNutrientTargets.Noot.S = SO4 / S_to_SO4;
        }
      }

      const nutrientBudgetPacket = {
        Version: 1,
        StartDate: this._programService.startDate.toISOString(),
        StartDateYMD: this._dayNumberService.convertCalendarDateToYMD(this._programService.startDate),
        EndDate: this._programService.endDate.toISOString(),
        EndDateYMD: this._dayNumberService.convertCalendarDateToYMD(this._programService.endDate),
        Weeks: weeks,
        NutrientProfile: {
          Name: savedBudgetNootProfile.Name,
          AssetId: savedBudgetNootProfile.AssetId,
        } as Asset,
        Sites: this._programService.plannedApplicationSites,
        SamplePointRows: samplePointRowsJson,
        ApplicationInstances: applicationInstancesJson,
        Groups: this._programService.selectedGroups,
        Fertilisers: selectedFertilisers,
        FertDistributions: fertDistributionJson,
        TotalNutrientTargets: totalNutrientTargets.Noot,
      } as NutrientBudgetPacket;

      try {
        newBudgetEntity.NutrientBudgetPacket = JSON.stringify(nutrientBudgetPacket);
      } catch (err) {
        const errObj = err as Error;
        this._languageService.error(errObj.message);
        return;
      }

      const successCallback = (newBudgetId: number) => {
        this._languageService.showSaveSuccess();
        const confirm = this._mdDialog
          .confirm()
          .title(this._languageService.instant('NUTR.PROG.APPLY_NEW_NUTRIENT_PROG'))
          .textContent('')
          .ariaLabel(this._languageService.instant('NUTR.PROG.APPLY_NUTRIENT_PROGRAM'))
          .ok(this._languageService.instant('COMMON.YES'))
          .cancel(this._languageService.instant('COMMON.NO'));
        this._mdDialog.show(confirm).then(() => {
          // Proceed with applying water budget
          // Update SiteSettingsWater BudgetId
          const newId = newBudgetId;

          const data = {
            budgetId: newId,
          } as fuse.applyProgramDto;

          this._http.post<any>(CommonHelper.getApiUrl('nutrients/ApplyNutrientProgramToSites'), data).then((response) => {
            if (response.data == 'Budget Applied') {
              this._languageService.success('NUTR.PROG.SUCCESSFULLY_APPLIED');
            } else {
              angular.forEach(response.data, (message) => {
                this._languageService.warning(message as string);
              });
            }
          });
        });
        this.gotoPrograms();
      };

      const failCallback = (err) => {
        console.log(err);
      };

      const params = {
        AuthAccountId: this.accountId,
        NutrientBudgetPacket: newBudgetEntity.NutrientBudgetPacket,
        Name: this._programService.programName,
        Water_KL: newBudgetEntity.Water_KL,
      };

      this._http
        .post(CommonHelper.getApiUrl('nutrients/SaveBudget'), params)
        .then((newBudgetId) => successCallback(newBudgetId.data as number), failCallback);
    });
  }

  private gotoPrograms() {
    this._nutrientsService.setKeepFilter(true);
    this._state.go('app.nutrients.programs');
  }
}

export interface NutrientBudgetPacket {
  Version: number;
  StartDate: string;
  EndDate: string;
  StartDateYMD: string;
  EndDateYMD: string;
  LocaleStartDate: Date;
  LocaleEndDate: Date;
  Weeks: number;
  SamplePointRows: SelectedSamplePointRow[];
  NutrientProfile: Asset;
  ApplicationInstances: ApplicationInstance[];
  Sites: Asset[];
  Groups: Asset[];
  Fertilisers: Fertiliser[];
  FertDistributions: FertDistribution[];
  TotalNutrientTargets: Nutrient;
}

angular.module('app.nutrients').controller('ProgramDetailsController', ProgramDetailsController);
