import * as angular from 'angular';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { SysAnalyte } from 'src/app/_DBContext/SysAnalyte';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class AnalyteRequestController extends BaseController {
  public name: string;
  public symbol: string;
  public units: string;
  public targetRequirements: number;
  public maxThreshold: number;

  public sendBtnDisabled: boolean = false;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._languageService = LanguageService;
  }

  public cancel() {
    this._mdDialog.hide();
  }

  public submit() {
    this.sendBtnDisabled = true;

    this._requestAnalyte();

    this._mdDialog.hide();
  }

  private _requestAnalyte() {
    const params = {
      AccountId: this.accountId,
      SysAnalyteClient: {
        MaxThreshold: this.maxThreshold,
        SysAnalyte: {
          Name: this.name,
          Symbol: this.symbol,
        } as SysAnalyte,
        TargetRequirements: this.targetRequirements,
        UnitClass: this.units,
      } as SysAnalyteClient,
    };

    return this._http.post(CommonHelper.getApiUrl('user/analyteRequest'), params).then((response) => {
      this._languageService.success('NUTR.ANA.ANALYTE_REQUEST_SENT');
      return response;
    });
  }

  private goToAnalyteDetail(savedFertiliser: Fertiliser) {
    this._state.go('app.nutrients.analyte.detail', { id: savedFertiliser.AssetId });
  }
}

angular.module('app.nutrients').controller('AnalyteRequestController', AnalyteRequestController);
