import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { AssetClassService } from '@services/asset-class.service';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { AssetClass } from 'src/app/_DBContext/AssetClass';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { BaseController } from 'src/app/base.controller';

export class AddProfileDialogController extends BaseController {
  public newName: string;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _assetClassService: AssetClassService;
  private _languageService: LanguageService;

  public sharedAssets: Asset[] = [];
  public showShared: boolean;
  public selectedsharedAsset: number;
  public nameExists: boolean = false;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AssetClassService: AssetClassService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    sharedAssets: Asset[],
    showShared: boolean,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._assetClassService = AssetClassService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.sharedAssets = sharedAssets;
    this.showShared = showShared;
  }

  public hide() {
    this._mdDialog.hide();
  }

  public cancel() {
    this._mdDialog.cancel();
  }

  private getNutrientProfileAssetClass(): AssetClass {
    return this.entityManager.getEntities('AssetClass').filter((assetClass: AssetClass) => {
        return assetClass.Name === 'NutrientProfile';
    })[0] as AssetClass;
  }

  public checkNameAlreadyExists(name: string) {
    this._assetClassService._fetchAllAssetClasses().then((assetClassData) => {
      const assetClasses = assetClassData.results as AssetClass[];
      const profileAssetClass = assetClasses.filter((ac) => {
        return ac.Name === AssetClassNameEnum.NutrientProfile;
      })[0];

      const pred = breeze.Predicate.create('Name', breeze.FilterQueryOp.Equals, name).and(
        'AssetClassId',
        breeze.FilterQueryOp.Equals,
        profileAssetClass.Id,
      );

      breeze.EntityQuery.from('AccountAssets')
        .withParameters({ accountId: this.accountId })
        .where(pred)
        .using(this.entityManager)
        .where(pred)
        .using(this.entityManager)
        .execute()
        .then((data) => {
          if (data.results.length >= 1) {
            this.nameExists = true;
          } else {
            this.nameExists = false;
          }
        });
    });
  }

  public submit() {
    const promisesArray = [];

    // Get the assetClassId
    const assetClassEntity = this.getNutrientProfileAssetClass();

    if (this.selectedsharedAsset) {
      const assetEntity = this.entityManager.getEntityByKey('Asset', this.selectedsharedAsset) as Asset;
      const assetType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
      let newEntity;
      let myProperty = [];
      let ignorecolumns = [
        'Shared',
        'TrackCreatedWhen',
        'TrackModifiedWhen',
        'TrackCreatedBy',
        'TrackModifiedBy',
        'TrackDeletedWhen',
        'TrackDeletedBy',
      ];
      //copying Profile - Asset
      assetEntity.entityType.dataProperties.forEach((obj) => {
        const index = ignorecolumns.indexOf(obj.name);
        if (obj.name !== 'AssetId' && assetEntity[obj.name]) {
          const field = obj.name.toString();
          let value = assetEntity[obj.name];

          if (obj.name === 'OwnerAccountId') {
            value = this.accountId;
          }

          if (index < 0) {
            myProperty.push('"' + field + '":"' + value + '"');
          }
        }
      });

      const params = JSON.parse('{' + myProperty.join(',') + '}');

      newEntity = assetType.createEntity(params);
      newEntity.Name = this.newName; // oldname + "_[local]";

      this.entityManager.addEntity(newEntity);

      //copying nutrient setting for the selected profile
      ignorecolumns = [
        'TrackCreatedWhen',
        'TrackModifiedWhen',
        'TrackCreatedBy',
        'TrackModifiedBy',
        'TrackDeletedWhen',
        'TrackDeletedBy',
      ];

      myProperty = [];
      const nutPromise = this.fetchNutrientSettings(this.selectedsharedAsset);
      promisesArray.push(nutPromise);
      nutPromise.then((nutrientSettings) => {
        nutrientSettings.forEach((nutsetting) => {
          //Every single nut phase needs to be added
          let newNutEntity;

          const newNutrientSettings = {} as NutrientSetting;
          nutsetting.entityType.dataProperties.forEach((obj) => {
            const index = ignorecolumns.indexOf(obj.name);
            const noots = nutsetting.Noots;

            if ((obj.name !== 'Id' && nutsetting[obj.name]) || obj.name === 'Noots') {
              newNutrientSettings[obj.name] = nutsetting[obj.name];

              if (obj.name === 'AssetId') {
                newNutrientSettings[obj.name] = newEntity.AssetId;
              }

              if (obj.name === 'Noots') {
                newNutrientSettings[obj.name] = nutsetting.Noots;
              }
            }
          });

          const nutrientSettingEntityType = this.entityManager.metadataStore.getEntityType('NutrientSetting') as breeze.EntityType;

          newNutEntity = nutrientSettingEntityType.createEntity(newNutrientSettings);

          this.entityManager.addEntity(newNutEntity);
        });
      });
    } else {
      //Normal profile creation : - Create new Profile
      const profileInitialValues = new Asset();
      profileInitialValues.OwnerAccountId = this.accountId;
      profileInitialValues.Name = this.newName;
      profileInitialValues.AssetClass = assetClassEntity;
      profileInitialValues.Status = 'Active';

      const assetAttachmentType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
      const newProfile = assetAttachmentType.createEntity(profileInitialValues) as SiteAsset;

      this.entityManager.addEntity(newProfile);
    }

    this._mdDialog.hide();

    // Navigate to the new profile
    if (promisesArray.length) {
      //If its clone make sure all promises resolved before saving the changes
      this._q.all(promisesArray).then((data) => {
          this.saveAll();
      });
    } else {
      this.saveAll();
    }
  }

  public saveAll() {
    this.entityManager.saveChanges().then((value: breeze.SaveResult) => {
      this._languageService.showSaveSuccess();
      const savedProfile = value.entities[0] as Asset;
      this.goToProfileDetail(savedProfile);
    });
  }

  public fetchNutrientSettings(assetId: number): angular.IPromise<NutrientSetting[]> {
    const defer = this._q.defer();
    // const pred = breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, assetId);

    let nutrientSettings: NutrientSetting[] = [];

    const successCallback = (data: breeze.QueryResult) => {
      nutrientSettings = data.results as NutrientSetting[];
      defer.resolve(nutrientSettings);
    };

    const failCallback = () => { };

    breeze.EntityQuery.from('assets/' + assetId + '/NutrientSettings')
      .using(this.entityManager)
      .execute()
      .then(successCallback, failCallback);

    return defer.promise as angular.IPromise<NutrientSetting[]>;
  }

  private goToProfileDetail(savedProfile: Asset) {
    this._state.go('app.nutrients.profiles.detail', { id: savedProfile.AssetId });
  }
}

angular.module('app.nutrients').controller('AddProfileDialogController', AddProfileDialogController);
