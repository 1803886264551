import * as angular from 'angular';
import * as breeze from 'breeze';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';
import { Unit } from 'src/app/_DBContext/Unit';
import { UnitBaseClass } from 'src/app/_DBContext/UnitBaseClass';
import { BaseController } from 'src/app/base.controller';

export class UpdateSysAnalyteClientController extends BaseController {
  public isSwanSystemAccount: boolean;
  public isSwanSystem: boolean;
  public analyteClient: SysAnalyteClient;
  public weightAreaNormalUnit: string;

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private _unitBaseClasses: UnitBaseClass[];
  private _units: Unit[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    item: SysAnalyteClient,
    unitBaseClasses: UnitBaseClass[],
    units: Unit[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.isSwanSystemAccount = PermissionService.isSwanSystemAccount;
    this.isSwanSystem = item.AccountId == SWANConstants.SystemAccountNumber;
    this.analyteClient = item;
    this.weightAreaNormalUnit = UnitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);

    this._unitBaseClasses = unitBaseClasses;
    this._units = units;
  }

  public getMesurementUnit(theId) {
    let theIdName = '-';
    if (this._units != null) {
      for (let j = 0; j < this._units.length; j++) {
        if (this._units[j].Id == theId) {
          theIdName = this._units[j].Name;
          break;
        }
      }
    }
    return theIdName;
  }

  //previous coding for submit commented above. please refer if any change
  public submit() {
    // update existing analyte client
    let existingAnalyteClient;
    if (this.analyteClient.Id) {
      existingAnalyteClient = this.entityManager.getEntityByKey('SysAnalyteClient', this.analyteClient.Id) as SysAnalyteClient;
      if (existingAnalyteClient != null && angular.isDefined(existingAnalyteClient)) {
        existingAnalyteClient.TargetRequirements = this.analyteClient.TargetRequirements;
        existingAnalyteClient.MaxThreshold = this.analyteClient.MaxThreshold;
        existingAnalyteClient.IsIncludeForDisplay = this.analyteClient.IsIncludeForDisplay;
        existingAnalyteClient.IsIncludeInAnalytes = this.analyteClient.IsIncludeInAnalytes;
        existingAnalyteClient.IsIncludeInFertiliser = this.analyteClient.IsIncludeInFertiliser;
      }
    }

    if (!this.analyteClient.Id || existingAnalyteClient == null || angular.isUndefined(existingAnalyteClient)) {
      // create new analyte client
      const analyteClientEntityType = this.entityManager.metadataStore.getEntityType('SysAnalyteClient') as breeze.EntityType;

      delete this.analyteClient.Id;
      this.analyteClient = analyteClientEntityType.createEntity(this.analyteClient) as SysAnalyteClient;
      this.entityManager.addEntity(this.analyteClient);
    }

    // Navigate to the new Analyte
    this.entityManager
      .saveChanges()
      .then((value: breeze.SaveResult) => {
        this._languageService.showSaveSuccess();
      })
      .finally(() => {
        this._mdDialog.hide();
      });
  }

  public cancelEdit() {
    if (this.analyteClient.Id) {
      this._mdDialog.hide({ response: 'cancel' });
    }
  }
}

angular.module('app.nutrients').controller('UpdateSysAnalyteClientController', UpdateSysAnalyteClientController);
