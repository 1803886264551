import * as angular from 'angular';
import { DataEntityService } from '@services/data-entity.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { BaseController } from 'src/app/base.controller';
import { TableControl } from '@common/helpers/TableControl';
import { AddAnalyteDialogController } from './addAnalyte-dialog.controller';
import { AnalyteRequestController } from './analyteRequest.controller';
import { UpdateSysAnalyteController } from './updateSysAnalyte.controller';
import { UpdateSysAnalyteClientController } from './updateSysAnalyteClient.controller';
import { SysAnalyte } from 'src/app/_DBContext/SysAnalyte';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';
import { Unit } from 'src/app/_DBContext/Unit';
import { UnitBaseClass } from 'src/app/_DBContext/UnitBaseClass';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

export class AnalytesController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _fertiliserService: FertiliserService;
  private _nutrientsService: NutrientsService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public showAddAnalyteBtn: boolean = true;

  public nameFilter = '';
  public typeFilter = '';
  public symbolFilter = '';
  public subClassFilter = '';
  public unitBaseFilter = '';
  public measurementUnitFilter = '';
  public analytes: SysAnalyte[] = [];
  public filteredAnalytes: SysAnalyte[];
  public clientanalytes: SysAnalyteClient[] = [];
  public filteredAnalytesClient: SysAnalyteClient[];
  public tableControl = new TableControl();
  public clientAnalytesDictionary = {};
  public mergedAnalytes: SysAnalyteClient[] = [];

  public _unitbaseClasses: UnitBaseClass[];
  public _units: Unit[];

  public weightAreaUnit: uomUnit;

  private _isSwanSystemAccount: boolean;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._fertiliserService = FertiliserService;
    this._nutrientsService = NutrientsService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this._isSwanSystemAccount = PermissionService.isSwanSystemAccount;

    if (NutrientsService.getKeepFilter() == true) {
      this.nameFilter = NutrientsService.getNameFilter();
      this.typeFilter = NutrientsService.getTypeFilter();
      this.symbolFilter = NutrientsService.getSymbolFilter();
      this.subClassFilter = NutrientsService.getSubClassFilter();
      this.unitBaseFilter = NutrientsService.getUnitBaseFilter();
      this.measurementUnitFilter = NutrientsService.getMeasurementUnitFilter();
      NutrientsService.setKeepFilter(false);
    } else {
      NutrientsService.setNameFilter(this.nameFilter);
      NutrientsService.setTypeFilter(this.typeFilter);
      NutrientsService.setSymbolFilter(this.symbolFilter);
      NutrientsService.setSubClassFilter(this.subClassFilter);
      NutrientsService.setUnitBaseFilter(this.unitBaseFilter);
      NutrientsService.setMeasurementUnitFilter(this.measurementUnitFilter);
    }

    this.weightAreaUnit = this._unitOfMeasureService.getUnits('Weight/Area');
  }

  $onInit() {
    this.setWatches();
    this._fetchData();
  }

  public _fetchData() {
    this.showAddAnalyteBtn = this._isSwanSystemAccount;

    this._fetchConfiguration();
    this.fetchUnitBaseClasses();
  }

  private fetchUnitBaseClasses() {
    breeze.EntityQuery.from('UnitBaseClass')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results) {
          this._unitbaseClasses = data.results as UnitBaseClass[];
        }
      });

    breeze.EntityQuery.from('getUnits')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results) {
          this._units = data.results as Unit[];
        }
      });
  }

  private _fetchConfiguration() {
    const promises = [];

    promises.push(
      breeze.EntityQuery.from('SysAnalytes')
        .withParameters({ accountId: this.accountId })
        .orderBy('Name')
        .using(this.entityManager)
        .execute()
        .then((data) => {
          this.analytes = data.results as SysAnalyte[];
        }),
    );
    if (!this._isSwanSystemAccount) {
      promises.push(
        breeze.EntityQuery.from('SysAnalyteClient')
          .expand('SysAnalyte')
          .withParameters({ accountId: this.accountId })
          .using(this.entityManager)
          .execute()
          .then((data: breeze.QueryResult) => {
            if (data.results) {
              this.clientanalytes = data.results as SysAnalyteClient[];

              this.clientanalytes.forEach((row: SysAnalyteClient) => {
                row.decimalPlaces = this._fertiliserService.nootDP(row.SysAnalyte.Symbol);
                this.clientAnalytesDictionary[row.SysAnalyte.Name] = {
                  Id: row.Id,
                  SubClass: row.SubClass,
                  IsIncludeForDisplay: row.IsIncludeForDisplay,
                  IsIncludeInAnalytes: row.IsIncludeInAnalytes,
                  IsIncludeInFertiliser: row.IsIncludeInFertiliser,
                } as SysAnalyteClient;
              });
            }
          }),
      );
    }
    this._q.all(promises).then(() => {
      this.setMergedAnalytes();
      this.filterAnalytesClient();
    });
  }

  private setMergedAnalytes() {
    this.mergedAnalytes = [];
    // populate merged analytes from Swan System Analytes
    this.analytes.forEach((analyte) => {
      const objSysAnalyteClient = analyte.SysAnalyteClients[0];
      this.mergedAnalytes.push({
        Id: analyte.Id,
        SubClass: analyte.SubClass ? analyte.SubClass : '',
        AccountId: this.accountId,
        AnalyteId: analyte.Id,
        SysAnalyte: analyte,
        IsIncludeForDisplay: false,
        IsIncludeInAnalytes: false,
        IsIncludeInFertiliser: false,
        MaxThreshold:
          angular.isDefined(objSysAnalyteClient) &&
          objSysAnalyteClient != null &&
          objSysAnalyteClient.MaxThreshold != null &&
          angular.isDefined(objSysAnalyteClient.MaxThreshold)
            ? objSysAnalyteClient.MaxThreshold
            : 0,
        TargetRequirements:
          angular.isDefined(objSysAnalyteClient) &&
          objSysAnalyteClient != null &&
          objSysAnalyteClient.TargetRequirements != null &&
          angular.isDefined(objSysAnalyteClient.TargetRequirements)
            ? objSysAnalyteClient.TargetRequirements
            : 0,
        decimalPlaces: this._fertiliserService.nootDP(analyte.Symbol),
      } as SysAnalyteClient);
    });

    const clientAnalytesIdRefs = this.clientanalytes.map((cAnalyte) => {
      return cAnalyte.AnalyteId;
    });

    // mark any includes if there are any sysanalyteclients
    this.mergedAnalytes
      .filter((mAnalyte) => {
        return clientAnalytesIdRefs.indexOf(mAnalyte.AnalyteId) !== -1;
      })
      .map((mAnalyte) => {
        mAnalyte.Id = this.clientAnalytesDictionary[mAnalyte.SysAnalyte.Name].Id;
        mAnalyte.IsIncludeForDisplay = this.clientAnalytesDictionary[mAnalyte.SysAnalyte.Name].IsIncludeForDisplay;
        mAnalyte.IsIncludeInAnalytes = this.clientAnalytesDictionary[mAnalyte.SysAnalyte.Name].IsIncludeInAnalytes;
        mAnalyte.IsIncludeInFertiliser = this.clientAnalytesDictionary[mAnalyte.SysAnalyte.Name].IsIncludeInFertiliser;
      });
  }

  private setWatches() {
    this.scope.$watchGroup(
      ['vm.nameFilter', 'vm.typeFilter', 'vm.symbolFilter', 'vm.subClassFilter', 'vm.unitBaseFilter', 'vm.measurementUnitFilter'],
      () => {
        if (this.analytes.length) {
          if (this._isSwanSystemAccount) {
            this.setFilteredAnalytes();
          } else {
            this.filterAnalytesClient();
          }
        }
      },
    );
  }

  private setFilteredAnalytes() {
    this.filteredAnalytes = this.analytes;
  }

  private filterAnalytesClient() {
    this._nutrientsService.setNameFilter(this.nameFilter);
    if (this.mergedAnalytes) {
      this.filteredAnalytesClient = this.mergedAnalytes.filter((a) => a.SysAnalyte.Name.toLowerCase().includes(this.nameFilter.toLowerCase()));
      this.filteredAnalytesClient = this.filteredAnalytesClient.filter((a) => a.SysAnalyte.Class.toLowerCase().includes(this.typeFilter.toLowerCase()));
      this.filteredAnalytesClient = this.filteredAnalytesClient.filter((a) => a.SysAnalyte.Symbol.toLowerCase().includes(this.symbolFilter.toLowerCase()));

      if (this.subClassFilter) {
        this.filteredAnalytesClient = this.filteredAnalytesClient.filter((a) =>  a.SysAnalyte.SubClass?.toLowerCase().includes(this.subClassFilter.toLowerCase()));
      }

      if (this.unitBaseFilter) {
        this.filteredAnalytesClient = this.filteredAnalytesClient.filter((a) => a.SysAnalyte.UnitBaseClass.Name.toLowerCase().includes(this.unitBaseFilter.toLowerCase()));
      }

      if (this.measurementUnitFilter) {
        this.filteredAnalytesClient = this.filteredAnalytesClient.filter((a) => this.getMesurementUnit(a.SysAnalyte.UnitClassId).toLowerCase().includes(this.measurementUnitFilter.toLowerCase()));
      }
    }
  }

  public getMesurementUnit(theId) {
    let theIdName = '-';
    if (this._units != null) {
      for (let j = 0; j < this._units.length; j++) {
        if (this._units[j].Id == theId) {
          theIdName = this._units[j].Name;
          break;
        }
      }
    }
    return theIdName;
  }

  /**
   * For a Client we expect 1 row or 0 row from db
   * @param sysanalyte
   */
  private fetchSysAnalyteClient(sysanalyte: SysAnalyte): angular.IPromise<SysAnalyteClient> {
    let sysclients: SysAnalyteClient;
    const p = breeze.Predicate.create('AnalyteId', '==', sysanalyte.Id).and('AccountId', '==', this.accountId);
    const defer = this._q.defer();

    breeze.EntityQuery.from('SysAnalyteClient')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .where(p)
      .execute()
      .then((data) => {
        sysclients = data.results[0] as SysAnalyteClient;
        defer.resolve(sysclients);
      });
    return defer.promise as angular.IPromise<SysAnalyteClient>;
  }
  // #endregion

  /**
   * For a Client we expect 1 row or 0 row from db
   * @param sysAnalyteClient
   */
  private fetchSysAnalyteClient2(sysAnalyteClient: SysAnalyteClient): angular.IPromise<SysAnalyteClient> {
    let sysclients: SysAnalyteClient;
    const p = breeze.Predicate.create('AnalyteId', '==', sysAnalyteClient.Id).and('AccountId', '==', this.accountId);
    const defer = this._q.defer();

    breeze.EntityQuery.from('SysAnalyteClient')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .where(p)
      .execute()
      .then((data) => {
        sysclients = data.results[0] as SysAnalyteClient;
        defer.resolve(sysclients);
      });
    return defer.promise as angular.IPromise<SysAnalyteClient>;
  }

  public updateSysAnalyte(ev, item: SysAnalyte) {
    //Need to check whether their is a row exist for this client in DB, if not create one or pass the existing one for update
    this.fetchSysAnalyteClient(item).then((data) => {
      if (data) {
        //There is an existing Row, Pass it for update
        item.SysAnalyteClients[0] = data as SysAnalyteClient;
      } else {
        //create a new SysAnalyteClient and pass.

        let clientAnalyte: SysAnalyteClient;
        const clientanalyteType = this.entityManager.metadataStore.getEntityType('SysAnalyteClient') as breeze.EntityType;
        const newclientAnalyteEntity = clientanalyteType.createEntity(clientAnalyte) as SysAnalyteClient;
        newclientAnalyteEntity.AccountId = this.accountId;
        newclientAnalyteEntity.AnalyteId = item.Id;
        this.entityManager.addEntity(newclientAnalyteEntity);
        item.SysAnalyteClients[0] = newclientAnalyteEntity;
      }

      this._mdDialog.show({
        clickOutsideToClose: true,
        escapeToClose: true,
        controller: UpdateSysAnalyteController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/nutrients/analytes/updateSysAnalyte.html',
        targetEvent: ev,
        locals: {
          item: item,
          account: this.accountId,
          unitBaseClasses: this._unitbaseClasses,
          units: this._units,
        },
      } as angular.material.IDialogOptions);
    });
  }

  public updateSysAnalyteClient(ev, item: SysAnalyteClient) {
    if (this.isReadOnly) {
      return;
    }

    const itemRef = {} as SysAnalyteClient;
    angular.copy(item, itemRef);
    this._mdDialog
      .show({
        clickOutsideToClose: true,
        escapeToClose: true,
        controller: UpdateSysAnalyteClientController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/nutrients/analytes/updateSysAnalyteClient.html',
        targetEvent: ev,
        locals: {
          item: item,
          unitBaseClasses: this._unitbaseClasses,
          units: this._units,
        },
      } as angular.material.IDialogOptions)
      .then((response) => {
        if (angular.isDefined(response)) {
          if (response == 'cancel') {
            this.filteredAnalytesClient.forEach((obj) => {
              if (obj.AnalyteId == itemRef.AnalyteId) {
                obj.Id = itemRef.Id;
                obj.AccountId = itemRef.AccountId;
                obj.MaxThreshold = itemRef.MaxThreshold;
                obj.TargetRequirements = itemRef.TargetRequirements;
                obj.IsIncludeInAnalytes = itemRef.IsIncludeInAnalytes;
                obj.IsIncludeInFertiliser = itemRef.IsIncludeInFertiliser;
              }
            });
          }
        } else {
          this._fetchData();
        }
      });
  }

  public openAddAnalyteDialog() {
    this._mdDialog
      .show({
        clickOutsideToClose: true,
        escapeToClose: true,
        controller: AddAnalyteDialogController,
        controllerAs: 'ctrl',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/nutrients/analytes/addAnalyte-dialog.html',
        locals: {
          unitBaseClasses: this._unitbaseClasses,
          units: this._units,
        },
      } as angular.material.IDialogOptions)
      .then(() => {
        this.analytes = this.entityManager.getEntities('SysAnalyte') as SysAnalyte[];
      });
  }

  public showAnalyteRequest(ev) {
    this._mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      controller: AnalyteRequestController,
      controllerAs: 'analyteRequestCtrl',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/analytes/analyte.request.html',
      targetEvent: ev,
    } as angular.material.IDialogOptions);
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
    this.symbolFilter = '';
    this.subClassFilter = '';
    this.unitBaseFilter = '';
    this.measurementUnitFilter = '';
  }
}

angular.module('app.nutrients').controller('AnalytesController', AnalytesController);
