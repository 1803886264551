import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { Analyte } from '@common/nutrients.interface';
import { AssetClassService } from '@services/asset-class.service';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { AssetClass } from 'src/app/_DBContext/AssetClass';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';
import { BaseController } from 'src/app/base.controller';

//different from AddSiteFertiliserDialogController
export class AddFertiliserDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _assetClassService: AssetClassService;
  private _dataEntityService: DataEntityService;
  private _dupeHandlerService: DupeHandlerService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AssetClassService: AssetClassService,
    DataEntityService: DataEntityService,
    DupeHandlerService: DupeHandlerService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    sharedAssets: Fertiliser[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._assetClassService = AssetClassService;
    this._dataEntityService = DataEntityService;
    this._dupeHandlerService = DupeHandlerService;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this._dupeHandlerService.setDuplicateType('Fertiliser');

    this.sharedAssets = sharedAssets;
  }

  public newName: string;
  public newType: string;
  public newUnits: string;
  public sharedAssets: Fertiliser[] = [];
  public showShared = false;
  public selectedsharedAsset: number;
  private assetClassFertiliser: AssetClass;
  private _fertiliserService: FertiliserService;
  public nameExists: boolean = false;

  public cancel() {
    this._mdDialog.hide();
  }

  public checkNameAlreadyExists(name: string) {
    const pred = breeze.Predicate.create('Name', breeze.FilterQueryOp.Equals, this.newName).and(
      'AssetClassId',
      breeze.FilterQueryOp.Equals,
      14,
    );

    breeze.EntityQuery.from('AccountAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results.length >= 1) this.nameExists = true;
        else this.nameExists = false;
      });
  }

  public getSharedFertiliserDetails(): void {
    if (!this.selectedsharedAsset) {
      return;
    }

    const asset = this.entityManager.getEntityByKey('Asset', this.selectedsharedAsset) as Asset;

    this.newName = asset.Name;
    this.newType = asset.Fertiliser.Type;
    this.newUnits = asset.Fertiliser.Units;
  }

  public submit() {
    const promise = this._assetClassService._fetchAssetClass(AssetClassNameEnum.Fertiliser);
    const defer = this._q.defer();

    promise.then((data) => {
      this.assetClassFertiliser = data.results[0] as AssetClass;

      const fertiliserType = this.entityManager.metadataStore.getEntityType('Fertiliser') as breeze.EntityType;
      const obsNutrientType = this.entityManager.metadataStore.getEntityType('ObsNutrients') as breeze.EntityType;
      const newFertiliser = fertiliserType.createEntity() as Fertiliser;

      // Create Asset
      const assetType = this.entityManager.metadataStore.getEntityType('Asset') as breeze.EntityType;
      const newAsset = assetType.createEntity() as Asset;

      if (this.selectedsharedAsset) {
        const assetEntity = this.entityManager.getEntityByKey('Asset', this.selectedsharedAsset) as Asset;
        let newAssetEntity: Asset;
        let myProperty = [];
        let ignoreProps = [
          'Shared',
          'TrackCreatedWhen',
          'TrackModifiedWhen',
          'TrackCreatedBy',
          'TrackModifiedBy',
          'TrackDeletedWhen',
          'TrackDeletedBy',
        ];
        //copying Asset
        assetEntity.entityType.dataProperties.forEach((obj) => {
          const index = ignoreProps.indexOf(obj.name);
          if (obj.name !== 'AssetId' && assetEntity[obj.name]) {
            const field = obj.name.toString();
            let value = assetEntity[obj.name];

            if (obj.name === 'OwnerAccountId') {
              value = this.accountId;
            }

            if (index < 0) myProperty.push('"' + field + '":"' + value + '"');
          }
        });

        const params = JSON.parse('{' + myProperty.join(',') + '}');
        newAssetEntity = assetType.createEntity(params) as Asset;
        newAssetEntity.Name = this.newName; //oldname + "_[local]";
        this.entityManager.addEntity(newAssetEntity);

        //Copying Fertiliser
        myProperty = [];
        assetEntity.Fertiliser.entityType.dataProperties.forEach((obj) => {
          if (assetEntity.Fertiliser[obj.name]) {
            const field = obj.name.toString();
            let value = assetEntity.Fertiliser[obj.name];

            if (obj.name === 'OwnerAccountId') {
              value = this.accountId;
            }
            if (obj.name === 'AssetId') {
              value = newAssetEntity.AssetId;
            }
            myProperty.push('"' + field + '":"' + value + '"');
          }
        });
        const fertparams = JSON.parse('{' + myProperty.join(',') + '}');
        const newfertEntity = fertiliserType.createEntity(fertparams) as Fertiliser;
        newAssetEntity.Fertiliser = newfertEntity;

        //Copy ObsNutrient values
        myProperty = [];
        ignoreProps = [
          'Id',
          'TrackCreatedWhen',
          'TrackModifiedWhen',
          'TrackCreatedBy',
          'TrackModifiedBy',
          'TrackDeletedWhen',
          'TrackDeletedBy',
        ];

        const promises = [];

        promises.push(this.fetchSelectedAnalytesClient()); //Fetch all analytes applicable to the account

        this._q.all(promises).then((data: breeze.QueryResult[]) => {
          this._fertiliserService.fetchObsNutrient(this.selectedsharedAsset, null).then((data2) => {
            const newObsNutrient = {} as ObsNutrients;
            const obsNut = data2 as ObsNutrients;
            obsNut.entityType.dataProperties.forEach((obj) => {
              const index = ignoreProps.indexOf(obj.name);
              if (index < 0) {
                if (obsNut[obj.name]) {
                  const field = obj.name.toString();
                  const value = obsNut[obj.name];

                  newObsNutrient[obj.name] = obsNut[obj.name];

                  if (obj.name === 'OwnerAccountId') {
                    newObsNutrient[obj.name] = this.accountId;
                  }
                  if (obj.name === 'AssetId') {
                    newObsNutrient[obj.name] = newAssetEntity.AssetId;
                  }

                  if (obj.name === 'Noots') {
                    newObsNutrient[obj.name] = obsNut.Noots;

                    //#IG 212 Cloned fertiliser should include anlaytes from Parent + what is available for the account.

                    const existingAnalyte = obsNut.Noots.Analytes; //Analytes from cloned fertiliser
                    let existingAnalytes: Analyte[] = [];

                    if (existingAnalyte) {
                      existingAnalytes = JSON.parse(existingAnalyte);
                    }

                    const units = [{ id: 1, value: 'kg/L' }];
                    const analytesLst: SysAnalyteClient[] = data?.[0]?.results as any[] ?? [];

                    if (analytesLst.length) {
                      const currentOnes = analytesLst.map((analyte) => {
                        return {
                          Name: analyte.SysAnalyte.Name,
                          Value: 0,
                          Unit: units[0],
                        } as Analyte;
                      });

                      //This will check whether new elements exist in Existing list, if no then add
                      currentOnes.forEach((newAnalyte) => {
                        if (!existingAnalytes.filter((s) => s.Name === newAnalyte.Name).length) {
                          existingAnalytes.push(newAnalyte);
                        }
                      });

                      newObsNutrient[obj.name].Analytes = JSON.stringify(existingAnalytes); //Analytes from the A/C
                    }
                  }
                }
              }
            });

            newObsNutrient.dayNumber = -999;
            const obsNutrientsEntityType = this.entityManager.metadataStore.getEntityType('ObsNutrients') as breeze.EntityType;
            const newObsEntity = obsNutrientsEntityType.createEntity(newObsNutrient);

            this.entityManager.addEntity(newObsEntity);

            this._mdDialog.hide();

            defer.resolve();
          });
        });
      } else {
        newAsset.Name = this.newName;
        newFertiliser.Type = this.newType;
        newAsset.Fertiliser = newFertiliser;
        newAsset.AssetClassId = this.assetClassFertiliser.Id;
        newAsset.OwnerAccountId = this.accountId;

        if (this.newType === 'Tank Mix') {
          newAsset.Fertiliser.Units = 'Litres';
          newAsset.Status = 'Active';
        } else if (this.newType === 'Raw') {
          newAsset.Fertiliser.Units = this.newUnits;
        }

        this.entityManager.addEntity(newAsset);
        this._mdDialog.hide();

        defer.resolve();
      }

      // Navigate to the new fertiliser
      this._dupeHandlerService.setDuplicateType('Fertiliser');
      this._dataEntityService.saveChanges(false, this._dupeHandlerService.duplicatesOnly).then(
        (value: breeze.SaveResult) => {
          this._languageService.showSaveSuccess();

          const fertiliserEntity = value.entities.filter((entity) => {
            return entity.entityType === fertiliserType;
          })[0] as Fertiliser;

          defer.promise.then(() => {
            const changes = this.entityManager.getChanges();

            changes.map((change) => {
              if (change.entityType == obsNutrientType && change.entityAspect.entityState == breeze.EntityState.Modified) {
                if ((change as ObsNutrients).Asset.OwnerAccountId == 1) {
                  // NOTE: not enough time to detect why cloning original entity
                  //		 caused the entity state become modified
                  change.entityAspect.rejectChanges();
                }
              }
            });

            // this is for saving obsNutrient before heading to fertiliser
            this.entityManager.saveChanges().then(() => {
              if (fertiliserEntity.Type === 'Raw') {
                this.goToFertiliserDetail(fertiliserEntity.AssetId);
              } else {
                this.gotoTankMixDetail(fertiliserEntity.AssetId);
              }
            });
          });
        },
        (error) => {
          this._dupeHandlerService.showError(error);
          this._dataEntityService.rejectChanges();
        },
      );
    });
  }

  //fetch all analytes those are selected for includ ein fertliser
  public fetchSelectedAnalytesClient(): Promise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('IsIncludeInFertiliser', '==', true);

    return breeze.EntityQuery.from('SysAnalyteClient')
      .expand('SysAnalyte')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute();
  }

  private goToFertiliserDetail(fertiliserId: number) {
    this._state.go('app.nutrients.fertiliser.detail', { id: fertiliserId });
  }

  private gotoTankMixDetail(tankmixId: number) {
    this._state.go('app.nutrients.tankmix.detail', { id: tankmixId });
  }
}

angular.module('app.nutrients').controller('AddFertiliserDialogController', AddFertiliserDialogController);
