import * as angular from 'angular';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { BaseController } from 'src/app/base.controller';
import { Analyte } from '@common/nutrients.interface';
import { CalculatedResults } from '../sampling/sample.detail.controller';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { LanguageService } from '@services/language.service';
import { NutrientService } from '@services/nutrients/nutrient.service';
import { SamplePointService } from '@services/nutrients/sample-point.service';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { unitSizes, AmGraphDataValues } from '@common/enums';
import { PermissionService } from '@services/permission.service';

class NutrientSampleNutrientComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    calculatedResults: '=',
    reloadChanges: '<',
    obsNutrient: '=',
    nitrogenChanges: '<',
  };

  controller = NutrientSampleNutrientController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/nutrients/components/nutrient-sample-nutrient.component.html';
}

enum CALCULATION_TYPE {
  EDIT,
  INIT,
}

class NutrientSampleNutrientController extends BaseController {
  public nootNames;
  public elementOpts;
  public nutrientDecimalPlaces: Record<string, number | null> = {};

  public elements: string[];
  public nutrientSigns: string[] = [];

  public theChartData = [];
  public obsNutrient: ObsNutrients;
  public assetId: number;
  public calculatedResults: CalculatedResults;
  public nutrientDisplayValues: number[] = [];
  public weightVolUnit: uomUnit;

  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientService: NutrientService;
  private _samplePointService: SamplePointService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    $scope: angular.IScope,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    NutrientService: NutrientService,
    PermissionService: PermissionService,
    SamplePointService: SamplePointService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientService = NutrientService;
    this._samplePointService = SamplePointService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.weightVolUnit = this._unitOfMeasureService.getUnits('Weight/Volume', unitSizes.small);
  }

  $onInit() {
    // NOTE: The order of nutrient properties is relevant!
    this.nootNames = {
      P: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'),
      K: this._languageService.instant('NUTR.CHEM.POTASSIUM'),
      S: this._languageService.instant('NUTR.CHEM.SULPHUR'),
      Ca: this._languageService.instant('NUTR.CHEM.CALCIUM'),
      Mg: this._languageService.instant('NUTR.CHEM.MAGNESIUM'),
      Na: this._languageService.instant('NUTR.CHEM.SODIUM'),
      Cl: this._languageService.instant('NUTR.CHEM.CHLORIDE'),
      Cu: this._languageService.instant('NUTR.CHEM.COPPER'),
      Fe: this._languageService.instant('NUTR.CHEM.IRON'),
      Mn: this._languageService.instant('NUTR.CHEM.MANGANESE'),
      Zn: this._languageService.instant('NUTR.CHEM.ZINC'),
      B: this._languageService.instant('NUTR.CHEM.BORON'),
      Mo: this._languageService.instant('NUTR.CHEM.MOLYBDENUM'),
    };

    this.elementOpts = {
      K: {
        current: 'K',
        opts: {
          K: { name: this._languageService.instant('NUTR.CHEM.POTASSIUM'), factor: 1 },
          K2O: { name: this._languageService.instant('NUTR.CHEM.POTASSIUM'), factor: 1.2047 },
        },
      },
      P: {
        current: 'P',
        opts: {
          P: { name: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'), factor: 1 },
          P2O5: { name: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'), factor: 2.2915 },
        },
      },
      S: {
        current: 'S',
        opts: {
          S: { name: this._languageService.instant('NUTR.CHEM.SULPHUR'), factor: 1 },
          SO4: { name: this._languageService.instant('NUTR.CHEM.SULPHATE'), factor: 3 },
        },
      },
    };

    this.elements = Object.keys(this.nootNames);
    this.nutrientDecimalPlaces = this._fertiliserService.getNutrientDecimalPaces(this.elements);

    this.elements.forEach((el) => {
      this.nutrientDisplayValues[el] = 0;
    });
  }

  $onChanges(changes) {
    if (changes.reloadChanges?.currentValue > 0) {
      for (const prop in this.nutrientSigns) {
        this.nutrientSigns[prop] = null;
      }

      if (!this.obsNutrient.Noots.Analytes) this.obsNutrient.Noots.JSONAnalytes = [];
      else {
        this.obsNutrient.Noots.JSONAnalytes = JSON.parse(this.obsNutrient.Noots.Analytes).filter((analyte: Analyte) => {
          return angular.isUndefined(analyte.CustomValue);
        });
      }

      this.updateDataAndChart(CALCULATION_TYPE.INIT);
    }
    if (changes.nitrogenChanges && changes.nitrogenChanges.currentValue > 0) {
      this.updateChart();
    }
  }
  
  public elemKeys(noot) {
    return Object.keys(this.elementOpts[noot].opts);
  }

  public elementHasOptions(elem) {
    return !!this.elementOpts[elem];
  }

  public elemLabel(elem) {
    if (this.elementHasOptions(elem)) {
      return this.elementOpts[elem].current;
    }

    return elem;
  }

  public updateDataAndChart(calcType: CALCULATION_TYPE) {
    this.convertDB(calcType);
    this.updateChart();
  }

  private updateChart() {
    const totalN: number =
      (this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.obsNutrient.Noots.NO3_N) +
      (this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.obsNutrient.Noots.NH4_N);

    const nutrientsValuesForChartDisplay: number[] = [];
    const excludeProps = ['NO3_N', 'NH4_N', 'Analytes', 'JSONAnalytes', 'JSONDisplayAnalytes'];
    // breeze.ComplexObject
    (this.obsNutrient.Noots as any).complexAspect.parentProperty.dataType.dataProperties.forEach((prop) => {
      if (excludeProps.indexOf(prop.name) === -1 && this.nutrientSigns[prop.name] !== 'NotTested') {
        nutrientsValuesForChartDisplay[prop.name] =
          this.nutrientSigns[prop.name] === 'LessThan' ? 0 : this.obsNutrient.Noots[prop.name];
      }
    });

    nutrientsValuesForChartDisplay['P2O5'] = nutrientsValuesForChartDisplay['P'] * this.elementOpts['P'].opts['P2O5'].factor;
    nutrientsValuesForChartDisplay['K2O'] = nutrientsValuesForChartDisplay['K'] * this.elementOpts['K'].opts['K2O'].factor;
    nutrientsValuesForChartDisplay['SO4'] = nutrientsValuesForChartDisplay['S'] * this.elementOpts['S'].opts['SO4'].factor;

    this.theChartData = [
      {
        name: this._languageService.instant('NUTR.CHEM.TOTAL_N'),
        value: this._unitOfMeasureService.convertFromBase('Weight/Volume', unitSizes.small, totalN),
      },
    ];

    this.elements.forEach((elem) => {
      const element = this.elemLabel(elem);
      this.theChartData.push({
        name: element,
        value: this._unitOfMeasureService.convertFromBase(
          'Weight/Volume',
          unitSizes.small,
          nutrientsValuesForChartDisplay[element],
        ),
      });
    });

    AmCharts.makeChart('nutrient-summary-chart', {
      type: 'serial',
      theme: 'light',
      dataProvider: this.theChartData,
      backgroundColor: '#EFEFEF',
      backgroundAlpha: 0.8,
      valueAxes: [
        {
          gridColor: '#FFFFFF',
          gridAlpha: 0,
          labelsEnabled: false,
          axisAlpha: 0,
        },
      ],
      gridAboveGraphs: true,
      startDuration: 0,
      graphs: [
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          fillColors: '#67b7dc',
          type: 'column',
          valueField: 'value',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            if (graphDataItem.values) {
              const val = (graphDataItem.values as AmGraphDataValues).value;
              const txt = graphDataItem.category;
              if (val) {
                return txt + ': ' + val.toFixed(2);
              }
            }
            return '';
          },
        },
      ],
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
      },
      categoryField: 'name',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      },
    } as AmCharts.AmSerialChart);

    this.updateCalculatedResults();
  }

  private updateCalculatedResults() {
    this.calculatedResults = this._samplePointService.getCalculatedResults(this.obsNutrient.Noots);
  }

  // Database stores '>' nutrients as negatives and N/A as -9999 - this func converts values back and forth for display
  private convertDB(calcType: CALCULATION_TYPE = CALCULATION_TYPE.EDIT) {
    const excludeProps = ['NO3_N', 'NH4_N', 'Analytes', 'JSONAnalytes', 'JSONDisplayAnalytes'];

    // breeze.ComplexObject
    (this.obsNutrient.Noots as any).complexAspect.parentProperty.dataType.dataProperties.forEach((prop) => {
      if (excludeProps.indexOf(prop.name) === -1) {
        switch (calcType) {
          case CALCULATION_TYPE.EDIT:
            this.obsNutrient.Noots[prop.name] = this._nutrientService.calculateDBValue(
              this.nutrientSigns[prop.name],
              this.obsNutrient.Noots[prop.name],
            );

            break;

          case CALCULATION_TYPE.INIT:
            this.nutrientDisplayValues[prop.name] = this._nutrientService.calculateDisplayValue(
              this.obsNutrient.Noots[prop.name],
            );
            this.nutrientSigns[prop.name] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots[prop.name]);
            break;

          default:
            break;
        }
      }
    });

    //console.log(this.nutrientDisplayValues);
  }

  public updateRecord(noot) {
    let val = this.nutrientDisplayValues[noot];
    if (this.elementHasOptions(noot)) {
      const obj = this.elementOpts[noot];
      const currentType = obj.current;

      val = val / obj.opts[currentType].factor;
    }

    this.obsNutrient.Noots[noot] = val;

    this.updateDataAndChart(CALCULATION_TYPE.EDIT);
  }

  public switchElement(noot) {
    if (this.elementHasOptions(noot)) {
      const obj = this.elementOpts[noot];
      const currentType = obj.current;
      const val = Math.abs(this.obsNutrient.Noots[noot]);

      const converted = val * obj.opts[currentType].factor;
      this.nutrientDisplayValues[noot] = converted;

      this.updateDataAndChart(CALCULATION_TYPE.EDIT);
    }
  }

  public switchSign(noot) {
    if (this.nutrientSigns[noot] != 'NotTested' && this.obsNutrient.Noots[noot] == -9999) {
      this.obsNutrient.Noots[noot] = 0;
      this.nutrientDisplayValues[noot] = 0;
    }
    this.updateDataAndChart(CALCULATION_TYPE.EDIT);
  }
}

angular.module('app.nutrients').component('nutrientSampleNutrient', new NutrientSampleNutrientComponent());
