// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#distribution-table {
  max-height: 300px;
  overflow: auto;
}
#distribution-table table {
  width: 100%;
  border-collapse: collapse;
}
#distribution-table table thead tr {
  color: white;
  height: 28px;
  top: 0;
  position: sticky;
  background-color: #666;
  opacity: 1;
  z-index: 2;
}
#distribution-table table thead tr th {
  min-width: 100px;
  text-align: center;
  padding: 2px 0;
}
#distribution-table table thead tr th:first-child {
  width: 120px;
  left: 0;
  text-align: left;
  z-index: 3;
  position: sticky;
  background-color: #666;
}
#distribution-table table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#distribution-table table tbody tr:hover {
  background: rgba(0, 0, 0, 0.12);
}
#distribution-table table tbody tr td {
  text-align: center;
  padding: 2px 4px;
}
#distribution-table table tbody tr td:first-child {
  width: 120px;
  left: 0;
  text-align: left;
  z-index: 1;
  position: sticky;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/programs/program-details/tabs/fertiliser-distribution.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;AACF;AACE;EACE,WAAA;EACA,yBAAA;AACJ;AAEM;EACE,YAAA;EACA,YAAA;EACA,MAAA;EACA,gBAAA;EACA,sBAAA;EACA,UAAA;EACA,UAAA;AAAR;AAEQ;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;AAAV;AAEU;EACE,YAAA;EACA,OAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,sBAAA;AAAZ;AAOM;EAKE,4CAAA;AATR;AAKQ;EACE,+BAAA;AAHV;AAQQ;EACE,kBAAA;EACA,gBAAA;AANV;AAQU;EACE,YAAA;EACA,OAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,sBAAA;AANZ","sourcesContent":["#distribution-table {\r\n  max-height: 300px;\r\n  overflow: auto;\r\n\r\n  table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n\r\n    thead {\r\n      tr {\r\n        color: white;\r\n        height: 28px;\r\n        top: 0;\r\n        position: sticky;\r\n        background-color: #666;\r\n        opacity: 1;\r\n        z-index: 2;\r\n\r\n        th {\r\n          min-width: 100px;\r\n          text-align: center;\r\n          padding: 2px 0;\r\n\r\n          &:first-child {\r\n            width: 120px;\r\n            left: 0;\r\n            text-align: left;\r\n            z-index: 3;\r\n            position: sticky;\r\n            background-color: #666;\r\n          }\r\n        }\r\n      }\r\n    }\r\n\r\n    tbody {\r\n      tr {\r\n        &:hover {\r\n          background: rgba(0, 0, 0, 0.12);\r\n        }\r\n\r\n        border-bottom:1px solid rgba(0, 0, 0, 0.12);\r\n\r\n        td {\r\n          text-align: center;\r\n          padding: 2px 4px;\r\n\r\n          &:first-child {\r\n            width: 120px;\r\n            left: 0;\r\n            text-align: left;\r\n            z-index: 1;\r\n            position: sticky;\r\n            background-color: #fff;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
