import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { Contact } from 'src/app/_DBContext/Contact';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { DataEntityService } from '@services/data-entity.service';
import { FertiliserCompatibilityDialogController } from './fertcompatibility-dialog.controller';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { FertiliserService, PriceUnit } from '@services/nutrients/fertiliser.service';
import { BaseController } from 'src/app/base.controller';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes } from '@common/enums';
import { PartsPerThousand_to_Percentage } from '@common/ratios';

/* Not actually needed to list tank mixes like most similar controllers - they're included in the Fertilisers listing. 
    But it is used a) to list Tank Mixes for the recipe report (icon on top right on fertilisers listing), and b) in routing to tank mix detail page
*/
export class TankMixDetailController extends BaseController implements GenericLst {
  name: string;
  Id: number;
  Solubility: number;

  public minDate = SWANConstants.MinDate;
  public assetId: number;
  public fertList: GenericLst[];
  public tankMixList: GenericLst[];
  public searchFert: string = '';
  public tankMixDetailScope: TankMixDetailScope;
  public selectedFert: number[];
  public toDate: Date;
  public totalMixed: number;
  public perMaxConcentration: number;
  public obsNutrientsEntityType;
  public tankMixObsNutrient: ObsNutrients;

  public fertVolUnit: uomUnit;
  public weightUnit: uomUnit;
  public solubilityUnit: uomUnit;
  public titleNoots = [
    'NO3_FORMATTED',
    'OTHER_N',
    'TOTAL_N',
    'P',
    'K',
    'S',
    'Ca',
    'Mg',
    'Na',
    'Cl',
    'Cu',
    'Fe',
    'Mn',
    'Zn',
    'B',
    'Mo',
  ];

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsFertilisersFull);

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.tankMixDetailScope = $scope as TankMixDetailScope;
    this.fertList = [];
    this.tankMixList = [];
    this.searchFert = '';

    this.toDate = new Date();
    this.totalMixed = 1;

    this.perMaxConcentration = 0;
    this.fertVolUnit = UnitOfMeasureService.getUnits(UnitTypes.FertVolume);
    this.weightUnit = UnitOfMeasureService.getUnits(UnitTypes.Weight);
    this.solubilityUnit = UnitOfMeasureService.getUnits(UnitTypes.Solubility);
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    this.toDate.setDate(this.toDate.getDate());

    this.initData();
  }

  private initData() {
    this.initFertiliserDetail();
    this._fetchData();
    this.FillAllDropdowns();
  }

  private initFertiliserDetail() {
    this.assetId = parseInt((this._state.params as any).id);
  }

  private FillAllDropdowns() {
    breeze.EntityQuery.from('Manufacturers')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.tankMixDetailScope.manufactures = data.results as Contact[];
      });
  }

  private setFertUnits() {
    this.tankMixDetailScope.fert.Asset.ChildAssets.forEach((fert) => {
      this._fertiliserService.setUnits(fert, this.weightUnit, this.fertVolUnit);
    });
  }

  public concPer1000FertVol(kgOrL, TotVolMixInLiters, unit: uomUnit) {
    return this._fertiliserService.concPer1000FertVol(kgOrL, TotVolMixInLiters, unit, this.fertVolUnit);
  }

  private _fetchData() {
    this.selectedFert = [];

    this.getFertAndTankMixList();

    this.getTankMixDetail(this.assetId).then((data) => {
      const tankMixAsset = data.results[0] as Asset;
      this.tankMixDetailScope.fert = tankMixAsset.Fertiliser;
      this.setFertUnits();
      if (this.tankMixDetailScope.fert.MixingStartDate == null) {
        this.tankMixDetailScope.fert.MixingStartDate = this.toDate;
      }

      this.fetchTankMixFertilisers(this.assetId).then((data) => {
        this.fetchAndBindrelatedValues();
      });
    });

    this._fertiliserService.fetchObsNutrient(this.assetId, null).then((obsNutrients) => {
      this.tankMixObsNutrient = obsNutrients;
    });
  }

  private fetchAndBindrelatedValues() {
    const promises = [];
    const TotVolMixInLiters = this.tankMixDetailScope.fert.TotVolMixInLiters;

    angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (assetLink: SiteAsset, val) => {
      promises.push(this._fertiliserService.fetchObsNutrient(assetLink.ChildAssetId, null));
      this.selectedFert.push(assetLink.ChildAssetId);
    });

    this._q.all(promises).then(() => {
      this.doRecalculations();
    });
  }

  public doRecalculations() {
    this.calculateTankMixObsNutrient();
    this.calculatePercofMaxConcent();
  }

  private calculateTankMixObsNutrient() {
    // Tank Mix Nutrients Container only
    const tankMixNoots = {
      NO3_N: 0,
      NH4_N: 0,
      B: 0,
      Ca: 0,
      Cl: 0,
      Cu: 0,
      Fe: 0,
      K: 0,
      Mg: 0,
      Mn: 0,
      Mo: 0,
      Na: 0,
      P: 0,
      S: 0,
      Zn: 0,
    } as NutrientFields;

    if (this.tankMixObsNutrient) {
      for (const property in tankMixNoots) {
        // parts per thousand to parts per 100
        this.tankMixObsNutrient.Noots[property] = Number((this.CalculateTotal(property) * PartsPerThousand_to_Percentage).toFixed(5));
      }
    }
  }

  //This is used for search dropdown in the detail page
  private getFertAndTankMixList() {
    const fertiliserEntities = this.entityManager.getEntities('Fertiliser');

    angular.forEach(fertiliserEntities, (obj: Fertiliser, val) => {
      const pt: GenericLst = {
        name: obj.Asset.Name,
        Id: obj.AssetId,
        Solubility: obj.Solubility,
      };

      switch (obj.Type) {
        case 'Tank Mix':
          if (obj.Asset.Status != 'Archived' && obj.Asset.OwnerAccountId === this.accountId) {
            this.tankMixList.push(pt);
          }
          break;

        case 'Raw':
          if (obj.Asset.Status != 'Archived' && obj.Asset.OwnerAccountId === this.accountId) {
            this.fertList.push(pt);
          }
          break;

        default:
          break;
      }
    });
  }

  //For binding the detail page
  private getTankMixDetail(assetId: number): Promise<breeze.QueryResult> {
    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null).and(
      'AssetId',
      breeze.FilterQueryOp.Equals,
      assetId,
    );

    return breeze.EntityQuery.from('AccountAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute();
  }

  //For binding the detail page
  private getFertiliserPriceDetails(assetId: number): Promise<breeze.QueryResult> {
    // const pred = new breeze.Predicate('FertiliserPrice', breeze.FilterQueryOp.NotEquals, null).and(
    //   'AssetId',
    //   breeze.FilterQueryOp.Equals,
    //   assetId,
    // );

    return breeze.EntityQuery.from('FertiliserPrices').withParameters({ assetId: assetId }).using(this.entityManager).execute();
  }

  private fetchTankMixFertilisers(assetId: number): angular.IPromise<SiteAsset[]> {
    const defer = this._q.defer<SiteAsset[]>();

    breeze.EntityQuery.from('SiteAssets')
      .where('ParentAssetId', breeze.FilterQueryOp.Equals, assetId)
      .withParameters({ accountId: this.accountId })
      .expand('ChildAsset.Fertiliser')
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results.length >= 1) {
          const siteAssets = data.results as SiteAsset[];
          siteAssets.forEach((sa) => this._fertiliserService.setUnits(sa, this.weightUnit, this.fertVolUnit));
          defer.resolve(siteAssets);
        }
      });

    return defer.promise;
  }

  public AddNewFert() {
    const siteAssets = this.tankMixDetailScope.fert.Asset.ChildAssets as SiteAsset[];
    const newList = this.selectedFert;
    const TankMixId = parseInt((this._state.params as any).id);
    const oldList = siteAssets;
    let exist: boolean = false;
    const TotVolMixInLiters = this.tankMixDetailScope.fert.TotVolMixInLiters;

    oldList.forEach((obj, index) => {
      //Old List
      exist = false;
      this.selectedFert.forEach((selected: number, index) => {
        //New List

        if (obj.ChildAsset.AssetId === selected) {
          exist = true;
        }
      });
      if (!exist) {
        //not exist in the new list so remove
        const index = siteAssets.indexOf(obj);
        siteAssets.splice(index, 1);
        this.RemoveSite(obj);
      }
    });
    this.selectedFert.forEach((selected: number, value) => {
      const exist = siteAssets.filter((f) => {
        return f.ChildAsset.AssetId === selected;
      })[0];
      if (angular.isUndefined(exist)) {
        //Element doesn't exist
        const siteAssetEntityType = this.entityManager.metadataStore.getEntityType('SiteAsset') as breeze.EntityType;
        const newSiteAsset = siteAssetEntityType.createEntity() as SiteAsset;
        newSiteAsset.ParentAssetId = TankMixId;
        newSiteAsset.ChildAssetId = selected;
        newSiteAsset.DataInputId = 1;
        newSiteAsset.Coefficient = 0;
        this.tankMixDetailScope.fert.Asset.ChildAssets.push(newSiteAsset);
        this._fertiliserService.setUnits(newSiteAsset, this.weightUnit, this.fertVolUnit);
        this.entityManager.addEntity(newSiteAsset);
      }
    });
    this.doRecalculations();
  }

  private RemoveSite(siteasset: SiteAsset) {
    const saPred: breeze.Predicate = breeze.Predicate.create('ParentAssetId', '==', siteasset.ParentAssetId)
      .and('ChildAssetId', '==', siteasset.ChildAssetId)
      .and('DataInputId', '==', 1);

    const query = breeze.EntityQuery.from('SiteAsset').where(saPred);

    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(query); // query the cache (synchronous)

    if (sa.length >= 1) {
      sa[0].entityAspect.setDeleted();
    }
  }

  private calculatePercofMaxConcent() {
    this.perMaxConcentration = this._fertiliserService.calculateMixsaturationPer(
      this.tankMixDetailScope.fert.Asset.ChildAssets,
      this.tankMixDetailScope.fert.TotVolMixInLiters,
    );
  }

  private reCalculateValues(coefficient: number, value: number) {
    const TotVolMixInLiters = this.tankMixDetailScope.fert.TotVolMixInLiters;
    return value * this._fertiliserService.calculateUnitsPer1000L(coefficient, TotVolMixInLiters) * 0.01;
  }

  public calculateTotalN(NO3_N: number, NH4_N: number, coefficient: number) {
    let NitrateN: number = 0;
    let OtherN: number = 0;
    let TotalN: number = 0;
    if (NO3_N > 0) NitrateN = NO3_N;
    if (NH4_N > 0) OtherN = NH4_N;

    TotalN = NitrateN + OtherN;
    return this.reCalculateValues(coefficient, TotalN);
  }

  private CalculateTotal(type: string) {
    let sum: number = 0;
    const TotVolMixInLiters = this.tankMixDetailScope.fert.TotVolMixInLiters;

    switch (type) {
      // Should probably remove price calcs - don't think we're using them anymore
      case 'lprice':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, value) => {
          const fertPrices = obj.ChildAsset.Fertiliser.FertiliserPrices;
          const coefficient = obj.Coefficient;
          const price = this._fertiliserService.reCalculateMinMaxValues(
            this._fertiliserService.getMinValue(fertPrices),
            coefficient,
            TotVolMixInLiters,
            PriceUnit.PricePer1000L,
          );

          if (!Number.isNaN(price)) sum = sum + price;
        });
        break;
      case 'hprice':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, value) => {
          const fertPrices = obj.ChildAsset.Fertiliser.FertiliserPrices;
          const coefficient = obj.Coefficient;
          if (fertPrices) {
            const price = this._fertiliserService.reCalculateMinMaxValues(
              this._fertiliserService.getMaxValue(fertPrices),
              coefficient,
              TotVolMixInLiters,
              PriceUnit.PricePer1000L,
            );

            if (!Number.isNaN(price)) sum = sum + price;
          }
        });
        break;
      case 'solubility':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, value) => {
          sum = sum + obj.ChildAsset.Fertiliser.Solubility;
        });
        break;
      case 'NO3_N': // See NitrateN
      case 'NH4_N': // See OtherN
      case 'P':
      case 'K':
      case 'S':
      case 'Ca':
      case 'Na':
      case 'Mg':
      case 'Cl':
      case 'Cu':
      case 'Fe':
      case 'Mn':
      case 'Zn':
      case 'B':
      case 'Mo':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, index) => {
          const coefficient = obj.Coefficient;

          if (obj.ChildAsset.ObsNutrients?.length) {
            const value = obj.ChildAsset.ObsNutrients[0].Noots[type];

            sum = sum + value * this._fertiliserService.calculateUnitsPer1000L(coefficient, TotVolMixInLiters) * 0.01;
          }
        });
        break;

      case 'NitrateN':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, index) => {
          const coefficient = obj.Coefficient;

          if (obj.ChildAsset.ObsNutrients?.length) {
            const NO3_N = obj.ChildAsset.ObsNutrients[0].Noots.NO3_N;

            let value = 0;
            if (NO3_N > 0) {
              value = NO3_N;
            }

            sum = sum + value * this._fertiliserService.calculateUnitsPer1000L(coefficient, TotVolMixInLiters) * 0.01;
          }
        });
        break;
      case 'OtherN':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, index) => {
          const coefficient = obj.Coefficient;
          if (obj.ChildAsset.ObsNutrients?.length) {
            const NH4_N = obj.ChildAsset.ObsNutrients[0].Noots.NH4_N;

            let value = 0;
            if (NH4_N > 0) {
              value = NH4_N;
            }

            sum = sum + value * this._fertiliserService.calculateUnitsPer1000L(coefficient, TotVolMixInLiters) * 0.01;
          }
        });
        break;
      case 'TotalN':
        sum = 0;
        angular.forEach(this.tankMixDetailScope.fert.Asset.ChildAssets, (obj, index) => {
          const coefficient = obj.Coefficient;
          if (obj.ChildAsset.ObsNutrients?.length) {
            const NO3_N = obj.ChildAsset.ObsNutrients[0].Noots.NO3_N;
            const NH4_N = obj.ChildAsset.ObsNutrients[0].Noots.NH4_N;

            let NitrateN = 0;
            let OtherN = 0;

            if (NO3_N > 0) {
              NitrateN = NO3_N;
            }

            if (NH4_N > 0) {
              OtherN = NH4_N;
            }

            const value = NitrateN + OtherN;

            sum = sum + value * this._fertiliserService.calculateUnitsPer1000L(coefficient, TotVolMixInLiters) * 0.01;
          }
        });
        break;
    }
    return sum;
  }

  public saveChanges() {
    this.entityManager.saveChanges().then((result) => {
      this._languageService.showSaveSuccess();
    });
  }

  public gotoFertiliserList() {
    this._state.go('app.nutrients.fertiliser');
  }

  public gototankmixDetail(fert: GenericLst) {
    this._state.go('app.nutrients.tankmix.detail', { id: fert.Id });
  }

  public cancelChanges() {
    this._dataEntityService.rejectChanges();
  }

  public showFertCompatibiltyPopup(ev) {
    this._mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      controller: FertiliserCompatibilityDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/nutrients/tankmix/fertcompatibility-dialog.html',
      targetEvent: ev,
    } as angular.material.IDialogOptions);
  }
}

// #region Interfaces

interface TankMixDetailScope extends angular.IScope {
  fert: Fertiliser;
  manufactures: Contact[];
  searchFert: string;
}

interface GenericLst {
  name: string;
  Id: number;
  Solubility: number;
}

// #endregion

angular.module('app.nutrients').controller('TankMixDetailController', TankMixDetailController);
