import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOptions } from '@services/unit-of-measure.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class SpecificGravityCalculatorDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _languageService: LanguageService;

  private _deferObservations: angular.IDeferred<any>;
  private unitOptions: UnitOptions;
  private volumeUnitOptions: fuse.unitScaleDto[];
  private netWeightUnitOptions: fuse.unitScaleDto[];

  private netWeight: number;
  private netWeightUnit: string;
  private volume: number;
  private volumeUnit: string;
  private specificGravity: number = 0;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._languageService = LanguageService;

    this._deferObservations = this._q.defer();
  }

  $onInit() {
    const promises = [] as angular.IPromise<void>[];
    promises.push(this.getUnitOptions());

    this._q.all(promises).then(() => {
      this._deferObservations.resolve();
    });
  }

  calculateSpecificGravity() {
    if (!this.isValid()) {
      this.specificGravity = 0;
      return;
    }
    const weightUnit = this.netWeightUnitOptions.find((unitOption: fuse.unitScaleDto) => {
      return unitOption.symbol == this.netWeightUnit;
    });
    const weightBase = weightUnit.toBase(this.netWeight);
    const volumeUnit = this.volumeUnitOptions.find((unitOption: fuse.unitScaleDto) => {
      return unitOption.symbol == this.volumeUnit;
    });
    const volumeBase = volumeUnit.toBase(this.volume);

    this.specificGravity = parseFloat((weightBase / volumeBase).toFixed(3));
  }

  public isValid() {
    let valid = [this.volumeUnit, this.netWeightUnit, this.volume, this.netWeight].every((n) => n != null);
    valid = valid && this.volume > 0 && this.netWeight > 0;
    return valid;
  }

  public select() {
    this._mdDialog.hide(this.specificGravity);
  }

  public cancel() {
    this._mdDialog.hide();
  }

  private getUnitOptions(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('profile/getUnitScales')).then(
      (res) => {
        if (!res.data) {
          this._languageService.error('COMMON.ERROR_LOADING_UNIT_OPTIONS');
        } else {
          this.unitOptions = new UnitOptions(res.data as fuse.unitScaleDto[]);
          this.volumeUnitOptions = this.unitOptions
            .getAllowedUnitScales('FertVolume', 'Normal')
            .map((unitScale: fuse.unitScaleDto) => {
              unitScale.toBase = new Function('a', unitScale.toBaseFormulaJS);
              return unitScale;
            });
          this.netWeightUnitOptions = this.unitOptions
            .getAllowedUnitScales('Weight', 'Normal')
            .map((unitScale: fuse.unitScaleDto) => {
              unitScale.toBase = new Function('a', unitScale.toBaseFormulaJS);
              return unitScale;
            });
        }
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );

    return defer.promise;
  }
}
