import * as angular from 'angular';
import { InitialisationService } from '@services/initialisation.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class FertiliserCompatibilityDialogController extends BaseController {
  public URL: string;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.URL = `assets/images/backgrounds/compatibility-chart_${InitialisationService.selectedLanguageCode}.png`;
  }

  public closeDialog() {
    this._mdDialog.hide({ dataRefreshRequired: false });
  }
}

angular.module('app.nutrients').controller('FertiliserCompatibilityDialogController', FertiliserCompatibilityDialogController);
