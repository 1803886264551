import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { UnitTypes } from '@common/enums';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { NutrientsService } from '@services/nutrients.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Contact } from 'src/app/_DBContext/Contact';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { MarketRegions } from 'src/app/_DBContext/MarketRegions';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { BaseController } from 'src/app/base.controller';
import { SpecificGravityCalculatorDialogController } from './specificGravityCalculator-dialog.controller';

interface FertiliserDetailScope extends angular.IScope {
  fert: Fertiliser;
  marketRegionCountries: MarketRegions[];
  marketRegionsProvinces: MarketRegions[];
  manufactures: Contact[];
  selectedMarkets: string[];
  selectedMarketCountry: string;
  selectedMarketProvince: string;
  fertList: FertLst[];
}

interface FertLst {
  name: string;
  Id: number;
  Status: string;
  AccountId: number;
}

export class FertiliserDetailController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _nutrientsService: NutrientsService;

  public _fert: Fertiliser;
  public _status: string[];
  public _marketRegionCountries: MarketRegions[];
  public _marketRegionProvinces: MarketRegions[];
  public _manufactures: Contact[];
  public _selectedMarkets: string[];
  public _selectedMarketCountry: string;
  public _selectedMarketProvince: string;
  public _markets: string[];
  public nameExists: boolean = false;
  public currentName: string;

  public assetId: number;
  public obsNutrientId: number;
  public obsNutrient: ObsNutrients;
  public rejectChanges: number;
  public reloadChanges: number = 0;
  public fertList: FertLst[];
  public fertiliserScope: FertiliserDetailScope;
  public isLiquidConcentrationModeSaved: boolean = true;
  public isIncludeInFertiliser: boolean = true;
  public analyteEditMode: boolean = false;
  public nootNames = ['NO3_N', 'NH4_N', 'P', 'K', 'Ca', 'Mg', 'S', 'Na', 'Cl', 'Cu', 'Fe', 'Mn', 'Zn', 'B', 'Mo'];
  public unitMap;

  public percUnit: uomUnit;
  public solubilityUnit: uomUnit;
  public temperatureUnit: uomUnit;
  public nitrogenChanges = 0;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NutrientsService: NutrientsService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsFertilisersFull);

    this._q = $q;
    this._state = $state;
    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._nutrientsService = NutrientsService;

    this.entityManager = DataEntityService.manager;
    this.fertiliserScope = $scope as FertiliserDetailScope;
    this._selectedMarkets = [];
    this.fertList = [];
    this.rejectChanges = 0;

    // Nutrients & Nitrogen forms expect a unit input, so send dummy f(a)=a "percent" unit to represent %w/w
    this.percUnit = UnitOfMeasureService.createDummyUnit();
    this.solubilityUnit = UnitOfMeasureService.getUnits(UnitTypes.Solubility);
    this.temperatureUnit = UnitOfMeasureService.getUnits(UnitTypes.Temperature);
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    this.initFertiliserDetail();
    this._fetchData();
    this.fillAllDropdowns();
    // Fertiliser inputs theoretically let user enter in terms of weight OR volume, but volume requires conversion to L (=kg) and thereafter treats everything
    // exactly the same as for weights, so the distinction is solely in the labeling. Clunky, but currently the convention.
    this.unitMap = {
      kg: {
        message: this._languageService.instant('NUTR.FERT.BY_WEIGHT'),
        dropDown: this._languageService.instant('NUTR.FERT.SOLID'),
      },
      L: {
        message: this._languageService.instant('NUTR.FERT.BY_VOLUME'),
        dropDown: this._languageService.instant('NUTR.FERT.LIQUID'),
      },
    };
  }

  private initFertiliserDetail() {
    this.assetId = parseInt((this._state.params as any).id);
  }

  private fillAllDropdowns() {
    breeze.EntityQuery.from('MarketRegions')
      .withParameters({ accountId: this.accountId })
      .select('Id, Country, Province')
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.fertiliserScope.marketRegionCountries = data.results as MarketRegions[];
      });

    breeze.EntityQuery.from('MarketProvinces')
      .withParameters({ accountId: this.accountId })
      .select('Id, Country, Province')
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.fertiliserScope.marketRegionsProvinces = data.results as MarketRegions[];
      });

    breeze.EntityQuery.from('Manufacturers')
      .withParameters({ accountId: this.accountId })
      .using(this.entityManager)
      .execute()
      .then((data) => {
        this.fertiliserScope.manufactures = data.results as Contact[];
      });
  }

  private _fetchData() {

    this.getFertiliserList();
    this.getFertiliserDetails();

    if (this.assetId) {
      this.fetchObsNutrients();
    }
  }

  //This is used for search dropdown in the detail page
  private getFertiliserList() {
    const fertilisers: any[] = this.entityManager.getEntities('Fertiliser');

    if (!fertilisers.length) {
      const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null);
      breeze.EntityQuery.from('AccountSharedAssets')
        .expand('Fertiliser')
        .withParameters({ accountId: this.accountId })
        .orderBy('Name')
        .where(pred)
        .using(this.entityManager)
        .execute()
        .then((data) => {
          data.results.map((asset: Asset) => {
            fertilisers.push(asset.Fertiliser);
          });
          this.bindSwitchingDropdOwn(fertilisers);
        });
    } else {
      this.bindSwitchingDropdOwn(fertilisers);
    }
  }

  private bindSwitchingDropdOwn(fertilisers: Fertiliser[]) {
    angular.forEach(fertilisers, (obj: Fertiliser, val) => {
      const pt: FertLst = {
        name: obj.Asset.Name,
        Id: obj.AssetId,
        Status: obj.Asset.Status,
        AccountId: obj.Asset.OwnerAccountId,
      };

      if (obj.Type == 'Raw' && obj.Asset.OwnerAccountId == this.accountId)
        if (obj.Asset.Status != 'Archived') {
          this.fertList.push(pt);
        }
    });
    console.log(this.fertList.length);
  }

  //fetch and bind Obsnutrients for use in components (Nitrogen form, Nutrients and Analytes)
  private fetchObsNutrients() {
    const pred = new breeze.Predicate('AssetId', breeze.FilterQueryOp.Equals, this.assetId);

    breeze.EntityQuery.from('ObsNutrients')
      .withParameters({ assetId: this.assetId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results.length) {
          this.obsNutrient = data.results[0] as ObsNutrients;
          this.obsNutrientId = this.obsNutrient.Id;
          this.entityManager.saveChanges();
          this.reloadChanges++;
        } else {
          const obsNutrientsEntityType = this.entityManager.metadataStore.getEntityType('ObsNutrients') as breeze.EntityType;
          const newObsEntity = obsNutrientsEntityType.createEntity() as ObsNutrients;

          newObsEntity.AssetId = this.assetId;
          newObsEntity.dayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();

          this.entityManager.addEntity(newObsEntity);
          this.entityManager.saveChanges();
          this.obsNutrientId = newObsEntity.Id;

          this.obsNutrient = newObsEntity;
          this.reloadChanges++;
        }
      });
  }

  public checkNameAlreadyExists(name: string) {
    const pred = breeze.Predicate.create('Name', breeze.FilterQueryOp.Equals, name).and(
      'AssetId',
      breeze.FilterQueryOp.NotEquals,
      this.assetId,
    );

    if (this.currentName === name) {
      this.nameExists = false;
    } else {
      breeze.EntityQuery.from('AccountAssets')
        .expand('Fertiliser')
        .withParameters({ accountId: this.accountId })
        .where(pred)
        .using(this.entityManager)
        .execute()
        .then((data) => {
          if (data.results.length >= 1) {
            const fertList = data.results as Asset[];
            const newList = fertList.filter((f) => f.Name !== this.currentName);

            this.nameExists = !!newList.length;
          } else {
            this.nameExists = false;
          }
        });
    }
  }

  //For binding the detail page
  private getFertiliserDetails() {
    const defer = this._q.defer();
    const pred = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null).and(
      'AssetId',
      breeze.FilterQueryOp.Equals,
      this.assetId,
    );

    breeze.EntityQuery.from('getAsset')
      .expand(['Fertiliser'])
      .withParameters({ AssetId: this.assetId })
      .where(pred)
      .using(this.entityManager)
      .execute()
      .then((data) => {
        if (data.results.length === 1) {
          this.fertiliserScope.fert = (data.results[0] as Asset).Fertiliser;
          defer.resolve(this.fertiliserScope.fert);
          this.currentName = this.fertiliserScope.fert.Asset.Name;
          this.isLiquidConcentrationModeSaved = !!this.fertiliserScope.fert.LiquidConcentrationMode;
        }
      });

    defer.promise.then((data) => {
      const selected = data as Fertiliser;
      if (selected) {
        if (selected.Type == null) {
          this.fertiliserScope.fert.Type = 'Raw';
        }
        if (selected.Units == null || selected.Units == 'kg/L') {
          this.fertiliserScope.fert.Units = 'kg';
        }
        if (selected.Asset.Status == null) {
          this.fertiliserScope.fert.Asset.Status = 'Active';
        }

        this.entityManager.saveChanges();
        this.bindMarkets(selected.Markets);
      }
    });
  }

  public openSpecificGravityCalculatorDialog() {
    this._mdDialog
      .show({
        controller: SpecificGravityCalculatorDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/nutrients/fertiliser/specificGravityCalculator-dialog.html',
      } as angular.material.IDialogOptions)
      .then((newSpecificGravity) => {
        if (newSpecificGravity != null) {
          if (this.fertiliserScope.fert.SpecificGravity != newSpecificGravity) {
            this.fertiliserScope.fert.SpecificGravity = newSpecificGravity;

            const message =
              `${
                this.fertiliserScope.fert.LiquidConcentrationMode == 'w/v'
                  ? this._languageService.instant('COMMON.WEIGHT_WEIGHT')
                  : this._languageService.instant('COMMON.WEIGHT_VOLUME')
              } ${this._languageService.instant('NUTR.FERT.VALUES_UPDATED')}`;

            this._languageService.warningMessage(message);
          }
        }
      });
  }

  private bindMarkets(selectedMarkets: string) {
    if (selectedMarkets) {
      if (selectedMarkets.length) {
        this._markets = selectedMarkets.split(',');

        this._markets.forEach((value) => {
          this._selectedMarkets.push(value);
        });
      }
    }
  }

  public addMarket() {
    let selectedMarket: string;
    if (!this.fertiliserScope.selectedMarketProvince) {
      const allMarketProvinces: MarketRegions[] = this.fertiliserScope.marketRegionsProvinces.filter(
        (x) => x.Country == this.fertiliserScope.selectedMarketCountry,
      );

      allMarketProvinces.forEach((obj) => {
        selectedMarket = obj.Country + ' - ' + obj.Province;
        const index: number = this._selectedMarkets.indexOf(selectedMarket);
        if (index == -1) {
          this._selectedMarkets.push(selectedMarket);
          this.fertiliserScope.fert.Markets = this._selectedMarkets.toString();
        }
      });
    } else {
      selectedMarket = this.fertiliserScope.selectedMarketCountry + ' - ' + this.fertiliserScope.selectedMarketProvince;

      const index: number = this._selectedMarkets.indexOf(selectedMarket);
      if (index == -1) {
        this._selectedMarkets.push(selectedMarket);
        this.fertiliserScope.fert.Markets = this._selectedMarkets.toString();
      }
    }
  }

  public deleteMarket(selected: string) {
    const index: number = this._selectedMarkets.indexOf(selected);
    if (index !== -1) {
      this._selectedMarkets.splice(index, 1);
      this.fertiliserScope.fert.Markets = this._selectedMarkets.toString();
    }
  }

  public totalWeightPercent() {
    let total = this.nootNames.reduce((n, m) => n + this.obsNutrient.Noots[m], 0);
    total += this.obsNutrient.Noots.JSONAnalytes.reduce((n, m) => n + m.Value, 0);

    if (this.fertiliserScope.fert.SpecificGravity) {
      total = total * this.fertiliserScope.fert.SpecificGravity;
    }

    return total;
  }

  public saveChanges() {
    if (this.fertiliserScope.fert.Units == 'L') {
      if (this.fertiliserScope.fert.LiquidConcentrationMode == 'w/w' && !this.fertiliserScope.fert.SpecificGravity) {
        this._languageService.error('NUTR.FERT.SPECIFIC_GRAVITY_REQUIRED');
        return;
      }
      if (this.fertiliserScope.fert.SpecificGravity != null && this.fertiliserScope.fert.SpecificGravity <= 0) {
        this._languageService.error('NUTR.FERT.SPECIFIC_GRAVITY_INVALID');
        return;
      }
    }

    if (this.totalWeightPercent() > 100) {
      this._languageService.error('NUTR.FERT.ERROR_WEIGHT_100', 'COMMON.CHANGES_NOT_SAVED');
      return;
    }

    // if fert is "not tested", change the value to -9999
    this.entityManager.saveChanges().then(
      () => {
        this._languageService.showSaveSuccess();
      },
      (error) => {
        error.entityErrors?.forEach((err) => {
          this._languageService.warning(err.errorMessage);
        });
      },
    );
  }

  public updateProvinces() {
    this.fertiliserScope.selectedMarketProvince = null;
  }

  public gotoFertiliserList() {
    this._nutrientsService.setKeepFilter(true);
    this._state.go('app.nutrients.fertiliser');
  }

  public gotoFertDetail(fert: FertLst) {
    this._state.go('app.nutrients.fertiliser.detail', { id: fert.Id });
  }

  public cancelChanges() {
    this._dataEntityService.rejectChanges();
    this.rejectChanges += 1;
  }

  public specificGravityChanged() {
    if (this.fertiliserScope.fert.LiquidConcentrationMode == 'w/v' && this.fertiliserScope.fert.SpecificGravity == null) {
      this._languageService.error('NUTR.FERT.SPECIFIC_GRAVITY_INVALID');
    } else if (this.fertiliserScope.fert.SpecificGravity != null) {
      const message =
        `${
          this.fertiliserScope.fert.LiquidConcentrationMode == 'w/v'
            ? this._languageService.instant('COMMON.WEIGHT_WEIGHT')
            : this._languageService.instant('COMMON.WEIGHT_VOLUME')
        } ${this._languageService.instant('NUTR.FERT.VALUES_UPDATED')}`;

      this._languageService.warningMessage(message);
    }
  }

  public onNitrogenChanged(): void {
    this.nitrogenChanges++;
  }
}

angular.module('app.nutrients').controller('FertiliserDetailController', FertiliserDetailController);
