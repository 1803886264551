// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#gravity-calculator md-input-container {
  margin: 0 8px 8px;
  padding: 0;
}
#gravity-calculator md-input-container .md-errors-spacer {
  min-height: 0px;
}
#gravity-calculator md-input-container .md-input {
  width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/nutrients/fertiliser/specificGravityCalculator-dialog.scss"],"names":[],"mappings":"AAGI;EACI,iBAAA;EACA,UAAA;AAFR;AAGQ;EAEI,eAAA;AAFZ;AAIQ;EACI,YAAA;AAFZ","sourcesContent":["\r\n\r\n#gravity-calculator {\r\n    md-input-container {\r\n        margin: 0 8px 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px;\r\n        }\r\n        .md-input {\r\n            width: 120px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
